const CORE_AIRCRAFT_USAGE_LOG_FRAGMENT = gql`
  fragment CoreAircraftUsageLogFields on AircraftUsageLog {
    id
    aircraftId
    usageAsOf
    airframeTotalTime
    airframeCycle
    createdAt
    updatedAt
    usageLoggedBy {
      id
      firstName
      lastName
    }
    ComponentUsageLog {
      id
      componentId
      usageAsOf
      totalTimeSinceNew
      timeSinceOverhaul
      cycleSinceNew
      cycleSinceOverhaul
      component {
        id
        name
        isPrimary
        isIntegral
        serialNumber
      }
    }
  }
`

export default CORE_AIRCRAFT_USAGE_LOG_FRAGMENT
