import React from 'react'

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { Chip, Typography } from '@mui/material'

import FileDownloadButton from 'src/components/FileDownloadButton'
import FileRemoveButton from 'src/components/FileRemoveButton'
interface UploadedFile {
  fileId?: string
  uploadedBy: string
  fileName: string
  fileSize?: string
  downloadUrl?: string // downloadUrl is optional prop for LogbookEntryPage/Attachments
}

interface UploadedFileDetailsProps {
  file: UploadedFile
  attachedFileId?: string
}

const UploadedFileDetails: React.FC<UploadedFileDetailsProps> = ({
  file,
  attachedFileId,
}) => {
  const { fileId, uploadedBy, fileName, fileSize, downloadUrl } = file
  return (
    <div>
      <div className="flex gap-1">
        <Typography variant="body2" component={'span'}>
          Uploaded by
        </Typography>
        <Chip label={uploadedBy} variant="outlined" size="small" />
      </div>
      <div className="mt-1 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="flex h-5 w-5 items-center justify-center rounded rounded-full bg-[#E4E4E4] p-2">
            <InsertDriveFileIcon
              fontSize="small"
              style={{ color: '#9D9997' }}
            />
          </div>
          <div className="flex flex-col">
            <Typography variant="body2">{fileName}</Typography>
            {fileSize && <Typography variant="caption">{fileSize}</Typography>}
          </div>
        </div>
        <div>
          <FileDownloadButton fileId={fileId} downloadUrl={downloadUrl} />
          <FileRemoveButton
            fileId={fileId}
            filename={fileName}
            // if attachedFileId is undefined, the remove button won't render
            attachedFileIds={attachedFileId ? [attachedFileId] : []}
          />
        </div>
      </div>
    </div>
  )
}

export default UploadedFileDetails
