//import { MaintenanceCadenceType } from 'types/graphql'
import { array, object, string, number, InferType } from 'yup'

export const miscellaneousExpensesSchema = array().of(
  object().shape({
    label: string().required('Label is required'),
    value: number()
      .typeError('Value is required')
      .required('Value is required'),
  })
)

export type MiscellaneousExpenses = InferType<
  typeof miscellaneousExpensesSchema
>
