import { useState } from 'react'

import { useMutation } from '@redwoodjs/web'

import Sentry from 'src/lib/sentry'

import useAddMaintenanceItemsToLedger from './useAddMaintenanceItemsToLedger'

const CREATE_COMPLIANCE_LEDGER_MUTATION = gql`
  mutation CreateComplianceLedger($aircraftId: String!, $getOrCreate: Boolean) {
    createComplianceLedger(
      input: { aircraftId: $aircraftId }
      getOrCreate: $getOrCreate
    ) {
      id
      aircraftId
    }
  }
`

function useCreateComplianceRecords({ getOrCreate } = { getOrCreate: true }) {
  const [
    createComplianceLedger,
    { data: ledgerData, loading: ledgerLoading, error: ledgerError },
  ] = useMutation(CREATE_COMPLIANCE_LEDGER_MUTATION)

  const [
    createComplianceActivity,
    { data: activityData, loading: activityLoading, error: activityError },
  ] = useAddMaintenanceItemsToLedger()

  // Add new state variables to track error details
  const [operationError, setOperationError] = useState({
    hasError: false,
    message: '',
  })

  const loading = React.useMemo(
    () => ledgerLoading || activityLoading,
    [ledgerLoading, activityLoading]
  )
  const error = React.useMemo(
    () => ledgerError || activityError || operationError,
    [ledgerError, activityError, operationError]
  )

  const createRecords = async (
    aircraftId: string,
    maintenanceItems: string[]
  ) => {
    try {
      const { data: ledgerData } = await createComplianceLedger({
        variables: { aircraftId, getOrCreate },
      })
      try {
        const { data: activityData } = await createComplianceActivity(
          ledgerData?.createComplianceLedger?.id,
          maintenanceItems
        )
        setOperationError({
          hasError: false,
          message: undefined,
        })
        return {
          ledgerData: ledgerData?.createComplianceLedger,
          activityData:
            activityData?.addMaintenanceItemsToComplianceLedger
              .complianceActivity,
        }
      } catch (error) {
        Sentry.captureException(
          'An error occurred while creating compliance records:',
          error
        )
        // Set operationError state upon encountering an error
        setOperationError({
          hasError: true,
          message: 'Failed to create compliance records.',
        })
        return {
          ledgerData: ledgerData?.createComplianceLedger,
          activityData: activityData?.addMaintenanceItemsToComplianceLedger,
          error,
        }
      }
    } catch (ledgerError) {
      Sentry.captureException(
        'An error occurred while creating compliance ledger:',
        ledgerError
      )
      setOperationError({
        hasError: true,
        message: 'Failed to create compliance ledger.',
      })
      return {
        ledgerData: null,
        activityData: null,
        error: ledgerError,
      }
    }
  }

  return {
    createRecords,
    ledgerData,
    activityData,
    loading,
    error,
  }
}

export default useCreateComplianceRecords
