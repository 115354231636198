// src/components/Modal.tsx
import { useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Modal, IconButton, Paper, Tabs, Tab } from '@mui/material'

import useDiscriminator from 'src/hooks/useDiscriminator'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, openModal, setModalData } from 'src/slices/modal'

import AircraftSelectorModal from '../AircraftSelectorModal'

import CreateTime from './CreateTimeV2'
import SelectTime from './SelectTime'

interface AircraftTimeModalProps {
  discriminator?: string
  onSuccess?: (aircraftUsageLogId: string) => void
  onClose?: () => void
}

const AircraftTimeModal: React.FC<AircraftTimeModalProps> = ({
  discriminator,
  onSuccess,
  onClose,
}) => {
  const dispatch = useDispatch()

  const open = useSelector(
    (state) => state.modal.modals?.aircraftTimeModal?.isOpen
  )
  const metadata =
    useSelector((state) => state.modal.modals?.aircraftTimeModal?.data) ?? {}
  const show = useDiscriminator(open, discriminator, metadata?.discriminator)

  const initialTab = { pick: 0, create: 1 }[metadata?.initialTab ?? 'create']
  // make sure tab is reset when modal is closed
  useEffect(() => {
    setTab(initialTab)
  }, [show, initialTab])

  const [tab, setTab] = useState(initialTab)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  useEffect(() => {
    // initial tab state is set before metadata is available
    // this ensures that the tab is set correctly if disableCreate is true
    if (metadata?.disablePick && tab == 0) {
      setTab(1)
    }
  }, [metadata?.disablePick, tab])

  useEffect(() => {
    if (show && !metadata?.aircraftId) {
      dispatch(
        openModal({
          name: 'aircraftSelectorModal',
          data: {
            discriminator: 'timeModalAircraftDisambiguator',
          },
        })
      )
      dispatch(
        setModalData({ name: 'aircraftTimeModal', data: { loading: false } })
      )
    }
  }, [show])

  const handleClose = () => {
    onClose?.()
    dispatch(closeModal({ name: 'aircraftTimeModal' }))
  }

  return (
    <>
      <Modal
        open={show && !!metadata?.aircraftId}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disablePortal
      >
        <Paper className="absolute left-1/2 top-1/2 max-h-[90%] w-4/5 -translate-x-1/2 -translate-y-1/2 transform overflow-auto rounded-xl">
          <div className="relative mx-3 flex items-start justify-end">
            <Tabs
              className="absolute left-1/2 -translate-x-1/2 transform"
              onChange={handleTabChange}
              value={tab}
            >
              {metadata?.disablePick !== true && (
                <Tab label="Historical Aircraft Times" />
              )}
              <Tab
                label="Update Aircraft Times"
                disabled={metadata?.disableCreate}
              />
            </Tabs>
            <IconButton className="mt-3" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <SelectTime
            show={tab === 0}
            onSuccess={onSuccess}
            trackedByComponentName={metadata?.trackedByComponentName}
          />
          <CreateTime
            show={tab === 1}
            onSuccess={onSuccess}
            aircraftUsageLogId={metadata?.aircraftUsageLogId}
            aircraftUsageAsOf={metadata?.aircraftUsageAsOf}
          />
        </Paper>
      </Modal>
      <AircraftSelectorModal
        discriminator="timeModalAircraftDisambiguator"
        onSuccess={(id) =>
          dispatch(
            setModalData({
              name: 'aircraftTimeModal',
              data: { aircraftId: id },
            })
          )
        }
        onBail={() => dispatch(closeModal({ name: 'aircraftTimeModal' }))}
      />
    </>
  )
}

export default AircraftTimeModal
