import {
  Select as MUISelect,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material'
import { get } from 'lodash'

import { Controller } from '@redwoodjs/forms'
import { useFormContext } from '@redwoodjs/forms'
export interface SelectFieldProps
  extends React.ComponentProps<typeof MUISelect> {
  name: string
  label: string
  helperText?: string
  growHeight?: boolean
  className?: string
}

const Select: React.FC<SelectFieldProps> = ({
  name,
  helperText,
  growHeight,
  className = '',
  ...props
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext()

  const fieldError = get(errors, name)?.message as string | undefined

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl
          fullWidth={props.fullWidth || false}
          error={Boolean(fieldError)}
          className={className}
          size={props.size}
        >
          <InputLabel
            id={`${name}-label`}
            shrink={!!field.value}
            error={props.error}
          >
            {props.label}
          </InputLabel>
          <MUISelect
            {...props}
            id={name}
            labelId={`${name}-label`}
            label={props.label}
            name={name}
            error={Boolean(fieldError) || props.error}
            defaultValue={field.value}
            notched={!!field.value}
            SelectDisplayProps={
              growHeight
                ? {
                    style: {
                      whiteSpace: 'normal',
                    },
                  }
                : {}
            }
            {...field}
            value={field.value ?? ''}
            MenuProps={{ className: 'w-1' }}
          >
            {props.children}
          </MUISelect>
          <FormHelperText>{fieldError || helperText}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default Select
