import React, { useState, useMemo } from 'react'
import { Typography, Popover, Button, Paper, Modal } from '@mui/material'
import { HoverableLink, HighlightedTypography } from 'src/components/MUI/StyledComponents'
import { capitalizeFirstLetter } from 'src/utils/helpers'
import CitationViewer from 'src/components/CitationViewer'
import { useReferenceBatch } from 'src/hooks/useReferenceBatch'
import useHasFeature from 'src/hooks/useHasFeature'
import { useOrgName } from 'src/hooks/useOrgName'

interface AtaCodeCellProps {
  row: {
    id: string
    ataCode: string
    manufactureCode: string
    trackedByComponent: {
      name: string
    }
    maintenanceType: string
  }
}

// Separate component for reference content to prevent unnecessary re-renders
const ReferenceContent = React.memo(({
  references,
  onViewCitation
}: {
  references: any[],
  onViewCitation: (event: React.MouseEvent, reference: any) => void
}) => (
  <div className="p-4 max-w-[350px]" onClick={(event) => event.stopPropagation()}>
    {references.map((reference, index) => (
      <div key={reference.id} className={index < references.length - 1 ? 'mb-4' : ''}>
        <Typography variant="subtitle2">{reference.name}</Typography>
        <Typography variant="body2" className="mb-2">
          {reference.extractedText}
        </Typography>
        <Button
          size="small"
          onClick={(event) => onViewCitation(event, reference)}
        >
          View Citation
        </Button>
      </div>
    ))}
  </div>
))

// Separate component for citation viewer modal to prevent unnecessary re-renders
const CitationViewerModal = React.memo(({
  selectedReference,
  onClose
}: {
  selectedReference: any,
  onClose: (event: React.MouseEvent) => void
}) => (
  <Modal
    open={!!selectedReference}
    onClose={onClose}
    aria-labelledby="citation-viewer-modal"
  >
    <Paper className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-4 max-w-[90vw] max-h-[90vh] overflow-auto" onClick={(event) => event.stopPropagation()}>
      {selectedReference && (
        <CitationViewer
          fileUrl={selectedReference.referenceSource.fileEntry.url}
          pageNumbers={selectedReference.pageNumbers}
          onClose={onClose}
        />
      )}
    </Paper>
  </Modal>
))

const AtaCodeCell: React.FC<AtaCodeCellProps> = React.memo(({ row }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedReference, setSelectedReference] = useState(null)
  const orgSlug = useOrgName()
  const { hasFeature: orgHasEmbeddedManuals } = useHasFeature('EmbeddedManuals', orgSlug)
  const { loading, data } = useReferenceBatch(orgHasEmbeddedManuals ? [row.id] : [])

  // Memoize references to prevent unnecessary re-renders
  const references = useMemo(() => data?.[row.id] || [], [data, row.id])
  const hasReference = !loading && references.length > 0

  // Memoize content string to prevent unnecessary re-renders
  const ataCodeContent = useMemo(() =>
    `${row.ataCode} ${row.manufactureCode}`,
    [row.ataCode, row.manufactureCode]
  )

  // Memoize component description to prevent unnecessary re-renders
  const componentDescription = useMemo(() =>
    `${capitalizeFirstLetter(row?.trackedByComponent.name)} ${capitalizeFirstLetter(row.maintenanceType)}`,
    [row?.trackedByComponent.name, row.maintenanceType]
  )

  const handlePopoverToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleViewCitation = (event: React.MouseEvent, reference: any) => {
    event.stopPropagation()
    setSelectedReference(reference)
    handlePopoverClose()
  }

  const handleCloseCitation = (event: React.MouseEvent) => {
    event.stopPropagation()
    setSelectedReference(null)
  }

  // If feature is disabled or there's no reference and we're done loading, render minimal content
  if (!orgHasEmbeddedManuals || !hasReference) {
    return (
      <div className="flex flex-col">
        <HighlightedTypography>{ataCodeContent}</HighlightedTypography>
        <Typography variant="caption">{componentDescription}</Typography>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <HoverableLink
        onClick={handlePopoverToggle}
        style={{ cursor: 'pointer' }}
      >
        {ataCodeContent}
      </HoverableLink>
      <Typography variant="caption">{componentDescription}</Typography>

      <Popover
        id="reference-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ReferenceContent
          references={references}
          onViewCitation={handleViewCitation}
        />
      </Popover>

      <CitationViewerModal
        selectedReference={selectedReference}
        onClose={handleCloseCitation}
      />
    </div>
  )
})

export default AtaCodeCell
