import { Permissions } from '@wingwork/common/src/constants/permissions'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Sentry from 'src/lib/sentry'

import useHasPermission from '../useHasPermission'
import { useOrgName } from '../useOrgName'

import useComplianceLedgerRefresh from './useComplianceLedgerRefresh'
import useUploadFile from './useUploadFile'

type PartData = {
  id?: string
  partNumber?: string
  serialNumber?: string
}

type OldPartData = PartData & {
  monthsSinceNew?: string
  monthsSinceOverhaul?: string
  monthsSinceRepair?: string
  hoursSinceNew?: string
  hoursSinceOverhaul?: string
  hoursSinceRepair?: string
  cyclesSinceNew?: string
  cyclesSinceOverhaul?: string
  cyclesSinceRepair?: string
}

type PartsFormData = {
  removalReason?: string
  installedStatus?: string
  added: PartData
  removed: OldPartData
  removedPartFile?: File[]
  addedPartFile?: File[]
}

type TaskFormData = {
  workHours?: string
  maintenanceItemId: string
}

type LedgerFormData = {
  description?: string
  workedById?: string
  inspectedById?: string
  inspectedByPin?: string
  workedByPin?: string
}

type FormData = {
  doSign: boolean
  task: TaskFormData
  parts: PartsFormData
  ledger: LedgerFormData
  nextDueOverride: string
}

const PERFORM_SINGLE_COMPLIANCE = gql`
  mutation PerformSingleCompliance(
    $input: PerformSingleComplianceInput!
    $doSign: Boolean!
  ) {
    performSingleCompliance(input: $input, doSign: $doSign) {
      id
      complianceActivity {
        id
        partsTransaction {
          id
          added {
            id
          }
          removed {
            id
          }
        }
      }
    }
  }
`

function useCreateSingleCompliance() {

  const { mutate: complianceLedgerRefresh } = useComplianceLedgerRefresh()
  const uploadFile = useUploadFile()
  const [performSingleCompliance, { loading: performSingleComplianceLoading }] =
    useMutation(PERFORM_SINGLE_COMPLIANCE)

  const createSingleCompliance = async (formData: FormData, options?) => {
    const loadingToastId = toast.loading('Adding Compliance...', {
      position: 'top-left',
    })

    try {
      const resp = await performSingleCompliance({
        variables: {
          doSign: formData.doSign,
          input: {
            task: formData?.task,
            parts: {
              ...formData?.parts,
              removed: {
                ...formData?.parts?.removed,
              },
              added: {
                ...formData?.parts?.added,
                monthsSinceNew: parseFloat(formData.parts.added.monthsSinceNew),
                monthsSinceOverhaul: parseFloat(
                  formData.parts.added.monthsSinceOverhaul
                ),
                monthsSinceRepair: parseFloat(
                  formData.parts.added.monthsSinceRepair
                ),
                hoursSinceNew: parseFloat(formData.parts.added.hoursSinceNew),
                hoursSinceOverhaul: parseFloat(
                  formData.parts.added.hoursSinceOverhaul
                ),
                hoursSinceRepair: parseFloat(
                  formData.parts.added.hoursSinceRepair
                ),
                cyclesSinceNew: parseFloat(formData.parts.added.cyclesSinceNew),
                cyclesSinceOverhaul: parseFloat(
                  formData.parts.added.cyclesSinceOverhaul
                ),
                cyclesSinceRepair: parseFloat(
                  formData.parts.added.cyclesSinceRepair
                ),
              },
              // since we spread above, we gotta omit the file contents, they dont go up until later
              addedPartFile: undefined,
              removedPartFile: undefined,
            },
            ledger: { ...formData?.ledger },
            nextDueOverride: formData?.nextDueOverride,
          },
        },
        ...options,
      })

      await complianceLedgerRefresh({
        id: resp?.data?.performSingleCompliance?.id,
      })

      // upload files if they exist
      const { added, removed } =
        resp?.data?.performSingleCompliance?.complianceActivity?.[0]
          ?.partsTransaction ?? {}

      if (formData?.parts?.removedPartFile?.length && removed?.id) {
        await uploadFile(formData.parts.removedPartFile[0], [
          { id: removed.id, type: 'AircraftComponent' },
        ])
      }

      if (formData?.parts?.addedPartFile?.length && added?.id) {
        await uploadFile(formData.parts.addedPartFile[0], [
          { id: added.id, type: 'AircraftComponent' },
        ])
      }
      toast.success('Compliance Successfully Added', { id: loadingToastId })
      return resp.data.performSingleCompliance
    } catch (error) {
      if (error.message === 'PIN_DOES_NOT_MATCH') {
        toast.error('PIN does not match', { id: loadingToastId })
      }
      if (error.message === 'PIN_EMPTY') {
        toast.error(
          'If Technician or Inspector is selected, their pins must be entered as well',
          { duration: 10000, id: loadingToastId }
        )
      }
      if (process.env.NODE_ENV === 'development') {
        toast.error('Compliance Failed', { id: loadingToastId })
        console.error(error)
      }
      Sentry.captureException(
        'An error occurred while creating compliance records:',
        error
      )
    }
  }

  // TODO ww-443: Only the create Errors are propagated, need to handle the other errors
  // Then usage should handle the error before proceeding to next step
  const loading = performSingleComplianceLoading || false
  const error = null

  return [
    createSingleCompliance,
    {
      loading,
      error,
    },
  ]
}

export default useCreateSingleCompliance
