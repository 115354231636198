import React, { useEffect } from 'react'

import { useApolloClient } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import { Backdrop, Paper, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { nextDueValueSchema } from '@wingwork/common/src/jsonObjects'
import { getNextDueCadenceType } from '@wingwork/common/src/maintenanceItem'
import { ComplianceActivity, ComplianceLedger } from 'types/graphql'
import * as Yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import Discrepancies from 'src/components/Compliance/DiscrepanciesTab'
import Button from 'src/components/MUI/Button'
import ReusableTabs, { TabComponent } from 'src/components/MUI/Tabs'
import useCreateAircraftComponent from 'src/hooks/requests/useCreateAircraftComponent'
import useCreatePartsTransaction from 'src/hooks/requests/useCreatePartsTransaction'
import useUpdateAircraftComponent from 'src/hooks/requests/useUpdateAircraftComponent'
import useUpdateComplianceActivity from 'src/hooks/requests/useUpdateComplianceActivity'
import useUpdatePartsTransaction from 'src/hooks/requests/useUpdatePartsTransaction'
import useUploadFile from 'src/hooks/requests/useUploadFile'
import { useOrgName } from 'src/hooks/useOrgName'
import {
  capitalizeFirstLetter,
  findChanges,
  titleCase,
} from 'src/utils/helpers'

import { updateMaintenanceItemNotesQuery } from '../queries'

import Attachments from './Attachments'
import ItemInformationForm from './ItemInformationForm'
import PartsForm, { GET_PARTS } from './PartsForm'
import useModal from 'src/hooks/useModal'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useSelector } from 'src/hooks/useSelector'

export const UPSERT_MTX_NEXT_DUE = gql`
  mutation UpsertMtxNextDue(
    $id: String!
    $input: UpsertMaintenanceNextDueInput!
  ) {
    upsertMaintenanceNextDue(id: $id, input: $input) {
      id
      isCompleted
      nextDueValue
      nextDueType
      nextDueOverride
      nextDueOverrideType
      nextDueOverrideBy
    }
  }
`

export const complianceActivitySchema = Yup.object().shape({
  task: Yup.object().shape({
    complianceLedgerId: Yup.string(),
    maintenanceItemId: Yup.string(),
    description: Yup.string().nullable(),
    notes: Yup.string().nullable(),
    workHours: Yup.number()
      .typeError('Must be a number')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .nullable()
      .min(0),
    correctiveAction: Yup.string(),
    partsRemovedId: Yup.array().of(Yup.string()),
    partsInstalledId: Yup.array().of(Yup.string()),
    nextDueId: Yup.string(),
    workedById: Yup.string().nullable(),
    signedById: Yup.string(),
    status: Yup.string(),
  }),
  parts: Yup.object().shape({
    removalReason: Yup.string().when(
      [
        'installedStatus',
        'added.partNumber',
        'added.serialNumber',
        'removedPartFile',
        'addedPartFile',
        'removed.partNumber',
        'removed.serialNumber',
      ],
      (
        [
          installedStatus,
          partNumber,
          serialNumber,
          removedPartFile,
          addedPartFile,
          removedPartNumber,
          removedSerialNumber,
        ],
        schema
      ) => {
        let required = false
        if (!removedPartNumber && !removedSerialNumber) {
          return schema.nullable()
        }

        if (installedStatus || partNumber || serialNumber) {
          required = true
        }

        if (removedPartFile?.length || addedPartFile?.length) {
          required = true
        }

        return required
          ? schema.required('Removal reason is a required field')
          : schema.nullable()
      }
    ),
    otherRemovalReason: Yup.string().when(
      'removalReason',
      ([removalReason], schema) =>
        removalReason?.startsWith('Other')
          ? schema.required('Other removal reason is a required field')
          : schema.nullable()
    ),
    installedStatus: Yup.string().when(
      ['added.partNumber', 'added.serialNumber'],
      (allFields, schema) =>
        allFields.some(Boolean)
          ? schema.required('Installed Status is a required field')
          : schema.nullable()
    ),
    otherInstalledStatus: Yup.string().when(
      'installedStatus',
      ([installedStatus], schema) =>
        installedStatus?.startsWith('Other')
          ? schema.required('Other Installed Status is a required field')
          : schema.nullable()
    ),
    added: Yup.object().shape({
      partNumber: Yup.string().nullable(),
      serialNumber: Yup.string().nullable(),
    }),
    removedPartFile: Yup.mixed().nullable(),
    addedPartFile: Yup.mixed().nullable(),
  }),
})

const TaskForm = ({
  task,
  complianceLedger,
  refreshLedger,
  setClickedTask,
  taskInQueue,
  resetTaskInQueue,
  hasAppliedTimes,
}: {
  task: ComplianceActivity
  complianceLedger: ComplianceLedger
  refreshLedger: () => void
  setClickedTask: (task: ComplianceActivity) => void
  taskInQueue: ComplianceActivity
  resetTaskInQueue: () => void
  hasAppliedTimes?: boolean
}) => {
  const { handleOpen: openWorkCardModal } = useModal('printWorkCardModal')
  const [hasOpenDiscrepancies, setHasOpenDiscrepancies] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [itemInformationErrors, setItemInformationErrors] = React.useState(null)
  const [partsErrors, setPartsErrors] = React.useState(null)
  const client = useApolloClient()
  const orgSlug = useOrgName()
  const formMethods = useForm({
    resolver: yupResolver(complianceActivitySchema),
  })

  const [updateMaintenanceItemNotes] = useMutation(
    updateMaintenanceItemNotesQuery
  )
  const { mutate: updateComplianceActivity } = useUpdateComplianceActivity()
  const { mutate: updateAircraftComponent } = useUpdateAircraftComponent()
  const { mutate: updatePartsTransaction } = useUpdatePartsTransaction({
    refetchQueries: ['getParts'],
  })
  const [createPartsTransaction] = useCreatePartsTransaction({
    refetchQueries: ['getParts'],
  })
  const [createAircraftComponent] = useCreateAircraftComponent()
  const uploadFile = useUploadFile()
  const [upsertMaintenanceNextDue] = useMutation(UPSERT_MTX_NEXT_DUE)

  useEffect(() => {
    if (hasAppliedTimes) {
      if (
        task.discrepancyItems?.some(
          (discrepancy) => discrepancy.discrepancyStatus === 'OPEN'
        )
      ) {
        setHasOpenDiscrepancies(true)
        if (task.status === 'REVIEW') {
          updateComplianceActivity({ id: task.id, status: 'IN_PROGRESS' })
        }
      } else {
        setHasOpenDiscrepancies(false)
      }
    }
  }, [task])

  useEffect(() => {
    formMethods.reset({
      task: {
        ...task,
        notes: task?.maintenanceItem?.notes ?? '',
      },
      nextDue: task.nextDue,
      parts: {
        removed:
          task?.partsTransaction?.removed ??
          task.maintenanceItem.aircraftComponent ??
          {},
        added: task?.partsTransaction?.added ?? {},
        removalReason: task?.partsTransaction?.removalReason ?? '',
        installedStatus: task?.partsTransaction?.installedStatus ?? '',
      },
    })
  }, [task, formMethods])

  const handlePartSubmit = async (values) => {
    if (!values.parts.removalReason && !values.parts.installedStatus) {
      return
    }

    let changesMade = false
    const partData = client.readQuery({
      query: GET_PARTS,
      variables: { id: task.id },
    })

    let newComponent = null
    if (Object.values(values.parts.added).some(Boolean)) {
      if (values.parts.added.id) {
        const resp = await updateAircraftComponent({
          id: values.parts.added.id,
          ...values.parts.added,
          files: undefined, // TODO:This is temporary fix to remove files.
          monthsSinceNew:
            parseFloat(values.parts.added.monthsSinceNew) || undefined,
          monthsSinceOverhaul:
            parseFloat(values.parts.added.monthsSinceOverhaul) || undefined,
          monthsSinceRepair:
            parseFloat(values.parts.added.monthsSinceRepair) || undefined,
          hoursSinceNew:
            parseFloat(values.parts.added.hoursSinceNew) || undefined,
          hoursSinceOverhaul:
            parseFloat(values.parts.added.hoursSinceOverhaul) || undefined,
          hoursSinceRepair:
            parseFloat(values.parts.added.hoursSinceRepair) || undefined,
          cyclesSinceNew:
            parseFloat(values.parts.added.cyclesSinceNew) || undefined,
          cyclesSinceOverhaul:
            parseFloat(values.parts.added.cyclesSinceOverhaul) || undefined,
          cyclesSinceRepair:
            parseFloat(values.parts.added.cyclesSinceRepair) || undefined,
          __typename: undefined,
        })
        newComponent = resp?.data?.updateAircraftComponent
      } else {
        try {
          const resp = await createAircraftComponent({
            onError: (err) => {
              throw new Error(err.message)
            },
            variables: {
              input: {
                orgSlug: orgSlug,
                aircraftId: task.maintenanceItem.aircraft.id,
                isPrimary:
                  partData.complianceActivity.maintenanceItem?.aircraftComponent
                    ?.isPrimary ?? false,
                isIntegral:
                  partData.complianceActivity.maintenanceItem?.aircraftComponent
                    ?.isIntegral ?? false,
                name: `${values.parts.added.partNumber} - ${values.parts.added.serialNumber}`,
                partNumber: values.parts.added.partNumber,
                serialNumber: values.parts.added.serialNumber,
                monthsSinceNew:
                  parseFloat(values.parts.added.monthsSinceNew) || undefined,
                monthsSinceOverhaul:
                  parseFloat(values.parts.added.monthsSinceOverhaul) ||
                  undefined,
                monthsSinceRepair:
                  parseFloat(values.parts.added.monthsSinceRepair) || undefined,
                hoursSinceNew:
                  parseFloat(values.parts.added.hoursSinceNew) || undefined,
                hoursSinceOverhaul:
                  parseFloat(values.parts.added.hoursSinceOverhaul) ||
                  undefined,
                hoursSinceRepair:
                  parseFloat(values.parts.added.hoursSinceRepair) || undefined,
                cyclesSinceNew:
                  parseFloat(values.parts.added.cyclesSinceNew) || undefined,
                cyclesSinceOverhaul:
                  parseFloat(values.parts.added.cyclesSinceOverhaul) ||
                  undefined,
                cyclesSinceRepair:
                  parseFloat(values.parts.added.cyclesSinceRepair) || undefined,
                componentLimitType:
                  partData.complianceActivity.maintenanceItem?.aircraftComponent
                    ?.componentLimitType ?? 'NONE',
              },
            },
          })
          newComponent = resp?.data?.createAircraftComponent
        } catch (e) {
          if (e.message === 'DUPLICATE_COMPONENTS_ERROR') {
            toast.error(
              'Cannot create new component when one already exists with the same part number and serial number',
              { duration: 16000 }
            )
          } else {
            toast.error('Cannot update parts')
          }
          // bail out of part form submission early if we cannot create new component
          return
        }
      }
      changesMade = true
    }

    if (
      partData.complianceActivity.maintenanceItem.aircraftComponent?.id &&
      values.parts.removalReason
    ) {
      await updateAircraftComponent({
        id: partData.complianceActivity.maintenanceItem.aircraftComponent.id,
        ...values.parts.removed,
        files: undefined,
        __typename: undefined,
      })

      changesMade = true
    }

    if (
      (values.parts.removed.partNumber || values.parts.removed.serialNumber) &&
      !values.parts.removalReason
    ) {
      return
    }

    if (
      values?.parts?.removedPartFile?.length &&
      partData?.complianceActivity?.maintenanceItem?.aircraftComponent?.id
    ) {
      await Promise.all(
        values.parts.removedPartFile.map(async (file) => {
          await uploadFile(file, [
            {
              id: partData.complianceActivity.maintenanceItem.aircraftComponent
                .id,
              type: 'AircraftComponent',
            },
          ])
        })
      )
    }

    if (values?.parts?.addedPartFile?.length && newComponent?.id) {
      await Promise.all(
        values.parts.addedPartFile.map(async (file) => {
          await uploadFile(file, [
            {
              id: newComponent.id,
              type: 'AircraftComponent',
            },
          ])
        })
      )
    }

    const payload = {
      removalReason: values.parts.removalReason.startsWith('Other')
        ? `Other - ${values.parts.otherRemovalReason}`
        : values.parts.removalReason,
      installedStatus: values.parts.installedStatus.startsWith('Other')
        ? `Other - ${values.parts.otherInstalledStatus}`
        : values.parts.installedStatus,
      removedId:
        partData.complianceActivity.maintenanceItem.aircraftComponent?.id,
      addedId: newComponent?.id,
      monthsSinceNew:
        parseFloat(values.parts.added.monthsSinceNew) || undefined,
      monthsSinceOverhaul:
        parseFloat(values.parts.added.monthsSinceOverhaul) || undefined,
      monthsSinceRepair:
        parseFloat(values.parts.added.monthsSinceRepair) || undefined,
      hoursSinceNew: parseFloat(values.parts.added.hoursSinceNew) || undefined,
      hoursSinceOverhaul:
        parseFloat(values.parts.added.hoursSinceOverhaul) || undefined,
      hoursSinceRepair:
        parseFloat(values.parts.added.hoursSinceRepair) || undefined,
      cyclesSinceNew:
        parseFloat(values.parts.added.cyclesSinceNew) || undefined,
      cyclesSinceOverhaul:
        parseFloat(values.parts.added.cyclesSinceOverhaul) || undefined,
      cyclesSinceRepair:
        parseFloat(values.parts.added.cyclesSinceRepair) || undefined,
    }
    if (partData.complianceActivity.partsTransaction?.id) {
      await updatePartsTransaction({
        id: partData.complianceActivity.partsTransaction.id,
        ...payload,
      })
    } else {
      await createPartsTransaction({
        variables: {
          input: {
            ...payload,
            complianceActivityId: partData.complianceActivity.id,
          },
        },
      })
    }

    return changesMade
  }

  const handleSubmit = async (values) => {
    let changesMade = false
    setIsLoading(true)

    const activityChanges = findChanges(task, {
      ...values.task,
      nextDue: undefined,
    })
    const nextDueChanges = findChanges(
      task.nextDue?.nextDueOverride ?? {},
      values.nextDue?.nextDueOverride ?? {}
    )
    const cleanedNextDueChanges = nextDueValueSchema.cast(nextDueChanges)

    if (Object.keys(activityChanges).length > 0) {
      changesMade = true
      await updateComplianceActivity({ ...activityChanges, id: task.id })
    }

    if (values.task.notes !== task?.maintenanceItem?.notes) {
      await updateMaintenanceItemNotes({
        variables: {
          id: task.maintenanceItem.id,
          notes: values.task.notes,
        },
      })
    }

    const partsChanged = await handlePartSubmit(values)

    if (Object.keys(cleanedNextDueChanges).length > 0) {
      changesMade = true

      const nextDue = task?.nextDue
      upsertMaintenanceNextDue({
        variables: {
          input: {
            nextDueOverride: {
              ...(values.nextDue?.nextDueOverride ?? {}),
              ...cleanedNextDueChanges,
            },
            isCompleted: false, // isCompleted is always false for new/active next due
            nextDueType: getNextDueCadenceType(nextDue),
            verifyPin: false,
            nextDueValue: nextDue.nextDueValue,
          },
          id: nextDue.id,
        },
        onCompleted: () => {
          formMethods.setValue('nextDue.nextDueOverride', cleanedNextDueChanges)
        },
      })
    }

    if (changesMade || partsChanged) {
      toast.success('Task Updated')
      // formMethods.reset(values)
    }
    setIsLoading(false)
  }

  const formDefaults = {
    task: {
      ...task,
      notes: task?.maintenanceItem?.notes ?? '',
    },
    nextDue: task.nextDue,
    parts: {
      removalReason: task?.partsTransaction?.removalReason ?? '',
      installedStatus: task?.partsTransaction?.installedStatus ?? '',
      removed:
        {
          ...task.maintenanceItem.aircraftComponent,
          removalReason: task?.partsTransaction?.removalReason,
        } ?? {},
      added: {
        ...task?.partsTransaction?.added,
      },
    },
  }

  const handleFormSubmit = async (taskInQueueLogic = false) => {
    let hasError = false
    await formMethods.handleSubmit(
      (values) => {
        handleSubmit(values).then(() => {
          taskInQueueLogic && setClickedTask(taskInQueue)
          setItemInformationErrors(null)
          setPartsErrors(null)
          refreshLedger() // to show saved changes and rerun logic to conditionally display Review Tasks button
        })
      },
      (error) => {
        hasError = true
        toast.error('Please fix errors before submitting')
        if (error?.task) {
          setItemInformationErrors(error.task)
        }
        if (error?.parts) {
          setPartsErrors(error.parts)
        }
        taskInQueueLogic && resetTaskInQueue() // clear taskInQueue so when user re-clicks the same task to submit after fixing error, it resubmits
      }
    )()
    return !hasError
  }

  useEffect(() => {
    // when switching from another task, check if there are unsaved changes to save then switch
    if (!taskInQueue) return
    if (Object.keys(formMethods.formState.dirtyFields).length) {
      handleFormSubmit(true)
    } else {
      setClickedTask(taskInQueue)
    }
  }, [taskInQueue])

  const aircraftId = useSelector((state) => state.compliance.aircraftId)
  const canUpdateCompliance = useHasPermission(
    Permissions.compliance.update,
    aircraftId
  )
  const canReadMtxItem = useHasPermission(
    Permissions.maintenanceItem.read,
    aircraftId
  )

  return (
    <Paper className="rounded-2xl">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="p-2">
        <Typography
          variant="h6"
          className="overflow-hidden text-ellipsis whitespace-nowrap	"
        >
          Task - {task.maintenanceItem.title}
        </Typography>
        <div className="grid grid-cols-4 gap-2">
          <PrimSecTextCombo
            primaryText={titleCase(task.maintenanceItem.maintenanceType)}
            secondaryText="Item Type"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={task.maintenanceItem.ataCode}
            secondaryText="ATA Code"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText="----"
            secondaryText="Reference"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={capitalizeFirstLetter(
              titleCase(task.maintenanceItem.trackedByComponent.name)
            )}
            secondaryText="Tracked By"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={titleCase(task.maintenanceItem.disposition) || '----'}
            secondaryText="Disposition"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={task.maintenanceItem.manufactureCode}
            secondaryText="MFG Code"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText="----"
            secondaryText="Requirement Type"
            variant="inverted"
          />
        </div>
      </div>
      <Form
        formMethods={formMethods}
        config={{
          defaultValues: formDefaults,
        }}
      >
        <ReusableTabs variant="fullWidth" className="mt-3">
          <TabComponent
            label="Item Information"
            iconPosition="end"
            icon={itemInformationErrors && <ErrorOutlinedIcon color="error" />}
          >
            <ItemInformationForm task={task} />
          </TabComponent>
          <TabComponent
            label="Parts"
            iconPosition="end"
            icon={partsErrors && <ErrorOutlinedIcon color="error" />}
          >
            <PartsForm
              complianceActivity={task}
              part={task.maintenanceItem.aircraftComponent}
              transaction={task.partsTransaction}
            />
          </TabComponent>
          <TabComponent label="Attachments">
            <Attachments task={task} />
          </TabComponent>
          <TabComponent
            label="Discrepancies"
            iconPosition="end"
            icon={hasOpenDiscrepancies && <ErrorOutlinedIcon color="error" />}
          >
            <Discrepancies
              complianceActivity={task}
              aircraftUsageLogId={complianceLedger?.aircraftUsageLog?.id}
              refreshLedger={refreshLedger}
            />
          </TabComponent>
        </ReusableTabs>
        <div className="flex w-full justify-end gap-2 rounded-b-2xl bg-[#E4E4E4] px-3 py-2">
          <Button
            onClick={() => {
              if (canUpdateCompliance) {
                handleFormSubmit().then(() => {
                  openWorkCardModal({ ids: task.id })
                })
              } else {
                openWorkCardModal({ ids: task.id })
              }
            }}
            variant="outlined"
            color="base"
            locked={!canReadMtxItem}
            lockedTooltip="You do not have permission"
          >
            Print Work Card
          </Button>
          <Button
            type="submit"
            sx={{
              ':hover': {
                backgroundColor: '#4c804c',
                color: '#D1E4D0',
              },
              backgroundColor: '#D1E4D0',
              color: '#4c804c',
            }}
            disabled={
              Object.keys(formMethods.formState.dirtyFields).length === 0
            }
            disabledTooltip="No changes made"
            onClick={() => handleFormSubmit()}
            locked={!canUpdateCompliance}
            lockedTooltip="You do not have permission"
          >
            Save
          </Button>
          <Button
            disabled={!hasAppliedTimes || hasOpenDiscrepancies}
            disabledTooltip={
              !hasAppliedTimes
                ? 'Apply times first'
                : 'Task has open discrepancies'
            }
            onClick={async () => {
              const noErrors = await handleFormSubmit()
              if (noErrors) {
                await updateComplianceActivity({
                  id: task.id,
                  status: 'REVIEW',
                })
              }
            }}
            locked={!canUpdateCompliance}
            lockedTooltip="You do not have permission"
          >
            Ready for review
          </Button>
        </div>
      </Form>
    </Paper>
  )
}

export default TaskForm
