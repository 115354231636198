import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from './AircraftUsageLog'
import CORE_COMPONENT_USAGE_LOG_FRAGMENT from './ComponentUsageLog'

const CORE_MAINTENANCE_DUE_STATUS_CALC_FRAGMENT = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  ${CORE_COMPONENT_USAGE_LOG_FRAGMENT}
  fragment CoreMaintenanceDueStatusCalcFields on MaintenanceItem {
    cadenceValue
    cadenceType
    maintenanceType
    lastComplianceDate
    calculatedNextDueAt
    trackedBy
    trackedByComponentId
    trackedByComponent {
      id
      name
      ComponentUsageLog {
        ...CoreComponentUsageLogFields
      }
    }
    importedDataCompliance
    lastComplianceStampId
    lastComplianceStamp {
      ...CoreAircraftUsageLogFields
    }
    nextDueStatus
    maintenanceNextDue(isCompleted: false) {
      id
      nextDueType
      nextDueValue
      isCompleted
      nextDueOverrideType
      nextDueOverride
      nextDueOverrideUser {
        firstName
        lastName
        email
      }
    }
  }
`

export default CORE_MAINTENANCE_DUE_STATUS_CALC_FRAGMENT
