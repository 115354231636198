import React, { useEffect } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Paper,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { Aircraft, MaintenanceItem } from 'types/graphql'

import ApiError from 'src/components/ApiError'
import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import DueStatusChip from 'src/components/DueStatusChip/DueStatusChip'
import Loading from 'src/components/Loading'
import {
  CADENCE_TYPE_TO_INTERVAL_KEY,
  CADENCE_TYPE_TO_LABEL,
} from 'src/constants'
import useDuelistRecords from 'src/hooks/requests/useDuelistRecords'
import { capitalizeFirstLetter } from 'src/utils/helpers'

interface ChildTasksTabProps {
  parentTask: MaintenanceItem
  aircraft: Aircraft
}

const ChildTasksTab: React.FC<ChildTasksTabProps> = ({
  parentTask,
  aircraft,
}) => {
  const { loadMaintenanceItems, maintenanceItems, loading, error } =
    useDuelistRecords()

  useEffect(() => {
    if (parentTask === undefined) return
    loadMaintenanceItems({
      parentId: parentTask.id,
      isChildItem: true,
      onlyDueItems: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTask])

  if (loading) return <Loading />

  if (error) return <ApiError />

  if (maintenanceItems.length === 0)
    return (
      <Paper className="mx-3 my-2 flex items-center justify-center rounded-xl p-5">
        <Typography variant="body2">
          No child tasks associated with this task
        </Typography>
      </Paper>
    )

  return (
    <div className="flex flex-col gap-2 px-3 py-2">
      {maintenanceItems.map((task) => {
        const cadenceType = get(task, 'cadenceType', '')
        const intervalValue = get(task, [
          'cadenceValue',
          CADENCE_TYPE_TO_INTERVAL_KEY[cadenceType],
        ])
        const nextDueDate = get(task, ['calculatedNextDueAt'])
        return (
          <div key={task.id}>
            <Accordion className="rounded-xl">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="flex flex-col">
                  <div className="flex items-center gap-1">
                    <Typography variant="subtitle2">{task.title}</Typography>
                    {task.disposition && (
                      <Chip
                        label={task.disposition}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  </div>
                  <Typography variant="caption">
                    {`${capitalizeFirstLetter(
                      task.trackedByComponent.name || ''
                    )}
                      ${capitalizeFirstLetter(task.maintenanceType || '')}
                    `}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-rows-2 gap-1">
                  <div className="grid grid-cols-2 gap-2">
                    <PrimSecTextCombo
                      primaryText={`${task.ataCode}  ${task.manufactureCode}`}
                      secondaryText="ATA / Manufacture Code"
                      variant="inverted"
                    />
                    <PrimSecTextCombo
                      primaryText={`${intervalValue} ${CADENCE_TYPE_TO_LABEL[cadenceType]}`}
                      secondaryText="Interval"
                      variant="inverted"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <PrimSecTextCombo
                      primaryText={
                        nextDueDate
                          ? dayjs(nextDueDate).format('MMM DD, YYYY')
                          : 'N/A'
                      }
                      secondaryText="Due Date"
                      variant="inverted"
                    />
                    <div>
                      <Typography variant="caption" component="div">
                        Remaining
                      </Typography>
                      <DueStatusChip
                        maintenanceItem={task}
                        aircraft={aircraft}
                      />
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )
      })}
    </div>
  )
}

export default ChildTasksTab
