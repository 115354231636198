import React from 'react'

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { IconButton, Tooltip } from '@mui/material'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useDispatch } from 'src/hooks/useDispatch'
import { openModal } from 'src/slices/modal'

import ConfirmationModal from './ConfirmationModal/ConfirmationModal'

export const DELETE_ATTACHED_FILES = gql`
  mutation DeleteAttachedFiles($fileId: String!, $attachedFileIds: [String!]!) {
    deleteAttachedFiles(fileId: $fileId, attachedFileIds: $attachedFileIds) {
      id
      originalFilename
    }
  }
`

type FileRemoveButtonProps = {
  fileId: string
  filename: string
  attachedFileIds?: string[]
}

const FileRemoveButton: React.FC<FileRemoveButtonProps> = ({
  fileId,
  filename,
  attachedFileIds,
}) => {
  const dispatch = useDispatch()

  const handleButtonClick = async () => {
    dispatch(
      openModal({
        name: 'confirmationModal',
        data: {
          message: `Are you sure you want to remove ${filename}?`,
          //variables used in DELETE_ATTACHED_FILES mutation
          variables: {
            fileId,
            attachedFileIds,
          },
          discriminator: 'removeFile',
        },
      })
    )
  }

  if (attachedFileIds.length === 0) {
    return null
  }

  return (
    <Tooltip title={`Remove File`}>
      <IconButton onClick={handleButtonClick}>
        <RemoveCircleOutlineIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default FileRemoveButton
