import { object, InferType } from 'yup'

import { roundToPrecision } from '../helpers'

import { UNIT_MAP } from './remainingValue'
import { toleranceSchemaV1, unitValueSchema } from './tolerance'

export const cadenceValueSchema = object({
  tolerance: toleranceSchemaV1.nullable(),
  days: unitValueSchema,
  months: unitValueSchema,
  flying_hours: unitValueSchema,
  cycles: unitValueSchema,
  landings: unitValueSchema,
})

export const getCadenceAsStringArray = (
  cadenceValue: CadenceValue
): string[] => {
  const result: string[] = []
  const fields = ['months', 'days', 'flying_hours', 'cycles', 'landings']
  fields.forEach((field) => {
    if (cadenceValue[field]) {
      result.push(`${roundToPrecision(cadenceValue[field])} ${UNIT_MAP[field]}`)
    }
  })
  return result
}

export const getCadenceAsFlatDict = (cadenceValueSchema: CadenceValue) => {
  const result = {}
  const fields = ['months', 'days', 'flying_hours', 'cycles', 'landings']

  const fieldMap = {
    months: 'Months',
    days: 'Days',
    flying_hours: 'Hours',
    cycles: 'Cycles',
    landings: 'Landings',
  }
  fields.forEach((field) => {
    if (cadenceValueSchema[field]) {
      result['interval' + fieldMap[field]] = roundToPrecision(
        cadenceValueSchema[field]
      )
    } else {
      result['interval' + fieldMap[field]] = null
    }
  })
  // Add tolerance with the same logic on each field
  if (cadenceValueSchema.tolerance) {
    const tolerance = cadenceValueSchema.tolerance
    fields.forEach((field) => {
      if (tolerance[field]) {
        result['tolerance' + fieldMap[field]] = roundToPrecision(
          tolerance[field]
        )
      } else {
        result['tolerance' + fieldMap[field]] = null
      }
    })
  } else {
    fields.forEach((field) => {
      result['tolerance' + fieldMap[field]] = null
    })
  }

  return result
}

export type CadenceValue = InferType<typeof cadenceValueSchema>
