import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Paper, Typography, Modal as MuiModal } from '@mui/material'

interface ModalProps {
  title?: string
  description?: string
  open: boolean
  onClose: () => void
  children: React.ReactElement | React.ReactElement[]
}

const ReusableModal: React.FC<ModalProps> = ({
  open,
  onClose,
  children,
  title,
  description,
}) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disablePortal
    >
      <Paper className="absolute left-1/2 top-1/2 max-h-[90%] w-111 max-w-[90%] -translate-x-1/2 -translate-y-1/2 transform overflow-auto rounded-xl">
        <div className="mx-3 mt-3 flex items-center justify-between">
          <div className="flex flex-col">
            {title && (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {title}
              </Typography>
            )}
            {description && (
              <Typography variant="caption" id="modal-modal-description">
                {description}
              </Typography>
            )}
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>{children}</div>
      </Paper>
    </MuiModal>
  )
}

export default ReusableModal
