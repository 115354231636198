import styled from '@emotion/styled'

const DropdownOption = styled('div')(() => ({
  padding: '8px 16px',
  cursor: 'pointer',
  ':hover': {
    background: '#E8E7F0',
  },
  '&.selected': {
    background: '#E8E7F0',
  },
}))

export default DropdownOption
