import { MenuItem } from '@mui/material'
import { User } from 'types/graphql'

import Select, { SelectFieldProps } from 'src/components/MUI/Select'

interface UsersDropDownSelectorProps extends SelectFieldProps {
  name: string
  label: string
  users: User[]
}

export const UsersDropDownSelector: React.FC<UsersDropDownSelectorProps> = ({
  users,
  label,
  name,
  ...props
}) => {
  return (
    <Select name={name} label={label} {...props}>
      <MenuItem key={-1} value={null}>
        {'Not Selected'}
      </MenuItem>
      {users.map((user) => (
        <MenuItem key={user.id} value={user.id}>
          {user.firstName} {user.lastName}
        </MenuItem>
      ))}
    </Select>
  )
}
