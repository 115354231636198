import { Chip, Typography } from '@mui/material'

import { DUE_STATUS_VARIANT } from './DueStatusChip/DueStatusChip'

const RemainingStatusChip = ({ remVal }) => {
  const icon = DUE_STATUS_VARIANT[remVal.status]?.icon
  const color: 'error' | 'warning' | 'success' =
    DUE_STATUS_VARIANT[remVal.status]?.color
  const variant: 'filled' | 'outlined' =
    DUE_STATUS_VARIANT[remVal.status]?.variant
  return (
    <div className="noWrap mb-1 flex items-center">
      <Chip
        icon={icon}
        label={remVal.valString}
        color={color}
        variant={variant}
        size="small"
      />

      {remVal.toleranceString && (
        <Typography variant="caption" className="ml-1">
          + {remVal.toleranceString}
        </Typography>
      )}
    </div>
  )
}

export default RemainingStatusChip
