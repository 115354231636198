import * as React from 'react'

import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Typography from '@mui/material/Typography'

interface ReusableTimelineItemProps {
  icon: React.ReactElement
  dotColor?: string
  iconVariant?: 'filled' | 'outlined'
  title: string
  description: string
  children?: React.ReactElement | React.ReactElement[]
}

interface ReusableTimelineProps {
  position?: 'right' | 'left'
  children:
    | React.ReactElement<ReusableTimelineItemProps>
    | React.ReactElement<ReusableTimelineItemProps>[]
}

export const ReusableTimelineItem: React.FC<ReusableTimelineItemProps> = () => {
  return <></>
}

const ReusableTimeline: React.FC<ReusableTimelineProps> = ({
  position = 'right',
  children,
}) => {
  return (
    <Timeline
      position={position}
      sx={{
        '& .MuiTimelineItem-root:before': {
          flex: 'none',
          padding: 0,
        },
      }}
    >
      {React.Children.map(
        children,
        (
          {
            props: {
              icon,
              title,
              description,
              children: timelineItemChildren,
              dotColor,
              iconVariant = 'filled',
            },
          },
          index
        ) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot
                sx={{ backgroundColor: dotColor }}
                variant={iconVariant}
              >
                {icon}
              </TimelineDot>
              <TimelineConnector className="my-2" />
            </TimelineSeparator>
            <TimelineContent className="pb-4">
              <div className="flex flex-col">
                <Typography variant="subtitle1" fontWeight={500}>
                  {title}
                </Typography>
                <Typography variant="caption">{description}</Typography>
              </div>
              <div
                className={`mt-2 ${position === 'left' ? 'float-right' : ''}`}
              >
                {timelineItemChildren}
              </div>
            </TimelineContent>
          </TimelineItem>
        )
      )}
    </Timeline>
  )
}

export default ReusableTimeline
