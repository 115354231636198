import * as React from 'react'

import { useLazyQuery } from '@apollo/client'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DifferenceRoundedIcon from '@mui/icons-material/DifferenceRounded'
import EditIcon from '@mui/icons-material/Edit'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import TopicRoundedIcon from '@mui/icons-material/TopicRounded'
import { Link, Typography, Checkbox, Tooltip, SvgIcon } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import {
  getLastComplianceTimeString,
  getMaxNextDueString,
  getNextDueString,
} from '@wingwork/common/src/maintenanceItem'
import dayjs from 'dayjs'
import { get } from 'lodash'
import isEqual from 'lodash/isEqual'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  MaintenanceItem,
  MaintenanceItemFilters,
  RemainingValueFilter,
} from 'types/graphql'

import { routes, navigate, useLocation } from '@redwoodjs/router'

import ApiError from 'src/components/ApiError'
import DueStatusChip from 'src/components/DueStatusChip/DueStatusChip'
import Loading from 'src/components/Loading'
import { GET_SAVED_COLUMNS_CONFIG } from 'src/components/ManageTableColumnsModal'
import IconButton from 'src/components/MUI/IconButton'
import {
  DuelistTableCell,
  HighlightedTypography,
  MultiLineTypography,
  StyledTableCell,
  StyledTableRow,
} from 'src/components/MUI/StyledComponents'
import useAircraftList from 'src/hooks/requests/useAircraftRecords'
import usePaginatedMaintenanceItemList from 'src/hooks/requests/usePaginatedMaintenanceItemList'
import { useDispatch } from 'src/hooks/useDispatch'
import useMaintenanceItemTable, { TableVariant } from 'src/hooks/useMaintenanceItemTable'
import useDueStatus from 'src/hooks/useDueStatus'
import { useOrgName } from 'src/hooks/useOrgName'
import usePreventClickOnTextSelect from 'src/hooks/usePreventClickOnTextSelect'
import { initialFilterData } from 'src/slices/dueListTableSlice'
import { capitalizeFirstLetter } from 'src/utils/helpers'
import { inProgressValues } from 'src/utils/maintenanceItem'

import { ReactComponent as OneQuarterStatusIcon } from '../../../assets/icons/OneQuarterStatusIcon.svg'
import { ReactComponent as ThreeQuartersStatusIcon } from '../../../assets/icons/ThreeQuartersStatusIcon.svg'
import { ReactComponent as TwoQuartersStatusIcon } from '../../../assets/icons/TwoQuartersStatusIcon.svg'
import { ReactComponent as WarningStatusIcon } from '../../../assets/icons/WarningStatusIcon.svg'
import TypeAndDescriptionDisplay from '../../TypeAndDescriptionDisplay'

import LineItemDetailsDrawer from './LineItemDetails/LineItemDetailsDrawer'
import TableHead, { enableStickyColumns } from './TableHead'
import AtaCodeCell from './AtaCodeCell'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'

const HIGHLIGHT_CARD_FILTER_COLUMN_MAP = {
  adhocOnlyNotDue: [
    'tailNumber',
    'ataCode',
    'typeDescription',
    'lastComplianceDate',
    'actions',
  ],
}

export type SortOrder = 'asc' | 'desc'

interface TableWithDataProps {
  variant: TableVariant
  onSelectItem?: (id: string | string[], isSelect?: boolean) => void
  selectedAircraft?: string
  setQueryVariables?: (queryVariables: MaintenanceItemFilters) => void
  queryVariables?: MaintenanceItemFilters
  disableTaskSelection?: boolean
  taskViewMode?: 'simple' | 'advanced'
  setTableTitle?: (title: string) => void
  discriminator?:
    | ''
    | 'associatedTasks'
    | 'componentRelatedMaintenanceItems'
    | 'unscheduledTasks'
    | 'dueListPicker'
  onChildTaskSliderClick?: (row: MaintenanceItem) => void
}

const TableWithData: React.FC<TableWithDataProps> = ({
  variant,
  onSelectItem,
  selectedAircraft,
  disableTaskSelection = false,
  taskViewMode = 'advanced',
  setQueryVariables,
  queryVariables = {
    isChildItem: false,
    onlyDueItems: true,
    parentId: null,
    showInProgress: true,
  },
  setTableTitle,
  discriminator = '',
  onChildTaskSliderClick,
}) => {
  const [order, setOrder] = React.useState<SortOrder>('asc')
  const [orderBy, setOrderBy] = React.useState<string | null>('nextDueStatus')

  const dispatch = useDispatch()

  const [detailedTaskView, setDetailedTaskView] = React.useState(undefined)
  const [openDetailedTaskView, setOpenDetailedTaskView] = React.useState(false)
  const orgName = useOrgName()

  const {
    duelistFiltersData,
    getSearchConfig,
    setEnabledColumns,
    showChildItems,
    selectedItemIds,
    unselectAll,
    selectMaintenanceItem,
    deselectMaintenanceItem,
  } = useMaintenanceItemTable(variant)

  const {
    filterDataBy,
    powerSearchTerms,
    fuzzySearchTerms,
    aircrafts: aircraftFilter,
    timeFrameAll,
    timeframeCycles,
    timeframeDays,
    timeframeHours,
    timeframeLandings,
    projectionFilterData,
  } = duelistFiltersData

  const {
    maintenanceItems,
    hasNextPage,
    initialLoading,
    error,
    loadMaintenanceItems,
    fetchNextMaintenancePage,
  } = usePaginatedMaintenanceItemList()

  const [
    getSavedColumnsConfig,
    { data: savedTableConfig, loading: tableColumnConfigLoading },
  ] = useLazyQuery(GET_SAVED_COLUMNS_CONFIG)

  const {
    loadAircraftList,
    loading: isAircraftListLoading,
    hasLoaded: hasAircraftListLoaded,
    aircrafts,
  } = useAircraftList()

  React.useEffect(() => {
    loadAircraftList({ orgSlug: orgName })
    getSavedColumnsConfig({
      variables: {
        tableName: 'MaintenanceItems',
        orgSlug: orgName,
      },
      fetchPolicy: 'cache-first',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (savedTableConfig?.feTableConfiguration) {
      setEnabledColumns(savedTableConfig.feTableConfiguration?.columns || [])
    }
  }, [savedTableConfig])

  const location = useLocation()

  const currentRequest = React.useRef<{ controller: AbortController; id: number } | null>(null)
  const requestCounter = React.useRef(0)

  React.useEffect(() => {
    const abortController = new AbortController()
    const requestId = ++requestCounter.current

    // Abort previous request if it exists (before starting new one)
    if (currentRequest.current) {
      currentRequest.current.controller.abort()
    }

    // Store new request as current
    currentRequest.current = { controller: abortController, id: requestId }

    const loadData = async () => {
      //Constructing Aircraft Filter

      // Only proceed if this is still the current request (prevents race condition with stale data)
      if (currentRequest.current?.id === requestId) {
        const aircraftIds = queryVariables?.aircraftIds
          ? queryVariables?.aircraftIds
          : Object.keys(aircraftFilter).filter(
            (aircraftId) => aircraftFilter[aircraftId]
          )
        //Constructing Remaining Value Filter
        let remainingValueFilter = {}
        if (timeframeDays.days > 0) {
          remainingValueFilter = {
            type: 'CALENDAR_DAYS',
            value: timeframeDays.days,
          }
        } else if (timeframeHours.hours > 0) {
          remainingValueFilter = {
            type: 'FLYING_HOURS',
            value: timeframeHours.hours,
          }
        } else if (timeframeCycles.cycles > 0) {
          remainingValueFilter = {
            type: 'CYCLES',
            value: timeframeCycles.cycles,
          }
        } else if (timeframeLandings.landings > 0) {
          remainingValueFilter = {
            type: 'LANDINGS',
            value: timeframeLandings.landings,
          }
        } else {
          remainingValueFilter = undefined
        }

        //Constructing Status Filter and Unscheduled Type Filter (Highlight Cards)
        let statuses = []
        const unscheduledType = queryVariables?.unscheduledType ?? []
        let onlyDueItems = queryVariables?.onlyDueItems ? true : false
        filterDataBy.forEach((filter) => {
          switch (filter) {
            case 'pastDueAndAog':
              statuses.push('OVERDUE')
              break
            case 'inToleranceAndComingDue':
              statuses.push('IN_TOLERANCE')
              statuses.push('APPROACHING_DUE')
              break
            case 'discrepanciesAndMel':
              // Empty the array
              unscheduledType.splice(0, unscheduledType.length)
              unscheduledType.push('DISCREPANCY')
              unscheduledType.push('MEL')
              break
            // These values needs to be in sync with stats backend.
            case 'adsb':
              // Empty the array
              unscheduledType.splice(0, unscheduledType.length)
              unscheduledType.push('AIRWORTHINESS_DIRECTIVE')
              unscheduledType.push('AD_SB')
              unscheduledType.push('SPECIAL_AD')
              unscheduledType.push('RECURRING_AD')
              unscheduledType.push('EMERGENCY_AD')
              unscheduledType.push('ALERT_SB')
              unscheduledType.push('MANDATORY_SB')
              unscheduledType.push('SERVICE_LETTER')
              unscheduledType.push('SERVICE_BULLETIN')
              break
            case 'ads':
              // Empty the array
              unscheduledType.splice(0, unscheduledType.length)
              unscheduledType.push('AIRWORTHINESS_DIRECTIVE')
              unscheduledType.push('AD_SB')
              unscheduledType.push('SPECIAL_AD')
              unscheduledType.push('RECURRING_AD')
              unscheduledType.push('EMERGENCY_AD')
              break
            case 'sbs':
              // Empty the array
              unscheduledType.splice(0, unscheduledType.length)
              unscheduledType.push('ALERT_SB')
              unscheduledType.push('MANDATORY_SB')
              unscheduledType.push('SERVICE_LETTER')
              unscheduledType.push('SERVICE_BULLETIN')
              break
            case 'adhocOnlyNotDue':
              statuses.push('NOT_DUE')
              break
            case 'adhocAndDuelist':
              onlyDueItems = false
              break
            default:
              break
          }
        })

        const {
          date,
          statuses: projectionStatuses,
          cadenceType,
          projectionGrid,
          ...otherProjectionFilters
        } = projectionFilterData
        statuses = statuses.concat(projectionStatuses ?? [])
        const showDueInNextNDays =
          date !== undefined ? dayjs(date).diff(dayjs(), 'days') : undefined

        const updatedQueryVariables = {
          ...queryVariables,
          ...getSearchConfig().variables,
          showChildItems,
          onlyDueItems,
          aircraftIds,
          statuses,
          unscheduledType,
          sortOrder: order,
          sortField: orderBy,
          showDueInNextNDays,
          // Intentional empty string check to avoid sending empty string to backend
          cadenceType: cadenceType === '' ? undefined : cadenceType,
          ...otherProjectionFilters,
        }
        if (remainingValueFilter) {
          updatedQueryVariables['remainingValueFilters'] = [
            remainingValueFilter as RemainingValueFilter,
          ]
        } else {
          // no-dd-sa:typescript-code-style/no-lonely-if
          if (projectionGrid.length > 0 && date !== undefined && date !== '') {
            updatedQueryVariables['remainingValueFilters'] = []
            projectionGrid.forEach((grid) => {
              // TODO Handle APU Hours
              if (grid.metric === 'APU Hours') return
              const projectionGridTypeMapping = {
                Landings: 'LANDINGS',
                Cycles: 'CYCLES',
                Hours: 'FLYING_HOURS',
              }
              updatedQueryVariables['remainingValueFilters'].push({
                type: projectionGridTypeMapping[grid.metric],
                value: grid.projection,
              } as RemainingValueFilter)
            })
          }
        }


        setQueryVariables?.(updatedQueryVariables)
        loadMaintenanceItems(
          updatedQueryVariables,
          true,
          abortController.signal,
          requestId,
        )
      }
    }

    // Promise.resolve() pushes loadData to microtask queue
    // new request starts after all cleanup is complete or else the new request might get aborted before it starts
    Promise.resolve().then(loadData)
  }, [
    powerSearchTerms,
    fuzzySearchTerms,
    aircraftFilter,
    timeFrameAll,
    timeframeDays,
    timeframeHours,
    timeframeCycles,
    timeframeLandings,
    filterDataBy,
    order,
    orderBy,
    projectionFilterData,
    location.search,
    showChildItems,
  ])

  const isColumnVisible = (column: string) => {
    const filteredAircrafts = Object.keys(aircraftFilter).filter(
      (key) => aircraftFilter[key]
    )
    if (
      column === 'tailNumber' &&
      (filteredAircrafts.length === 1 || Object.keys(aircrafts).length === 1)
    ) {
      let tailNumber = ''
      if (filteredAircrafts.length === 1) {
        tailNumber = aircrafts[filteredAircrafts[0]]?.tailNumber
      } else if (Object.keys(aircrafts).length === 1) {
        tailNumber = aircrafts[Object.keys(aircrafts)[0]]?.tailNumber
      }
      setTableTitle &&
        setTableTitle(tailNumber ? `${tailNumber} Tasks List` : 'Tasks List')
      return false
    } else if (column === 'tailNumber') {
      setTableTitle && setTableTitle('Tasks List')
    }
    if (filterDataBy.length === 0) return true
    return HIGHLIGHT_CARD_FILTER_COLUMN_MAP[filterDataBy[0]]
      ? HIGHLIGHT_CARD_FILTER_COLUMN_MAP[filterDataBy[0]].includes(column)
      : true
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof MaintenanceItem
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // update redux state
      selectMaintenanceItem(maintenanceItems)

      // let the parent know, if they care
      onSelectItem?.(maintenanceItems.map((item) => item.id))
      return
    }

    // unselect all
    unselectAll()
    onSelectItem?.([])
  }

  const handleClick = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      const maintenanceItem = maintenanceItems.find((item) => item.id === id)

      const isSelect = !selectedItemIds.includes(id)
      if (isSelect) {
        selectMaintenanceItem(maintenanceItem)
      } else {
        deselectMaintenanceItem(maintenanceItem)
      }

      onSelectItem?.(maintenanceItem.id, isSelect)
    },
    [maintenanceItems, onSelectItem, selectedItemIds, dispatch]
  )

  const onDetailTaskViewClick = (event, row) => {
    event.stopPropagation()
    setDetailedTaskView(row)
    setOpenDetailedTaskView(true)
  }

  if (initialLoading || isAircraftListLoading || tableColumnConfigLoading)
    return <Loading />

  if (!hasAircraftListLoaded) return <Loading />

  if (error) return <ApiError />

  return (
    <div className="w-full">
      <TableContainer>
        <InfiniteScroll
          dataLength={maintenanceItems.length}
          next={fetchNextMaintenancePage}
          hasMore={hasNextPage}
          loader={<Loading />}
          endMessage={
            <div className="flex h-full w-full flex-col items-center justify-center gap-2">
              <p>All Items Loaded.</p>
            </div>
          }
          scrollableTarget="main-content"
          scrollThreshold={0.25}
        >
          <Table aria-labelledby="tableTitle" size={'medium'}>
            <TableHead
              variant={variant}
              numSelected={selectedItemIds.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={maintenanceItems.length}
              disableTaskSelection={disableTaskSelection}
              isColumnVisible={isColumnVisible}
              // TEMPORARY: This should only exist while we create a better way of showing selected items
              selectedItemIds={selectedItemIds}
              maintenanceItems={maintenanceItems}
            />
            <TableBody>
              {maintenanceItems.map((row, index) => (
                <DuelistTableRow
                  variant={variant}
                  key={row.id}
                  row={row}
                  index={index}
                  selected={selectedItemIds}
                  onSelectRow={handleClick}
                  selectedAircraft={selectedAircraft}
                  aircrafts={aircrafts}
                  onDetailTaskViewClick={onDetailTaskViewClick}
                  disableTaskSelection={disableTaskSelection}
                  isColumnVisible={isColumnVisible}
                  discriminator={discriminator}
                  onChildTaskSliderClick={onChildTaskSliderClick}
                />
              ))}
            </TableBody>
          </Table>
          <LineItemDetailsDrawer
            open={openDetailedTaskView}
            onClose={() => {
              setOpenDetailedTaskView(false)
            }}
            parentTask={detailedTaskView}
            aircraft={aircrafts[detailedTaskView?.aircraftId]}
            taskViewMode={taskViewMode}
          />
        </InfiniteScroll>
      </TableContainer>
    </div>
  )
}

const DuelistTableRow = ({
  variant,
  row,
  index,
  selected,
  onSelectRow,
  selectedAircraft,
  aircrafts,
  onDetailTaskViewClick,
  disableTaskSelection,
  isColumnVisible,
  discriminator,
  onChildTaskSliderClick,
}) => {
  const orgName = useOrgName()
  const canReadCompliance = useHasPermission(
    Permissions.compliance.read,
    row.aircraftId
  )
  const { intervals } = useDueStatus(row, aircrafts[row.aircraftId])
  const { enabledColumns } = useMaintenanceItemTable(variant)
  const calculatedNextDueAt = get(row, 'calculatedNextDueAt')
  const projectedDate = calculatedNextDueAt
    ? dayjs(calculatedNextDueAt).format('MMM DD, YYYY')
    : 'N/A'
  const lastComplianceTimeArray = getLastComplianceTimeString(row)
  const { handleClick, handleMouseDown, handleMouseUp } =
    usePreventClickOnTextSelect()

  const isItemSelected = selected.includes(row.id)
  const labelId = `enhanced-table-checkbox-${index}`
  const maintenanceType =
    row.maintenanceType === 'UNSCHEDULED'
      ? row.adSbType || ''
      : capitalizeFirstLetter(row.maintenanceType) || ''

  const aircraftComponent = get(row, ['aircraftComponent'], {})
  const partNumber = get(aircraftComponent, 'partNumber')
  const serialNumber = get(aircraftComponent, 'serialNumber')

  const chips = [
    ...(row.disposition ? [row.disposition] : []),
    ...(row.childCount > 0 ? ['Parent'] : []),
    ...(row.parentId !== null ? ['Child'] : []),
    ...row.tags.map((tag) => tag.name),
  ]
  const tailTags = []
  if (!enableStickyColumns && !enabledColumns.includes('partSerial')) {
    if (partNumber) tailTags.push(`Part# ${partNumber}`)
    if (serialNumber) tailTags.push(`Serial# ${serialNumber}`)
  }
  if (row?.metadata?.procRef) tailTags.push(`Ref#: ${row?.metadata?.procRef}`)

  const onFullViewMtxItem = () => {
    navigate(routes.maintenanceItem({ orgName: orgName, id: row.id }))
  }

  const {
    isLedgerInProgress,
    isRevisionInProgress,
    isLogbookInProgress,
    isLogbookAwaitingSignature,
    isWorkOrderInProgress,
    isMaintenanceItemInProgress,
  } = inProgressValues(row)

  const getRowCell = (cellId) => {
    switch (cellId) {
      case 'ataCode':
        return (
          <StyledTableCell cellSize="medium" key={cellId}>
            <AtaCodeCell row={row} />
          </StyledTableCell>
        )
      case 'typeDescription':
        return (
          <StyledTableCell
            cellSize="large"
            sx={{ position: 'relative' }}
            key={cellId}
          >
            <TypeAndDescriptionDisplay
              description={row.title}
              chips={chips}
              highlight
              tailTags={tailTags.length > 0 ? tailTags : undefined}
            />
          </StyledTableCell>
        )
      case 'lastComplianceDate':
        return (
          <StyledTableCell cellSize="medium" key={cellId}>
            <MultiLineTypography>
              {lastComplianceTimeArray.map((val, index) => (
                <Typography key={index} component="div">
                  {val}
                </Typography>
              ))}
            </MultiLineTypography>
          </StyledTableCell>
        )
      case 'interval':
        return (
          <StyledTableCell cellSize="medium" key={cellId}>
            <MultiLineTypography>
              {intervals.length > 0 ? (
                intervals.map((item) => (
                  <Typography key={item} component="div" className="noWrap">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography> ---- </Typography>
              )}
            </MultiLineTypography>
          </StyledTableCell>
        )
      case 'nextDueStatus':
        return (
          <Tooltip title={`Projected Date: ${projectedDate}`} key={cellId}>
            <StyledTableCell cellSize="medium">
              <MultiLineTypography>
                {getNextDueString(row).length > 0 ? (
                  getNextDueString(row).map((item) => (
                    <Typography className="noWrap" key={item} component="div">
                      {item}
                    </Typography>
                  ))
                ) : (
                  <Typography> ---- </Typography>
                )}
              </MultiLineTypography>
            </StyledTableCell>
          </Tooltip>
        )
      case 'maxDue':
        return (
          <StyledTableCell cellSize="medium" key={cellId}>
            <MultiLineTypography>
              {getMaxNextDueString(row).length > 0 ? (
                getMaxNextDueString(row).map((item) => (
                  <Typography className="noWrap" key={item} component="div">
                    {item}
                  </Typography>
                ))
              ) : (
                <Typography> ---- </Typography>
              )}
            </MultiLineTypography>
          </StyledTableCell>
        )
      case 'calculatedNextDueAt':
        return (
          <StyledTableCell cellSize="medium" key={cellId}>
            <DueStatusChip
              maintenanceItem={row}
              aircraft={aircrafts[row.aircraftId]}
            />
          </StyledTableCell>
        )
      case 'partSerial':
        return (
          <StyledTableCell cellSize="medium" key={cellId}>
            {partNumber && (
              <Typography variant="caption" component="div">
                Part# {partNumber}
              </Typography>
            )}
            {serialNumber && (
              <Typography variant="caption" component="div" className="mt-1">
                Serial# {serialNumber}
              </Typography>
            )}
          </StyledTableCell>
        )
      default:
        return null
    }
  }

  return (
    <Tooltip
      title={isMaintenanceItemInProgress ? 'Task already in progress' : ''}
      placement="top"
    >
      <StyledTableRow
        hover
        aria-checked={isItemSelected}
        tabIndex={-1}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={(event) => {
          if (handleClick(event)) {
            onSelectRow(event, row.id)
          }
        }}
        selected={!disableTaskSelection && isItemSelected}
      >
        <DuelistTableCell
          padding="none"
          cellSize="small"
          className={
            enableStickyColumns
              ? `sticky left-0 w-[80px] ${
                  !isColumnVisible('tailNumber')
                    ? 'sticky-shadow-right'
                    : 'z-[1]'
                }`
              : undefined
          }
        >
          <div className="flex items-center">
            {!disableTaskSelection && (
              <Checkbox
                color="primary"
                disabled={
                  selectedAircraft ? selectedAircraft !== row.aircraftId : false
                }
                onChange={(event) => onSelectRow(event, row.id)}
                onClick={(event) => event.stopPropagation()}
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            )}
            {isLedgerInProgress && (
              <Tooltip title="Compliance in progress">
                <SvgIcon fontSize="small" color="info">
                  <OneQuarterStatusIcon />
                </SvgIcon>
              </Tooltip>
            )}
            {isRevisionInProgress && (
              <Tooltip title="Revision in progress">
                <SvgIcon fontSize="small" color="error">
                  <WarningStatusIcon />
                </SvgIcon>
              </Tooltip>
            )}
            {isLogbookInProgress && (
              <Tooltip title="Logbook in progress">
                <SvgIcon fontSize="small" color="info">
                  <TwoQuartersStatusIcon />
                </SvgIcon>
              </Tooltip>
            )}
            {isLogbookAwaitingSignature && (
              <Tooltip title="Logbook awaiting signature">
                <SvgIcon fontSize="small" color="warning">
                  <ThreeQuartersStatusIcon />
                </SvgIcon>
              </Tooltip>
            )}
          </div>
        </DuelistTableCell>

        {isColumnVisible('tailNumber') && (
          <DuelistTableCell
            cellSize="medium"
            className={
              enableStickyColumns
                ? `sticky-shadow-right sticky left-[80px]`
                : undefined
            }
          >
            <Link
              variant="body1"
              color="inherit"
              href={routes.planeDashboard({ orgName: orgName, id: row.aircraftId })}
            >
              <HighlightedTypography>
                {aircrafts[row.aircraftId]?.tailNumber}
              </HighlightedTypography>
            </Link>
            <Typography variant="caption">
              {aircrafts[row.aircraftId]?.model}
            </Typography>
          </DuelistTableCell>
        )}

        {enabledColumns.map((col) => getRowCell(col))}

        <DuelistTableCell
          cellSize="medium"
          align="right"
          className={
            enableStickyColumns
              ? `sticky-shadow-left sticky right-0`
              : undefined
          }
        >
          <div className="flex items-center justify-end gap-0.5">
            {isLedgerInProgress && (
              <IconButton
                tooltip="Open compliance in progress"
                disabled={!canReadCompliance}
                disabledTooltip="You do not have permission to view compliance"
                onClick={() =>
                  navigate(
                    routes.bulkCompliance({
                      orgName: orgName,
                      ledgerId: row.complianceLedger?.id,
                    })
                  )
                }
              >
                <TopicRoundedIcon />
              </IconButton>
            )}
            {isRevisionInProgress && (
              <IconButton
                tooltip="Continue revision"
                disabled={!canReadCompliance}
                disabledTooltip="You do not have permission to continue revision"
                onClick={() =>
                  navigate(
                    routes.bulkCompliance({
                      orgName: orgName,
                      ledgerId: row.complianceLedger?.id,
                    })
                  )
                }
              >
                <DifferenceRoundedIcon />
              </IconButton>
            )}
            {isWorkOrderInProgress && (
              <IconButton
                tooltip="Open work order in progress"
                onClick={() =>
                  navigate(
                    routes.workOrder({
                      orgName: orgName,
                      id: row.workOrderIdInProgress,
                    })
                  )
                }
              >
                <HomeRepairServiceIcon />
              </IconButton>
            )}
            <IconButton
              tooltip="Open sidebar"
              onClick={(event) => onDetailTaskViewClick(event, row)}
            >
              <RemoveRedEyeOutlinedIcon />
            </IconButton>
            {discriminator === 'associatedTasks' && (
              <IconButton
                tooltip="Edit Task"
                onClick={() => onChildTaskSliderClick(row)}
              >
                <EditIcon />
              </IconButton>
            )}
            <IconButton tooltip="Open detail page" onClick={onFullViewMtxItem}>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        </DuelistTableCell>
      </StyledTableRow>
    </Tooltip>
  )
}

export default TableWithData
