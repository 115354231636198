import { DuelistFilterTimeframeCycles } from 'src/types'

interface Option extends DuelistFilterTimeframeCycles {
  label: string
}

const CYCLES_FILTER_OPTIONS: Option[] = [
  {
    option: '25C',
    cycles: 25,
    label: '25 Cycles',
  },
  {
    option: '50C',
    cycles: 50,
    label: '50 Cycles',
  },
  {
    option: '75C',
    cycles: 75,
    label: '75 Cycles',
  },
  {
    option: '100C',
    cycles: 100,
    label: '100 Cycles',
  },
  {
    option: 'ALL',
    cycles: -1,
    label: 'Clear',
  },
]

export default CYCLES_FILTER_OPTIONS
