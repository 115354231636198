import { ReactElement } from 'react'

const HorizontalDivider = ({
  className,
}: {
  className?: string
}): ReactElement => (
  <div className="flex hidden flex-col md:block">
    <hr className={`h-0 w-full border-gray-200 ${className}`} />
  </div>
)

export default HorizontalDivider
