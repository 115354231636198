import React, { useEffect, useMemo } from 'react'

import { useApolloClient } from '@apollo/client'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {
  IconButton,
  Typography,
  TextField as MUITextField,
} from '@mui/material'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers'
import {
  getRemainingValueFromNextDueValue,
  nextDueValueSchema,
} from '@wingwork/common/src/jsonObjects'
import { getNextDueCadenceType } from '@wingwork/common/src/maintenanceItem'
import dayjs from 'dayjs'
import {
  AircraftUsageLog,
  ComplianceActivity,
  InternalWorkItem,
} from 'types/graphql'

import { useFormContext, useWatch } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import Button from 'src/components/MUI/Button'
import DatePicker from 'src/components/MUI/DatePicker'
import TextField from 'src/components/MUI/TextField'
import RemainingStatusChip from 'src/components/RemainingStatusChip'
import coreAircraftUsageLogFragment from 'src/fragments/AircraftUsageLog'
import { useSelector } from 'src/hooks/useSelector'

const keyToLabel = {
  date: 'Date',
  flying_hours: 'Hours',
  landings: 'Landings',
  cycles: 'Cycles',
}

export const RESET_NEXT_DUE_OVERRIDE = gql`
  mutation ResetNextDueOverride(
    $id: String!
    $input: UpsertMaintenanceNextDueInput!
  ) {
    upsertMaintenanceNextDue(id: $id, input: $input) {
      id
      isCompleted
      nextDueValue
      nextDueType
      nextDueOverride
      nextDueOverrideType
      nextDueOverrideBy
      updatedAt
    }
  }
`

interface ComplianceTimesProps {
  task: ComplianceActivity | InternalWorkItem
  workFlow: 'internalWorkItem' | 'complianceActivity'
  disableEdit?: boolean
}

const ComplianceTimes: React.FC<ComplianceTimesProps> = ({
  task,
  workFlow,
  disableEdit = false,
}) => {
  const formMethods = useFormContext()
  const client = useApolloClient()
  const [upsertMaintenanceNextDue] = useMutation(RESET_NEXT_DUE_OVERRIDE)
  const [editableInterval, setEditableInterval] = React.useState(false)
  const [overridingNextDue, setOverridingNextDue] = React.useState(false)
  const nextDueRoot = task.nextDue
  const nextDueValue = formMethods.watch('nextDue.nextDueValue')
  const nextDueOverride = formMethods.watch('nextDue.nextDueOverride')
  const cleanedNextDueOverride = nextDueValueSchema.cast(nextDueOverride ?? {})

  delete cleanedNextDueOverride['note']
  delete cleanedNextDueOverride['status']
  const nextDueOverrideCycles = useWatch({
    name: 'nextDue.nextDueOverride.cycles',
  })
  const nextDueOverrideLandings = useWatch({
    name: 'nextDue.nextDueOverride.landings',
  })
  const nextDueOverrideDate = useWatch({
    name: 'nextDue.nextDueOverride.date',
  })
  const nextDueOverrideFlyingHours = useWatch({
    name: 'nextDue.nextDueOverride.flying_hours',
  })

  const handleResetOverride = () => {
    const nextDue = task.nextDue
    upsertMaintenanceNextDue({
      variables: {
        input: {
          nextDueOverride: {},
          nextDueOverrideType: null,
          isCompleted: false, // isCompleted is always false for new/active next due
          nextDueType: getNextDueCadenceType(nextDue),
          verifyPin: false,
          nextDueValue: nextDue.nextDueValue,
        },
        id: nextDue.id,
      },
      onCompleted: () => {
        setOverridingNextDue(false)
        formMethods.setValue('nextDue.nextDueOverride', {})
      },
    })
  }

  useEffect(() => {
    setOverridingNextDue(false)
  }, [task.id, formMethods.formState.submitCount])

  const isRecurringTask = React.useMemo(() => {
    return task.nextDue !== null && task.nextDue !== undefined
  }, [task])

  useEffect(() => {
    setEditableInterval(false)
  }, [task])

  const usageLogId = useSelector((state) =>
    workFlow === 'complianceActivity'
      ? state.compliance.aircraftUsageLogId
      : state.workOrder.data.aircraftUsageLogId
  )

  const usageLog: AircraftUsageLog = client.readFragment({
    id: client.cache.identify({
      __typename: 'AircraftUsageLog',
      id: usageLogId,
    }),
    fragment: coreAircraftUsageLogFragment,
  })
  const { cadenceValue, trackedByComponent } = task.maintenanceItem

  //TODO: This will fail if selected usageLogId has missing non-integral components
  const componentUsageLog = React.useMemo(
    () =>
      usageLog?.ComponentUsageLog.find(
        (log) => log.component.id === trackedByComponent.id
      ),
    [usageLog, trackedByComponent]
  )

  const calculatedRemainingValue = useMemo(() => {
    if (
      !trackedByComponent ||
      !trackedByComponent?.id ||
      (!Object.keys(nextDueValue ?? {}).length &&
        !Object.keys(cleanedNextDueOverride ?? {}).length) ||
      !usageLogId
    )
      return '----'
    const mtxNextDueObj = {
      nextDueValue: nextDueValue ?? {},
      nextDueOverride: nextDueValueSchema.cast(cleanedNextDueOverride),
    }

    const remVal = getRemainingValueFromNextDueValue(
      mtxNextDueObj,
      cadenceValue as any,
      componentUsageLog
    )
    delete remVal['status']
    delete remVal['note']
    return Object.keys(remVal).map((key, id) => {
      return <RemainingStatusChip key={id} remVal={remVal[key]} />
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overridingNextDue,
    cleanedNextDueOverride,
    trackedByComponent,
    componentUsageLog,
    cadenceValue,
    nextDueOverrideCycles,
    nextDueOverrideLandings,
    nextDueOverrideDate,
    nextDueOverrideFlyingHours,
  ])

  return (
    <div>
      <div>
        <Typography variant="subtitle1" fontWeight="bold">
          Compliance
        </Typography>
        <Typography variant="caption">
          Apply new times to this task and adjust intervals.
        </Typography>
      </div>
      <div className="mt-2 grid grid-cols-4">
        <div className="flex flex-col gap-2 border-0 border-r border-solid border-gray-300 pr-2">
          <Typography variant="subtitle2" fontWeight={'bold'}>
            Times
          </Typography>
          <MUIDatePicker
            disabled
            label="Date"
            value={usageLog?.usageAsOf ? dayjs(usageLog?.usageAsOf) : null}
            slotProps={{ textField: { size: 'small' } }}
          />
          <MUITextField
            disabled
            size="small"
            label="Hours"
            value={componentUsageLog?.totalTimeSinceNew}
            variant="outlined"
            slotProps={{
              inputLabel: {
                shrink: componentUsageLog?.totalTimeSinceNew !== undefined,
              },
            }}
          />
          <MUITextField
            disabled
            size="small"
            label={
              trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                ? 'Landings'
                : 'Cycles'
            }
            value={componentUsageLog?.cycleSinceNew}
            variant="outlined"
            slotProps={{
              inputLabel: {
                shrink: componentUsageLog?.cycleSinceNew !== undefined,
              },
            }}
          />
        </div>

        <div className="flex flex-col gap-2 border-0 border-r border-solid border-gray-300 px-2">
          <div className="relative flex items-start justify-between">
            <Typography variant="subtitle2" fontWeight={'bold'}>
              Next Due
            </Typography>
            <IconButton
              disabled={!usageLogId || disableEdit}
              size="small"
              className="absolute right-0 top-0 mr-1"
              onClick={() => {
                if (!overridingNextDue) {
                  //editing - form
                  if (!Object.keys(cleanedNextDueOverride ?? {}).length) {
                    formMethods.setValue(
                      'nextDue.nextDueOverride',
                      nextDueRoot?.nextDueValue ?? {}
                    )
                  }
                } else {
                  // closing form
                  if (!Object.keys(nextDueRoot?.nextDueOverride ?? {}).length) {
                    formMethods.setValue('nextDue.nextDueOverride', {})
                  }
                }
                setOverridingNextDue(!overridingNextDue)
              }}
            >
              {overridingNextDue ? (
                <CloseIcon fontSize="small" />
              ) : (
                <EditIcon fontSize="small" />
              )}
            </IconButton>
          </div>

          {overridingNextDue ? (
            <>
              <DatePicker
                label="Date"
                name="nextDue.nextDueOverride.date"
                slotProps={{ textField: { size: 'small' } }}
              />
              <TextField
                name="nextDue.nextDueOverride.flying_hours"
                size="small"
                label="Hours"
                type="number"
                variant="outlined"
              />
              <TextField
                name={`nextDue.nextDueOverride.${
                  trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                    ? 'landings'
                    : 'cycles'
                }`}
                size="small"
                type="number"
                label={
                  trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                    ? 'Landings'
                    : 'Cycles'
                }
                variant="outlined"
              />
              <Button
                variant="outlined"
                fullWidth
                color="pastelRed"
                onClick={handleResetOverride}
              >
                Reset
              </Button>
            </>
          ) : (
            <>
              {/*
            when a next due override is in effect, show the override values only. whatever data that we have under
            nextDueOverride should be shown.
            but, when there is no override, show all the calculated values from nextDueValue.
            */}
              {Object.keys(cleanedNextDueOverride ?? {}).length ? (
                <>
                  {Object.keys(cleanedNextDueOverride).map((key) => {
                    return (
                      <PrimSecTextCombo
                        key={key}
                        primaryText={
                          key === 'date'
                            ? dayjs(cleanedNextDueOverride[key]).format(
                                'MMM DD, YYYY'
                              )
                            : cleanedNextDueOverride[key]
                        }
                        secondaryText={keyToLabel[key]}
                        variant="inverted"
                      />
                    )
                  })}
                </>
              ) : (
                <>
                  <PrimSecTextCombo
                    secondaryText="Date"
                    variant="inverted"
                    primaryText={
                      usageLogId && isRecurringTask && nextDueValue?.date
                        ? dayjs(nextDueValue?.date).format('MMM DD, YYYY')
                        : '--'
                    }
                  />
                  <PrimSecTextCombo
                    primaryText={
                      usageLogId && isRecurringTask
                        ? nextDueValue?.flying_hours ?? '--'
                        : '--'
                    }
                    secondaryText="Hours"
                    variant="inverted"
                  />
                  {trackedByComponent?.name?.toUpperCase() === 'AIRFRAME' ? (
                    <PrimSecTextCombo
                      primaryText={
                        usageLogId && isRecurringTask
                          ? nextDueValue?.landings ?? '--'
                          : '--'
                      }
                      secondaryText="Landings"
                      variant="inverted"
                    />
                  ) : (
                    <PrimSecTextCombo
                      primaryText={
                        usageLogId && isRecurringTask
                          ? nextDueValue?.cycles ?? '--'
                          : '--'
                      }
                      secondaryText="Cycles"
                      variant="inverted"
                    />
                  )}
                </>
              )}
            </>
          )}
          {!!Object.keys(cleanedNextDueOverride ?? {}).length &&
            !overridingNextDue && (
              <Typography variant="caption">
                Next due override is in effect
              </Typography>
            )}
        </div>

        <div className="flex flex-col gap-2 border-0 border-r border-solid border-gray-300 pl-2">
          <Typography variant="subtitle2" fontWeight={'bold'}>
            Remaining
          </Typography>
          {calculatedRemainingValue}
        </div>
        <div className="flex flex-col gap-2 pl-2">
          <div className="relative flex items-start justify-between">
            <Typography variant="subtitle2" fontWeight={'bold'}>
              Interval
            </Typography>
          </div>
          {editableInterval ? (
            <>
              <DatePicker
                label="Date"
                name="nextDue.nextDueOverride.date"
                slotProps={{ textField: { size: 'small' } }}
              />
              <TextField
                name="nextDue.nextDueOverride.hours"
                size="small"
                label="Hours"
                variant="outlined"
              />
              <TextField
                name="nextDue.nextDueOverride.landings"
                size="small"
                label={
                  trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                    ? 'Landings'
                    : 'Cycles'
                }
                variant="outlined"
              />
            </>
          ) : (
            <>
              <PrimSecTextCombo
                secondaryText="Months"
                variant="inverted"
                primaryText={cadenceValue?.months ?? 'N/A'}
              />
              <PrimSecTextCombo
                primaryText={cadenceValue?.flying_hours ?? 'N/A'}
                secondaryText="Hours"
                variant="inverted"
              />
              <PrimSecTextCombo
                primaryText={
                  trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                    ? cadenceValue.landings ?? 'N/A'
                    : cadenceValue.cycles ?? 'N/A'
                }
                secondaryText={
                  trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                    ? 'Landings'
                    : 'Cycles'
                }
                variant="inverted"
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ComplianceTimes
