import React from 'react'

import { Typography } from '@mui/material'

import ComplianceTimes from 'src/components/Compliance/FormSections/ComplianceTimes'
import WorkInformation from 'src/components/Compliance/FormSections/WorkInformation'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useSelector } from 'src/hooks/useSelector'

const ItemInformationForm = ({ task }) => {
  const { data: users } = useGetUsers()

  const aircraftId = useSelector((state) => state.compliance.aircraftId)
  const canUpdateCompliance = useHasPermission(
    Permissions.compliance.update,
    aircraftId
  )

  return (
    <div className="p-3">
      <ComplianceTimes
        task={task}
        workFlow="complianceActivity"
        disableEdit={!canUpdateCompliance}
      />
      <div className="mt-2">
        <Typography variant="subtitle1" fontWeight="bold">
          Work Information
        </Typography>
        <WorkInformation users={users?.users} compact />
      </div>
    </div>
  )
}

export default ItemInformationForm
