import { DuelistFilterTimeframeDays } from 'src/types'

interface Option extends DuelistFilterTimeframeDays {
  label: string
}

const DAY_FILTER_OPTIONS: Option[] = [
  {
    option: '30D',
    days: 30,
    label: '30 Days',
  },
  {
    option: '60D',
    days: 60,
    label: '60 Days',
  },
  {
    option: '90D',
    days: 90,
    label: '90 Days',
  },
  {
    option: '120D',
    days: 120,
    label: '120 Days',
  },
  {
    option: 'ALL',
    days: -1,
    label: 'Clear',
  },
  //Disabled this for now because this date picker is a paid component and we haven't purchased it yet
  // {
  //   option: 'CUSTOM',
  //   label: 'Custom Period',
  // },
]

export default DAY_FILTER_OPTIONS
