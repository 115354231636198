import React, { useEffect } from 'react'

import AddIcon from '@mui/icons-material/Add'
import { TableHead as MuiTableHead, Typography, TableCell } from '@mui/material'
import Chip from '@mui/material/Chip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  ComplianceActivity,
  DiscrepancyStatus,
  MaintenanceItem,
} from 'types/graphql'

import ApiError from 'src/components/ApiError'
import Loading from 'src/components/Loading'
import TypeAndDescriptionDisplay from 'src/components/TypeAndDescriptionDisplay'
import usePaginatedMaintenanceItemList from 'src/hooks/requests/usePaginatedMaintenanceItemList'

import AddDiscrepancySlider from '../../Discrepanies/AddDiscrepancySlider'
import Button from 'src/components/MUI/Button'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useSelector } from 'src/hooks/useSelector'

interface NonRoutineTasksTableProps {
  complianceActivity: ComplianceActivity
  aircraftUsageLogId: string
  refreshLedger: () => void
}

const DiscrepanciesTable: React.FC<NonRoutineTasksTableProps> = ({
  complianceActivity,
  aircraftUsageLogId,
  refreshLedger,
}) => {
  const [showAddDiscrepancyModal, setShowAddDiscrepancyModal] =
    React.useState(false)
  const [clickedTask, setClickedTask] =
    React.useState<MaintenanceItem>(undefined)
  const [readyForReview, setReadyForReview] = React.useState(true)
  const {
    maintenanceItems,
    hasNextPage,
    initialLoading,
    error,
    loadMaintenanceItems,
    fetchNextMaintenancePage,
  } = usePaginatedMaintenanceItemList()

  const [tableRefreshKey, setTableRefreshKey] = React.useState(0)

  useEffect(() => {
    if (complianceActivity) {
      loadMaintenanceItems(
        {
          discrepancyCompActivityId: complianceActivity.id,
          unscheduledType: ['DISCREPANCY', 'MEL', 'NEF', 'WATCH_LIST'],
        },
        true
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceActivity, tableRefreshKey])

  const getChipColor = (status: DiscrepancyStatus) => {
    switch (status) {
      case 'OPEN':
        return 'error'
      case 'CLOSED':
        return 'success'
      default:
        return 'default'
    }
  }

  const getDiscrepancyChips = (row: MaintenanceItem) => {
    const chips: string[] = [row.adSbType as string]
    if (row.tags.length) {
      row.tags.forEach((tag) => chips.push(tag.name))
    }
    return chips
  }

  const getNextDueValue = (row: MaintenanceItem) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nextDueValue: any = row.maintenanceNextDue[0]?.nextDueValue ?? {}
    if (nextDueValue?.date) {
      return dayjs(nextDueValue.date).format('MM/DD/YYYY')
    }
    return 'N/A'
  }

  const aircraftId = useSelector((state) => state.compliance.aircraftId)

  if (initialLoading) return <Loading />

  if (error) return <ApiError />

  const canCreateMtxItem = useHasPermission(
    Permissions.maintenanceItem.create,
    aircraftId
  )

  return (
    <div className="p-3">
      <div>
        <Typography variant="subtitle1" fontWeight="bold">
          Discrepancies
        </Typography>
      </div>
      <TableContainer className="mt-1">
        {aircraftUsageLogId ? (
          <InfiniteScroll
            dataLength={maintenanceItems.length}
            next={fetchNextMaintenancePage}
            hasMore={hasNextPage}
            loader={<Loading />}
            endMessage={
              <div className="flex h-full w-full flex-col items-center justify-center gap-2">
                <p>All Items Loaded.</p>
              </div>
            }
            style={{ overflowY: 'clip' }}
            scrollableTarget="parent"
            scrollThreshold={0.65}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <TableHead />
              <TableBody>
                {maintenanceItems.map((row) => {
                  if (row.discrepancyStatus === 'OPEN' && readyForReview) {
                    setReadyForReview(false)
                  }
                  return (
                    <React.Fragment key={row.id}>
                      <TableRow
                        hover
                        tabIndex={-1}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setClickedTask(row)
                          setShowAddDiscrepancyModal(true)
                        }}
                      >
                        <TableCell>
                          <Typography>{row.title}</Typography>
                        </TableCell>
                        <TableCell sx={{ position: 'relative' }}>
                          <TypeAndDescriptionDisplay
                            description={row.description}
                            chips={getDiscrepancyChips(row)}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography>{getNextDueValue(row)}</Typography>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={row.discrepancyStatus}
                            color={getChipColor(row.discrepancyStatus)}
                            variant="outlined"
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>
          </InfiniteScroll>
        ) : (
          <Typography variant="body1">
            Apply times first to add discrepancies.
          </Typography>
        )}
      </TableContainer>
      <Button
        variant="outlined"
        color="base"
        startIcon={<AddIcon />}
        size="small"
        className="mt-2"
        disabled={!aircraftUsageLogId}
        onClick={() => {
          setClickedTask(undefined)
          setShowAddDiscrepancyModal(true)
        }}
        locked={!canCreateMtxItem}
        lockedTooltip="You do not have permission"
      >
        Add Discrepancy
      </Button>

      <AddDiscrepancySlider
        open={showAddDiscrepancyModal}
        onClose={(refreshTable) => {
          setClickedTask(undefined)
          setShowAddDiscrepancyModal(false)
          if (refreshTable) {
            refreshLedger()
            setTableRefreshKey(tableRefreshKey + 1)
          }
        }}
        isWorkOrder={false}
        complianceOrWorkOrder={complianceActivity}
        selectedTask={clickedTask}
        aircraftUsageLogId={aircraftUsageLogId}
      />
    </div>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'title',
    disablePadding: false,
    label: 'TITLE',
  },
  {
    id: 'typeDescription',
    disablePadding: false,
    label: 'TYPE, DESCRIPTION',
  },
  {
    id: 'nextDue',
    disablePadding: false,
    label: 'NEXT DUE',
  },
  {
    id: 'status',
    disablePadding: false,
    label: 'STATUS',
  },
]

const TableHead: React.FC = () => {
  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'actions' ? 'right' : 'left'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  )
}

export default DiscrepanciesTable
