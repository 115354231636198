import React from "react";
import SelectedListTable from "./SelectedListTable";
import DueListPicker from "../Picker";

interface SelectedListProps {
  aircraftIds: string[],
  selectedTasks: string[],
  setSelectedTasks: (tasks: string[]) => void
}

const SelectedList = ({ aircraftIds, selectedTasks = [], setSelectedTasks }: SelectedListProps) => {
  const [addMode, setAddMode] = React.useState(false)
  return (
    <div>
      {addMode ?
        <DueListPicker
          aircraftIds={aircraftIds}
          aircraftFilterEnabled={false}
          onDismiss={() => setAddMode(false)}
          onAdd={async (pickedTasks: string[]): Promise<void> => {

            if (selectedTasks.length === 0) return
            setSelectedTasks([
              ...selectedTasks,
              ...pickedTasks,
            ])
            setAddMode(false)
          }}
        /> : <SelectedListTable selectedTasks={selectedTasks} onAddTaskClick={() => setAddMode(true)} onRemoveTasksClick={
          (tasks: string[]) => {
            setSelectedTasks(selectedTasks.filter(task => !tasks.includes(task)))
          }
        } />
      }
    </div>
  )
};


export default SelectedList
