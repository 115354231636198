import {
  OrganizationSwitcher,
  UserButton,
  useOrganizationList,
} from '@clerk/clerk-react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, CircularProgress, CssBaseline, IconButton, styled, Toolbar } from '@mui/material'
import AiSparklesIconButton from 'src/components/AiSparklesIcon'
import AiAssistantDrawer from './AiAssistantDrawer'
import { routes, useLocation } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

import Toast from 'src/components/Toast'
import useHistoryCount from 'src/hooks/useHistoryCount'
import useUploadEventListeners from 'src/hooks/useUploadEventListeners'

import Drawer from './Drawer'
import NotificationPopover from '../../components/Notifications/NotificationPopover'
import { useDispatch } from 'src/hooks/useDispatch'
import { navDrawerExpandedToggled, selectCommentsDrawerExpanded, selectNavDrawerExpanded } from 'src/slices/appLayoutSlice'
import { useSelector } from 'src/hooks/useSelector'
import SupportIcon from 'src/components/Comments/SupportIcon'
import { commentsDrawerWidth } from 'src/components/Comments/CommentsDrawerScaffold'
import CommentContext from 'src/components/Comments/CommentContext'
import CommentsDrawer from 'src/components/Comments/CommentsDrawer'
import useComments from 'src/components/Comments/useComments'
import CommentIcon from 'src/components/Comments/CommentIcon'
import useIsAdmin from 'src/hooks/useIsAdmin'
import { useOrgName } from 'src/hooks/useOrgName'
import useAppLayoutState from 'src/hooks/useAppLayoutSlice'


const AppContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100vh',
  width: '100vw',
})

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: 'calc(100vh - 64px)',
  width: '100vw',
})

export const transitionOutClasses = 'transition-all ease-out duration-300'
export const transitionInClasses = 'transition-all ease-in duration-300'

const MainContent = styled('div', { shouldForwardProp: (prop) => prop !== 'commentsDrawerExpanded' })<{
  commentsDrawerExpanded?: boolean;
}>(({ commentsDrawerExpanded }) => ({
  flexGrow: 1,
  width: `calc(100vw - ${commentsDrawerWidth}px)`,
  overflow: 'auto',
  marginRight: commentsDrawerExpanded ? 0 : -commentsDrawerWidth,
}));


type AppLayoutProps = {
  children?: React.ReactNode
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const { organizationList, isLoaded } = useOrganizationList()
  const dispatch = useDispatch()
  const orgSlug = useOrgName()
  const { navDrawerExpanded, setAiAssistantDrawerOpen } = useAppLayoutState()
  const { CommentsProvider, roomId } = useComments({ isAdmin: false })
  const { CommentsProvider: AdminCommentsProvider } = useComments({ isAdmin: true })
  const commentsDrawerExpanded = useSelector(selectCommentsDrawerExpanded)
  const showOrganizationSwitcher = React.useMemo(
    () => isLoaded && organizationList.length > 1,
    [isLoaded, organizationList]
  )
  const isAdmin = useIsAdmin()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const isDocHubPreview = queryParams.get('view') === 'docHubPreview'
  useHistoryCount()
  useUploadEventListeners()

  if (isDocHubPreview) {
    return (
      <div className="flex">
        <CssBaseline />
        <main className="flex-grow">
          {children}
        </main>
      </div>
    )
  }

  return (
    <CommentContext.Provider value={{ CommentsProvider, AdminCommentsProvider, roomId }}>
      <AppContainer>
        <CssBaseline />
        <AppBar position="relative" color="inherit" elevation={1} sx={{ zIndex: (theme) => theme.zIndex.appBar }}>
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={() => dispatch(navDrawerExpandedToggled())}
              edge="start"
            >
              <MenuIcon />
            </IconButton>

            <img src="/WingWork.png" alt="Wingwork Logo" width={'200px'} />

            <div className="flex-grow" />

            <div className="flex items-center gap-1">
              <IconButton size="large" aria-label="help area" color="inherit">
                <HelpOutlineIcon />
              </IconButton>
              <CommentsProvider>
                <CommentIcon />
                <NotificationPopover />
              </CommentsProvider>
              {isAdmin && <SupportIcon />}
              <AiSparklesIconButton onClick={() => setAiAssistantDrawerOpen(true)} />
              {showOrganizationSwitcher && (
                <div className="pl-2">
                  <OrganizationSwitcher
                    hidePersonal
                    afterSelectOrganizationUrl={routes.landing()}
                  />
                </div>
              )}
              <UserButton showName />
            </div>
          </Toolbar>
        </AppBar>
        <ContentContainer>
          <AiAssistantDrawer />
          <Drawer expanded={navDrawerExpanded} />
          <MainContent
            className={`${navDrawerExpanded ? transitionInClasses : transitionOutClasses}`}
            commentsDrawerExpanded={commentsDrawerExpanded}
            id="main-content" // ! Most InfiniteScroll components in the app reference this ID.  Be very wary of changing it.
          >
            {children}
          </MainContent>
          <CommentsDrawer />
        </ContentContainer>
        <Toaster position="top-right" containerClassName="mt-7 z-51">
          {(t) => <Toast t={t} message={t.message} />}
        </Toaster>
      </AppContainer>
    </CommentContext.Provider>
  )
}

export default AppLayout
