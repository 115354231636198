import { useCallback, useMemo } from 'react'

import { Typography } from '@mui/material'
import dayjs from 'dayjs'

const RemainingValue = ({
  regularValue,
  overrideValue,
  sinceValue,
  cadenceLabel,
  isRecurringTask,
  isDate = false,
  dateDiffUnit = 'month',
}) => {
  const dateCalc = useCallback((current, old) => {
    return dayjs(current).diff(dayjs(old), dateDiffUnit, true).toFixed()
  }, [])

  const text = useMemo(() => {
    if (!sinceValue) {
      return '--'
    }

    if (!isRecurringTask) {
      return '--'
    }

    if (overrideValue && overrideValue !== regularValue) {
      return (
        <div className="flex gap-1">
          <span
            className={`line-through ${
              regularValue < sinceValue ? 'text-red-500' : 'text-gray-400'
            }`}
          >
            {isDate
              ? dateCalc(regularValue, sinceValue)
              : (regularValue - sinceValue).toFixed(2)}{' '}
            {cadenceLabel} {regularValue < sinceValue ? '[Overdue]' : ''}
          </span>
          <span
            className={`${
              overrideValue < sinceValue ? 'text-red-500' : 'text-black'
            }`}
          >
            {isDate
              ? dateCalc(overrideValue, sinceValue)
              : (overrideValue - sinceValue).toFixed(2)}{' '}
            {cadenceLabel} {overrideValue < sinceValue ? '[Overdue]' : ''}
          </span>
        </div>
      )
    }

    return `${
      isDate
        ? dateCalc(regularValue, sinceValue)
        : (regularValue - sinceValue).toFixed(2)
    } ${cadenceLabel ?? ''} ${regularValue < sinceValue ? '[Overdue]' : ''}`
  }, [
    sinceValue,
    isRecurringTask,
    overrideValue,
    isDate,
    dateCalc,
    regularValue,
    cadenceLabel,
  ])

  return (
    <Typography
      variant="body2"
      className={`${regularValue < sinceValue ? 'text-red-500' : 'text-black'}`}
    >
      {text}
    </Typography>
  )
}

export default RemainingValue
