import React, { useEffect } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { Button, Chip, Paper, Typography } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { get } from 'lodash'
import { MaintenanceItem } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import ApiError from 'src/components/ApiError'
import Loading from 'src/components/Loading'
import ReusableTimeline, {
  ReusableTimelineItem,
} from 'src/components/MUI/Timeline'
import useComplianceActivitiesRecords from 'src/hooks/requests/useComplianceActivitiesRecords'
import { useOrgName } from 'src/hooks/useOrgName'
import { formatDateForDisplay } from 'src/utils/helpers'

interface WorkCompletedTabProps {
  parentTask: MaintenanceItem
}

const WorkCompletedTab: React.FC<WorkCompletedTabProps> = ({ parentTask }) => {
  dayjs.extend(relativeTime)
  const { complianceActivities, loadComplianceActivities, loading, error } =
    useComplianceActivitiesRecords()

  const orgName = useOrgName()

  useEffect(() => {
    if (parentTask === undefined) return
    loadComplianceActivities({
      maintenanceItemIds: [parentTask.id],
      showCompletedOnly: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTask])

  if (loading) return <Loading />

  if (error) return <ApiError />

  return (
    <div className="flex flex-col gap-2 px-3 py-2">
      <Paper className="rounded-xl">
        {complianceActivities.length > 0 ? (
          <ReusableTimeline>
            {complianceActivities.map((activity) => {
              const correctiveAction = activity.correctiveAction
              const signedBy = activity.complianceLedger?.inspectedBy
              return (
                <ReusableTimelineItem
                  icon={<CheckIcon fontSize="small" />}
                  dotColor="#7FABFF"
                  title={dayjs(
                    activity.complianceLedger?.aircraftUsageLog?.usageAsOf
                  ).fromNow()}
                  description={formatDateForDisplay(
                    activity.complianceLedger?.aircraftUsageLog?.usageAsOf
                  )}
                  key={activity.id}
                >
                  <div className="flex flex-col gap-2">
                    <div>
                      {correctiveAction && (
                        <Typography variant="body2">
                          Ensure all systems are functioning correctly and that
                          there are no visible signs of damage or anomalies.
                        </Typography>
                      )}

                      <Chip
                        label={`${get(signedBy, 'firstName', '')} ${get(
                          signedBy,
                          'lastName',
                          ''
                        )}`}
                        size="small"
                        className="mt-1"
                        variant="outlined"
                      />
                    </div>
                    <div>
                      {/* Todo: navigate to logbook entry */}
                      {activity?.MaintenanceLogbookActivity?.Logbook?.id && (
                        <Button
                          variant="outlined"
                          color="base"
                          size="small"
                          startIcon={<InsertDriveFileOutlinedIcon />}
                          onClick={() =>
                            navigate(
                              routes.logbookEntry({
                                orgName,
                                entryId:
                                  activity.MaintenanceLogbookActivity.Logbook
                                    .id,
                              })
                            )
                          }
                        >
                          Logbook Entry
                        </Button>
                      )}
                    </div>
                  </div>
                </ReusableTimelineItem>
              )
            })}
          </ReusableTimeline>
        ) : (
          <div className="flex w-full items-center justify-center p-5">
            <Typography variant="body2">
              No work has been completed for this task.
            </Typography>
          </div>
        )}
      </Paper>
    </div>
  )
}

export default WorkCompletedTab
