import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ModalName =
  | 'aircraftTimeModal'
  | 'tagModal'
  | 'aircraftSelectorModal'
  | 'confirmationModal'
  | 'auditLogModal'
  | 'auditNotesModal'
  | 'example'
  | 'bulkComplianceEditModal'
  | 'addCustomWorkItemModal'
  | 'aircraftUsageParameterModal'
  | 'userSelectorModal'
  | 'complianceEventLogsModal'
  | 'underRevisionWarningModal'
  | 'vendorDrawer'
  | 'requestPartsModal'
  | 'requestServiceModal'
  | 'addressEditorModal'
  | 'shippingMethodEditorModal'
  | 'addPartLineItemModal'
  | 'addServiceLineItemModal'
  | 'addLineItemModal'
  | 'purchaseOrderReviewModal'
  | 'rejectItemModal'
  | 'sendPurchaseOrderModal'
  | 'vendorEditorModal'
  | 'requestedLineItemsPickerModal'
  | 'purchaseOrderPdfViewModal'
  | 'saveLaborTransactionModal'
  | 'laborTransactionsModal'
  | 'printLogbookModal'
  | 'printWorkCardModal'
  | 'purchaseOrderComplianceModal'
  | 'addMtxItemsToPurchaseOrderModal'
  | 'bulkReadyForReviewReassignmentModal'
  | 'addReferenceModal'
  | 'viewReferenceModal'
  | 'lifecycleEventModal'

// Type for Modal state
interface ModalState {
  modals: {
    [name: string]: {
      isOpen: boolean
      data?: any
    }
  }
  openingOrder: Array<{
    name: ModalName
    discriminator?: string
    isClosing?: boolean
  }>
}

// Initial state
const initialState: ModalState = {
  modals: {
    aircraftTimeModal: {
      data: { loading: false, disableCreate: false, disablePick: false },
      isOpen: false,
    },
  },
  openingOrder: [],
}

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        name: ModalName
        data?: { discriminator?: string }
      }>
    ) => {
      const { name, data } = action.payload
      const discriminator = data?.discriminator

      // If the modal does not exist, create it and set it to open.
      if (!state.modals[name]) {
        state.modals[name] = { isOpen: true, data }
      }
      // If the modal does exist, set it to open and update its data.
      else {
        state.modals[name].isOpen = true
        state.modals[name].data = data
      }

      // Add to opening order
      state.openingOrder.push({ name, discriminator })
    },
    closeModal: (
      state,
      action: PayloadAction<{ name: ModalName; discriminator?: string }>
    ) => {
      const { name, discriminator } = action.payload

      // If the modal exists, set it to closed and reset its data.
      if (state.modals[name]) {
        state.modals[name].isOpen = false
        state.modals[name].data = {}
      }

      // Mark as closing in the opening order
      const modalIndex = state.openingOrder.findIndex(
        (modal) => modal.name === name && modal.discriminator === discriminator
      )
      if (modalIndex !== -1) {
        state.openingOrder[modalIndex].isClosing = true
      }
    },
    removeClosedModals: (state) => {
      state.openingOrder = state.openingOrder.filter(
        (modal) => !modal.isClosing
      )
    },
    setModalData: (
      state,
      action: PayloadAction<{
        name: ModalName
        data: { discriminator?: string }
      }>
    ) => {
      const { name, data } = action.payload

      if (!state.modals[name]) {
        state.modals[name] = { isOpen: false, data }
      } else {
        state.modals[name].data = {
          ...state.modals[name].data,
          ...data,
        }
      }
    },
  },
})

export const { openModal, closeModal, removeClosedModals, setModalData } =
  modalsSlice.actions

export default modalsSlice.reducer
