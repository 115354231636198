import React from 'react'

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Typography } from '@mui/material'

const ApiError = () => {
  return (
    <div className="flex h-full w-full items-center justify-center gap-2 p-2">
      <ErrorOutlineOutlinedIcon />
      <Typography>Uh Oh, Something went wrong. Please try again.</Typography>
    </div>
  )
}

export default ApiError
