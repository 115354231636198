import React from 'react'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Chip, IconButton, Typography } from '@mui/material'
import {
  getIntervals,
  getNextDueAdjustmentString,
  getNextDueString,
} from '@wingwork/common/src/maintenanceItem'
import { get } from 'lodash'
import { Aircraft, MaintenanceItem } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import DueStatusChip from 'src/components/DueStatusChip/DueStatusChip'
import Button from 'src/components/MUI/Button'
import { MenuOption } from 'src/components/MUI/DotsMenu/DotsMenu'
import ReusableTabs, { TabComponent } from 'src/components/MUI/Tabs'
import { useDispatch } from 'src/hooks/useDispatch'
import { useOrgName } from 'src/hooks/useOrgName'
import Field from 'src/pages/MaintenanceItemPage/components/Field'
import { openModal } from 'src/slices/modal'
import { isMaintenanceItemInProgress } from 'src/utils/maintenanceItem'

import ChildTasksTab from './ChildTasksTab'
import ComplianceTab from './ComplianceTab'
import WorkCompletedTab from './WorkCompletedTab'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'

interface AdvancedDrawerContentRootProps {
  onClose: () => void
  parentTask: MaintenanceItem
  aircraft: Aircraft
}

const AdvancedDrawerContentRoot: React.FC<AdvancedDrawerContentRootProps> = ({
  onClose,
  parentTask,
  aircraft,
}) => {
  const dispatch = useDispatch()
  const componentRef = React.useRef<HTMLDivElement>(null)
  const [showMoreDetails, setShowMoreDetails] = React.useState(false)
  const urlOrgSlug = useOrgName()
  const hasPermission =
    useHasPermission(Permissions.compliance.create, aircraft.id) &&
    useHasPermission(Permissions.compliance.complete, aircraft.id)

  const nextDueDate = get(parentTask, ['calculatedNextDueAt'])
  const aircraftComponent = get(parentTask, 'aircraftComponent')
  const dotMenuOptions: MenuOption[] = [
    {
      label: 'Create New Task',
      onClick: () => {},
    },
    {
      label: 'Print Duelist',
      onClick: () => console.log('Edit'),
    },
    {
      label: 'Save Duelist as...',
      onClick: () => console.log('Edit'),
    },
  ]

  const intervals = getIntervals(parentTask)
  const nextDueAdjustments = getNextDueAdjustmentString(
    parentTask.maintenanceNextDue?.[0]
  )

  return (
    <div className="relative h-full" ref={componentRef}>
      <div
        className="h-full overflow-auto bg-[#EBECF3] pb-12"
        style={{ width: '450px' }}
      >
        <div className="bg-[#ffffff] p-2 pb-3">
          <div className="flex items-center justify-between">
            <IconButton onClick={onClose} className="mb-1">
              <ArrowForwardIosIcon />
            </IconButton>
            <div className="flex items-center">
              <Button
                variant="outlined"
                color="base"
                size="small"
                onClick={() => {
                  navigate(
                    routes.maintenanceItem({
                      orgName: urlOrgSlug,
                      id: parentTask.id,
                    })
                  )
                }}
              >
                View Task
              </Button>
              {/* <DotsMenu options={dotMenuOptions} /> */}
            </div>
          </div>
          <div className="flex items-center gap-1">
            <Typography variant="h6">{`${parentTask?.ataCode} ${parentTask?.manufactureCode}`}</Typography>
            {nextDueDate && (
              <Chip label="Scheduled" variant="outlined" size="small" />
            )}
          </div>
          <div className="grid grid-cols-[75%_25%]">
            <div>
              <Typography variant="caption">Description</Typography>
              <Typography variant="body2">
                {get(parentTask, 'title')}
              </Typography>
            </div>
            <div className="flex items-center justify-center">
              <Button
                variant="outlined"
                color="base"
                size="small"
                onClick={() => setShowMoreDetails(!showMoreDetails)}
              >
                {`Show ${showMoreDetails ? 'Less' : 'More'}`}
              </Button>
            </div>
          </div>
          {showMoreDetails && (
            <div className="mt-1 grid grid-cols-2">
              <div className="flex flex-col gap-1">
                <Field label="Next Due">
                  {getNextDueString(parentTask).length > 0 ? (
                    getNextDueString(parentTask).map((item) => {
                      return (
                        <Typography key={item} variant="body2">
                          {item}
                        </Typography>
                      )
                    })
                  ) : (
                    <Typography> ---- </Typography>
                  )}
                </Field>

                {/* <PrimSecTextCombo
                  primaryText="Todo: Recurring"
                  secondaryText="Recurring"
                  variant="inverted"
                />
                */}
                <Field label="Interval">
                  {intervals.length > 0 ? (
                    intervals.map((item) => {
                      return (
                        <Typography key={item} variant="body2">
                          {item}
                        </Typography>
                      )
                    })
                  ) : (
                    <Typography> ---- </Typography>
                  )}
                </Field>
              </div>
              <div className="mb-1 flex flex-col gap-1">
                <Field label="Remaining + Tolerance">
                  <DueStatusChip
                    maintenanceItem={parentTask}
                    aircraft={aircraft}
                  />
                </Field>
                {/*<PrimSecTextCombo
                  primaryText="Todo: Threshold"
                  secondaryText="Threshold"
                  variant="inverted"
                />
                */}
                <Field label="Adjustment">
                  {nextDueAdjustments.length > 0 ? (
                    nextDueAdjustments.map((item) => {
                      return (
                        <Typography key={item} variant="body2">
                          {item}
                        </Typography>
                      )
                    })
                  ) : (
                    <Typography> ---- </Typography>
                  )}
                </Field>
              </div>
              {aircraftComponent && (
                <>
                  <div className="flex flex-col gap-1">
                    <PrimSecTextCombo
                      primaryText={aircraftComponent?.partNumber}
                      secondaryText="Part Number"
                      variant="inverted"
                    />
                    <PrimSecTextCombo
                      // Todo: Make sure this is the correct value
                      primaryText={aircraftComponent?.serialNumber}
                      secondaryText="Serial Number"
                      variant="inverted"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <PrimSecTextCombo
                      // Todo: Make sure this is the correct value
                      primaryText={aircraftComponent?.description || '--'}
                      secondaryText="Part Description"
                      variant="inverted"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <ReusableTabs tabsClassName="bg-[#ffffff]" variant="fullWidth">
          <TabComponent label="Compliance">
            <ComplianceTab maintenanceItemId={parentTask.id} />
          </TabComponent>
          <TabComponent label="Child Tasks">
            <ChildTasksTab parentTask={parentTask} aircraft={aircraft} />
          </TabComponent>
          <TabComponent label="Work Completed">
            <WorkCompletedTab parentTask={parentTask} />
          </TabComponent>
        </ReusableTabs>
      </div>
      <div className="absolute bottom-0 left-0 right-0 z-[1] float-right flex justify-end gap-2 bg-[#FAFAFA] p-2">
        {!isMaintenanceItemInProgress(parentTask) && (
          <>
            <Button
              locked={!hasPermission}
              lockedTooltip="You do not have permission to add compliance"
              variant="outlined"
              color="base"
              onClick={() =>
                dispatch(
                  openModal({
                    name: 'confirmationModal',
                    data: { discriminator: 'sidebarCompleteCompliance' },
                  })
                )
              }
            >
              Create Compliance
            </Button>
            <Button
              locked={!hasPermission}
              lockedTooltip="You do not have permission to complete compliance (and create a logbook)"
              onClick={() =>
                dispatch(
                  openModal({
                    name: 'confirmationModal',
                    data: { discriminator: 'sidebarCreateLogbook' },
                  })
                )
              }
            >
              Create Logbook Entry
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default AdvancedDrawerContentRoot
