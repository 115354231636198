import { useState, useEffect } from 'react'

function useDiscriminator(
  open: boolean,
  discriminator?: string,
  requestedDiscriminator?: string
): boolean {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!open) {
      setShow(false)
      return
    }

    if (
      typeof discriminator === 'undefined' &&
      typeof requestedDiscriminator === 'undefined'
    ) {
      setShow(true)
    } else if (
      typeof discriminator === 'undefined' &&
      typeof requestedDiscriminator !== 'undefined'
    ) {
      setShow(false)
    } else if (discriminator === requestedDiscriminator) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [open, discriminator, requestedDiscriminator])

  return show
}

export default useDiscriminator
