import React from 'react'

import { Checkbox, TableRow, TableSortLabel } from '@mui/material'
import MuiTableHead from '@mui/material/TableHead'
import { visuallyHidden } from '@mui/utils'

import { DuelistTableCell } from 'src/components/MUI/StyledComponents'
import useMaintenanceItemTable, { TableVariant } from 'src/hooks/useMaintenanceItemTable'

import { SortOrder } from '.'
import { MaintenanceItem } from 'types/graphql'

interface TableHeadProps {
  variant: TableVariant
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: SortOrder
  orderBy: string
  rowCount: number
  disableTaskSelection?: boolean
  isColumnVisible: (columnId: string) => boolean
  selectedItemIds: string[]
  maintenanceItems: MaintenanceItem[]
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  sticky: boolean
  shadowDirection?: 'left' | 'right'
}

export const enableStickyColumns = true
export const manageColumnsEnabled = true

const stickyLeftHeadCells: HeadCell[] = [
  {
    id: 'tailNumber',
    disablePadding: true,
    label: 'TAIL #',
    sticky: enableStickyColumns,
    shadowDirection: 'right',
  },
]

const stickyRightHeadCells: HeadCell[] = [
  {
    id: 'actions',
    disablePadding: true,
    label: 'ACTIONS',
    sticky: enableStickyColumns,
    shadowDirection: 'left',
  },
]

const headCells: readonly HeadCell[] = [
  {
    id: 'ataCode',
    disablePadding: false,
    label: 'ATA / CODE',
    sticky: false,
  },
  {
    id: 'typeDescription',
    disablePadding: false,
    label: 'TYPE, DESCRIPTION',
    sticky: false,
  },
  {
    id: 'lastComplianceDate',
    disablePadding: false,
    label: 'Compliance',
    sticky: false,
  },
  { id: 'interval', disablePadding: false, label: 'Interval', sticky: false },
  {
    id: 'nextDueStatus',
    disablePadding: false,
    label: 'NEXT DUE',
    sticky: false,
  },
  {
    id: 'maxDue',
    disablePadding: false,
    label: 'MAX DUE',
    sticky: false,
  },
  {
    id: 'calculatedNextDueAt',
    disablePadding: false,
    label: 'REMAINING',
    sticky: false,
  },
  {
    id: 'partSerial',
    disablePadding: false,
    label: 'PART, SERIAL#',
    sticky: false,
  },
]

const TableHead: React.FC<TableHeadProps> = (props) => {
  const {
    variant,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    disableTaskSelection = false,
    isColumnVisible,
    // TEMPORARY: This should only exist while we create a better way of showing selected items
    selectedItemIds,
    maintenanceItems,
  } = props
  const { enabledColumns } = useMaintenanceItemTable(variant)
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  // TEMPORARY: This should only exist while we create a better way of showing selected items
  const numSelectedInView = selectedItemIds.filter((id) =>
    maintenanceItems.find((item) => item.id === id)
  ).length
  const numSelectedOutOfView = selectedItemIds.length - numSelectedInView

  return (
    <MuiTableHead>
      <TableRow className="table-row-bg">
        <DuelistTableCell
          padding="none"
          cellSize="small"
          className={
            enableStickyColumns
              ? `sticky left-0 w-[80px] ${!isColumnVisible('tailNumber')
                ? 'sticky-shadow-right'
                : 'z-[1]'
              }`
              : undefined
          }
        >
          {!disableTaskSelection && (
            <Checkbox
              color="primary"
              // TEMPORARY: This should only exist while we create a better way of showing selected items
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              // checked={rowCount > 0 && numSelected === rowCount}
              indeterminate={
                (numSelectedInView > 0 && numSelectedInView < rowCount) ||
                (numSelectedOutOfView > 0 && numSelectedInView !== rowCount)
              }
              checked={rowCount > 0 && numSelectedInView === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all maintenance tasks',
              }}
            />
          )}
        </DuelistTableCell>
        {stickyLeftHeadCells.map((headCell) => {
          return isColumnVisible(headCell.id) ? (
            <TableHeadCell
              createSortHandler={createSortHandler}
              key={headCell.id}
              headCell={headCell}
              order={order}
              orderBy={orderBy}
              className={
                headCell.sticky
                  ? `sticky-shadow-right sticky left-[80px]`
                  : undefined
              }
            />
          ) : null
        })}
        {enabledColumns.map((col) => {
          const colConfig = headCells.find((headCell) => headCell.id === col)
          if (!colConfig) return null
          return (
            <TableHeadCell
              createSortHandler={createSortHandler}
              key={colConfig.id}
              headCell={colConfig}
              order={order}
              orderBy={orderBy}
            />
          )
        })}
        {stickyRightHeadCells.map((headCell) => {
          return (
            <TableHeadCell
              createSortHandler={createSortHandler}
              key={headCell.id}
              headCell={headCell}
              order={order}
              orderBy={orderBy}
              className={
                headCell.sticky
                  ? `sticky-shadow-left sticky right-0 z-[1]`
                  : undefined
              }
            />
          )
        })}
      </TableRow>
    </MuiTableHead>
  )
}

const TableHeadCell: React.FC<{
  headCell: HeadCell
  order: SortOrder
  orderBy: string
  className?: string
  createSortHandler: (
    property: string
  ) => (event: React.MouseEvent<unknown>) => void
}> = ({ headCell, order, orderBy, className, createSortHandler }) => {
  return (
    <DuelistTableCell
      key={headCell.id}
      cellSize={headCell.id === 'typeDescription' ? 'large' : 'medium'}
      align={headCell.id === 'actions' ? 'right' : 'left'}
      sortDirection={orderBy === headCell.id ? order : false}
      className={className}
    >
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={createSortHandler(headCell.id)}
        disabled={['actions', 'tailNumber', 'interval', 'partSerial', 'maxDue'].includes(
          headCell.id
        )}
      >
        {headCell.label}
        {orderBy === headCell.id ? (
          <span style={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </DuelistTableCell>
  )
}

export default TableHead
