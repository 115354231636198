import * as React from 'react'

import ToggleButton from '@mui/material/ToggleButton'

import useMaintenanceItemTable, { TableVariant } from 'src/hooks/useMaintenanceItemTable'

import DAY_FILTER_OPTIONS from 'src/components/TimeFilter/options/dayFilterOptions'
import LANDINGS_FILTER_OPTIONS from 'src/components/TimeFilter/options/landingsFilterOptions'
import DropdownButtonMenu from 'src/components/TimeFilter/DropdownButtonMenu'
import CYCLES_FILTER_OPTIONS from 'src/components/TimeFilter/options/cyclesFilterOptions'
import HOURS_FILTER_OPTIONS from 'src/components/TimeFilter/options/hoursFilterOptions'
import { styled } from '@mui/material'

const StyledAllButton = styled(ToggleButton)(() => ({
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px',
}))

const TimeFilter = ({ tableVariant }: { tableVariant: TableVariant }) => {
  const {
    duelistFiltersData,
    setTimeframeAll,
    setTimeframeDays,
    setTimeframeHours,
    setTimeframeCycles,
    setTimeframeLandings,
  } = useMaintenanceItemTable(tableVariant)

  const { timeFrameAll } = duelistFiltersData

  return (
    <div>
      <StyledAllButton
        onClick={() => setTimeframeAll(!timeFrameAll)}
        selected={timeFrameAll}
        value="timeFrameAll"
        aria-label="left aligned"
        size="small"
      >
        All
      </StyledAllButton>
      <DropdownButtonMenu
        buttonPosition="center"
        options={DAY_FILTER_OPTIONS}
        onApply={(selctedData) => setTimeframeDays(selctedData)}
        buttonActive={!!duelistFiltersData.timeframeDays?.option}
        fieldName="timeframeDays"
        title="Days"
        defaultData={duelistFiltersData.timeframeDays}
      />
      <DropdownButtonMenu
        buttonPosition="center"
        options={HOURS_FILTER_OPTIONS}
        onApply={(selctedData) => setTimeframeHours(selctedData)}
        buttonActive={!!duelistFiltersData.timeframeHours?.option}
        fieldName="timeframeHours"
        title="Hours"
        defaultData={duelistFiltersData.timeframeHours}
      />
      <DropdownButtonMenu
        buttonPosition="center"
        options={CYCLES_FILTER_OPTIONS}
        onApply={(selctedData) => setTimeframeCycles(selctedData)}
        buttonActive={!!duelistFiltersData.timeframeCycles?.option}
        fieldName="timeframeCycles"
        title="Cycles"
        defaultData={duelistFiltersData.timeframeCycles}
      />
      <DropdownButtonMenu
        buttonPosition="right"
        options={LANDINGS_FILTER_OPTIONS}
        onApply={(selctedData) => setTimeframeLandings(selctedData)}
        buttonActive={!!duelistFiltersData.timeframeLandings?.option}
        fieldName="timeframeLandings"
        title="Landings"
        defaultData={duelistFiltersData.timeframeLandings}
      />
    </div>
  )
}

export default TimeFilter
