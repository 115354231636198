import React from 'react'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Chip, IconButton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { Aircraft, MaintenanceItem } from 'types/graphql'

import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import DueStatusChip from 'src/components/DueStatusChip/DueStatusChip'
import {
  CADENCE_TYPE_TO_INTERVAL_KEY,
  CADENCE_TYPE_TO_LABEL,
} from 'src/constants'
import { formatDateForDisplay } from 'src/utils/helpers'

interface SimpleDrawerContentProps {
  onClose: () => void
  parentTask: MaintenanceItem
  aircraft: Aircraft
}

const SimpleDrawerContent: React.FC<SimpleDrawerContentProps> = ({
  onClose,
  parentTask,
  aircraft,
}) => {
  const nextDueDate = get(parentTask, [
    'maintenanceNextDue',
    0,
    'nextDueValue',
    'date',
  ])
  const aircraftComponent = get(parentTask, ['aircraftComponent'], {})
  const cadenceType = get(parentTask, 'cadenceType', '')
  const intervalValue = get(parentTask, [
    'cadenceValue',
    CADENCE_TYPE_TO_INTERVAL_KEY[cadenceType],
  ])
  const importedDataCompliance = get(parentTask, ['importedDataCompliance'])
  const lastComplianceDate =
    get(parentTask, ['lastComplianceDate']) ||
    get(importedDataCompliance, ['logDate'])

  return (
    <div className="relative h-full">
      <div
        className="h-full overflow-auto bg-[#ffffff] pb-12"
        style={{ width: '450px' }}
      >
        <div className="p-2 pb-3">
          <div className="flex items-center justify-between">
            <IconButton onClick={onClose} className="mb-1">
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <div className="rounded border border-solid border-gray-300 p-2">
            <div className="flex items-center gap-1">
              <Typography variant="h6">{`${parentTask?.ataCode} ${parentTask?.manufactureCode}`}</Typography>
              {nextDueDate && (
                <Chip label="Scheduled" variant="outlined" size="small" />
              )}
            </div>
            <Typography variant="caption">
              {get(parentTask, 'title')}
            </Typography>
            <div className="mt-1 grid grid-cols-2">
              <div className="flex flex-col gap-2">
                <div>
                  <Typography variant="caption">Due Status</Typography>
                  <DueStatusChip
                    maintenanceItem={parentTask}
                    aircraft={aircraft}
                  />
                </div>
                <PrimSecTextCombo
                  primaryText={`${intervalValue} ${CADENCE_TYPE_TO_LABEL[cadenceType]}`}
                  secondaryText="Intervals"
                  variant="inverted"
                />
                <PrimSecTextCombo
                  primaryText="Todo: Labor Hours"
                  secondaryText="Labor Hours"
                  variant="inverted"
                />
                <PrimSecTextCombo
                  primaryText={get(
                    aircraftComponent,
                    'partNumber',
                    '---------'
                  )}
                  secondaryText="Part Number"
                  variant="inverted"
                />
                <PrimSecTextCombo
                  primaryText="Todo: Work Order"
                  secondaryText="Work Order"
                  variant="inverted"
                />
              </div>
              <div className="flex flex-col gap-2">
                <PrimSecTextCombo
                  primaryText={nextDueDate ? 'Scheduled' : 'N/A'}
                  secondaryText="Status"
                  variant="inverted"
                />
                <PrimSecTextCombo
                  primaryText={formatDateForDisplay(lastComplianceDate)}
                  secondaryText="Last C/W"
                  variant="inverted"
                />
                <PrimSecTextCombo
                  primaryText="Todo: Labor Cost"
                  secondaryText="Labor Cost"
                  variant="inverted"
                />
                <PrimSecTextCombo
                  primaryText={get(
                    aircraftComponent,
                    'serialNumber',
                    '-----------'
                  )}
                  secondaryText="Serial Number"
                  variant="inverted"
                />
                <PrimSecTextCombo
                  primaryText={
                    nextDueDate
                      ? dayjs(nextDueDate).format('MMM DD, YYYY')
                      : 'N/A'
                  }
                  secondaryText="Scheduled Date"
                  variant="inverted"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SimpleDrawerContent
