import { Button } from '@mui/material'

import { useFormContext, useWatch } from '@redwoodjs/forms'

type UploadFieldProps = {
  name: string
  accept?: string
  button?: React.ReactNode
  multiple?: boolean
  renderValue?: (value: any, clearValue: () => void) => React.ReactNode
}
const FileUploadFieldMulti: React.FC<UploadFieldProps> = ({
  name,
  accept,
  button,
  renderValue,
}) => {
  const multiple = true
  const { register, setValue } = useFormContext()
  const value = useWatch({ name })

  const handleButtonClick = () => {
    const input = document.getElementById(name) as HTMLInputElement
    input.click()
  }

  const handleClearFile = (index: number) => {
    setValue(
      name,
      value.filter((_, i) => i !== index)
    )
    // setValue(name, null)
  }
  const handleChange = (event) => {
    // Clear old value if not multiple
    if (!multiple) {
      setValue(name, Array.from(event.target.files))
    } else {
      setValue(name, [...(value ?? []), ...Array.from(event.target.files)])
    }
  }

  const ButtonToRender = button ? (
    React.cloneElement(button as React.ReactElement, {
      onClick: handleButtonClick,
    })
  ) : (
    <Button onClick={handleButtonClick}>Upload</Button>
  )

  return (
    <div>
      <input
        type="file"
        id={name}
        name={name}
        accept={accept}
        style={{ display: 'none' }}
        multiple={multiple}
        {...register(name)}
        onChange={handleChange}
      />
      {/* renders provided button or default button */}
      {/* unless value is populated AND renderValue is provided */}
      {ButtonToRender}

      {/* renders the output of renderValue if value is populated */}
      {value &&
        renderValue &&
        value.map((file, index) =>
          renderValue(file, () => handleClearFile(index))
        )}
    </div>
  )
}

export default FileUploadFieldMulti
