import { Autocomplete as MUIAutocomplete, TextField } from '@mui/material'

import { Controller } from '@redwoodjs/forms'
import { useFormContext } from '@redwoodjs/forms'

export interface AutocompleteOption {
  label: string
  value: string | number
}

interface AutocompleteProps
  extends Omit<React.ComponentProps<typeof MUIAutocomplete>, 'renderInput'> {
  name: string
  label: string
  helperText?: string
  size?: 'small' | 'medium'
  options: AutocompleteOption[]
  className?: string
  growHeight?: boolean
  required?: boolean
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  name,
  options,
  size = 'small',
  multiple = false,
  ...props
}) => {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext()
  const value = watch(name)

  const fieldError = errors[name]?.[0]
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <MUIAutocomplete
          multiple={multiple}
          options={options}
          getOptionLabel={(option: AutocompleteOption) =>
            option.label as string
          }
          size={size}
          onChange={(e, value: AutocompleteOption[]) => {
            onChange(value.map((v) => v.value))
          }}
          value={options.filter((option) => {
            return value?.includes(option.value)
          })}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              helperText={fieldError}
            />
          )}
          {...props}
        />
      )}
    />
  )
}

export default Autocomplete
