import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import relativeTime from 'dayjs/plugin/relativeTime'
import { get, isFinite } from 'lodash'

import {
  AircraftUsageLog,
  MaintenanceItem,
  MaintenanceNextDueStatus,
} from '../graphql'
import {
  UNIT_MAP,
  cadenceValueSchema,
  getRemainingValueFromNextDueValue,
} from '../jsonObjects'

import {
  getLastComplianceCycles,
  getLastComplianceDate,
  getLastComplianceHours,
  getLastComplianceLandings,
} from './utils'

const isValidField = (field) => {
  return field !== undefined && field !== null && field !== ''
}

dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
dayjs.extend(relativeTime)

export interface CurrentStatus {
  status?: MaintenanceNextDueStatus
  remainingValue?: number | string
  months?: number
  days?: number
}

export const getLastComplianceRelativeTime = (
  maintenanceItem: MaintenanceItem,
  usageLog: AircraftUsageLog
): string => {
  const mtxCadenceType = maintenanceItem.cadenceType
  const lastComplianceStamp = get(maintenanceItem, ['lastComplianceStamp'])
  const mtxItemTrackedByComponentId = maintenanceItem.trackedByComponent.id
  const componentUsageLog = lastComplianceStamp?.ComponentUsageLog.find(
    (log) => log.component.id === mtxItemTrackedByComponentId
  )
  const latestComponentUsageLog = usageLog.ComponentUsageLog.find(
    (log) => log.component.id === mtxItemTrackedByComponentId
  )
  switch (mtxCadenceType) {
    case 'FLYING_HOURS': {
      const lastComplianceHours = getLastComplianceHours(
        maintenanceItem,
        componentUsageLog
      )
      if (isFinite(lastComplianceHours)) {
        return `${(
          latestComponentUsageLog.totalTimeSinceNew - lastComplianceHours
        ).toFixed(1)} hours ago`
      }
      return ''
    }
    case 'CYCLES': {
      const lastComplianceCycles = getLastComplianceCycles(
        maintenanceItem,
        componentUsageLog
      )
      if (isFinite(lastComplianceCycles)) {
        return `${
          latestComponentUsageLog.cycleSinceNew - lastComplianceCycles
        } cycles ago`
      }
      return ''
    }
    case 'LANDINGS': {
      const lastComplianceLandings = getLastComplianceLandings(
        maintenanceItem,
        componentUsageLog
      )
      if (isFinite(lastComplianceLandings)) {
        return `${
          latestComponentUsageLog.cycleSinceNew - lastComplianceLandings
        } landings ago`
      }
      return ''
    }
    case 'CALENDAR_MONTHS': {
      // TODO: the data should be when compliance activity was started/ended
      const lastComplianceDate = getLastComplianceDate(maintenanceItem)
      if (isValidField(lastComplianceDate)) {
        return `${dayjs().diff(dayjs(lastComplianceDate), 'months')} months ago`
      } else {
        return ''
      }
    }
    case 'CALENDAR_DAYS': {
      // TODO: the data should be when compliance activity was started/ended
      const lastComplianceDate = getLastComplianceDate(maintenanceItem)
      if (isValidField(lastComplianceDate)) {
        return `${dayjs().diff(dayjs(lastComplianceDate), 'days')} days ago`
      } else {
        return ''
      }
    }
  }
}

export const getLastComplianceValues = (maintenanceItem: MaintenanceItem) => {
  const lastComplianceStamp = get(maintenanceItem, ['lastComplianceStamp'])
  const mtxItemTrackedByComponentId = maintenanceItem.trackedByComponent.id
  const componentUsageLog = lastComplianceStamp?.ComponentUsageLog.find(
    (log) => log.component.id === mtxItemTrackedByComponentId
  )
  const values = {
    flying_hours: getLastComplianceHours(maintenanceItem, componentUsageLog),
    cycles: getLastComplianceCycles(maintenanceItem, componentUsageLog),
    landings: getLastComplianceLandings(maintenanceItem, componentUsageLog),
    date: getLastComplianceDate(maintenanceItem),
  }
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  const asString = fields
    .map((field) => {
      if (isFinite(values[field])) {
        return `${values[field]} ${UNIT_MAP[field]}`
      } else return null
    })
    .filter((value) => value !== null)
  return { ...values, asString }
}
export const getLastComplianceTime = (
  maintenanceItem: MaintenanceItem
): string => {
  const mtxCadenceType = maintenanceItem.cadenceType
  const lastComplianceStamp = get(maintenanceItem, ['lastComplianceStamp'])
  const mtxItemTrackedByComponentId = maintenanceItem.trackedByComponent.id
  const componentUsageLog = lastComplianceStamp?.ComponentUsageLog.find(
    (log) => log.component.id === mtxItemTrackedByComponentId
  )

  switch (mtxCadenceType) {
    case 'FLYING_HOURS': {
      const lastComplianceHours = getLastComplianceHours(
        maintenanceItem,
        componentUsageLog
      )
      if (isFinite(lastComplianceHours)) {
        return `${lastComplianceHours.toFixed(1)} hours`
      }
      return ''
    }
    case 'CYCLES': {
      const lastComplianceCycles = getLastComplianceCycles(
        maintenanceItem,
        componentUsageLog
      )
      if (isFinite(lastComplianceCycles)) {
        return `${lastComplianceCycles} cycles`
      }
      return ''
    }
    case 'LANDINGS': {
      const lastComplianceLandings = getLastComplianceLandings(
        maintenanceItem,
        componentUsageLog
      )
      if (isFinite(lastComplianceLandings)) {
        return `${lastComplianceLandings} landings`
      }
      return ''
    }
    case 'CALENDAR_MONTHS':
    case 'CALENDAR_DAYS': {
      // TODO: the data should be when compliance activity was started/ended
      return getLastComplianceDate(maintenanceItem)?.toDateString() ?? ''
    }
  }
}

export const getRemainingValue = (
  maintenanceItem: MaintenanceItem,
  latestAircraftUsageLog: AircraftUsageLog
): CurrentStatus => {
  if (maintenanceItem.nextDueStatus === 'NOT_DUE') {
    if (maintenanceItem.maintenanceNextDue.length === 0) {
      return {
        status: 'NOT_DUE',
      }
    }
  }
  const trackedByComponentId =
    maintenanceItem.trackedByComponent.id ??
    maintenanceItem.trackedByComponentId ??
    ''
  const componentUsageLog = latestAircraftUsageLog?.ComponentUsageLog.find(
    (log) => log.component.id === trackedByComponentId
  )
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)

  const remValue = getRemainingValueFromNextDueValue(
    maintenanceItem.maintenanceNextDue[0],
    cadenceValue,
    componentUsageLog
  )
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  // TODO: This is temporary solution until we migration away from
  // CurrentStatus in useDueStatus.ts
  let result = {} as CurrentStatus
  if (remValue.status === 'OVERDUE') {
    result = {
      status: remValue.status as MaintenanceNextDueStatus,
      remainingValue: -1,
      months: 0,
      days: 0,
    }
  } else if (remValue.status === 'IN_TOLERANCE') {
    result = {
      status: remValue.status as MaintenanceNextDueStatus,
      remainingValue: Math.min(
        ...fields.map(
          (field) => (remValue[field]?.toleranceValue || 1000000) as number
        )
      ),
    }
  } else {
    result = {
      status: remValue.status as MaintenanceNextDueStatus,
      remainingValue: Math.min(
        ...fields.map((field) => (remValue[field]?.value || 1000000) as number)
      ),
    }
  }
  if (remValue.date !== undefined) {
    result = {
      ...result,
      months: remValue.date.remMonths,
      days: remValue.date.remDays,
    }
  }
  return result
}
