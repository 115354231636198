import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Sentry from 'src/lib/sentry'

const CREATE_PARTS_TRANSACTION_MUTATION = gql`
  mutation CreatePartsTransaction($input: CreatePartsTransactionInput!) {
    createPartsTransaction(input: $input) {
      id
      removalReason
      installedStatus
      complianceActivity {
        id
        partsTransaction {
          id
          removalReason
          added {
            id
            aircraftId
            isPrimary
            partNumber
            serialNumber
            monthsSinceNew
            monthsSinceOverhaul
            monthsSinceRepair
            hoursSinceNew
            hoursSinceOverhaul
            hoursSinceRepair
            cyclesSinceNew
            cyclesSinceOverhaul
            cyclesSinceRepair
          }
          removed {
            id
            aircraftId
            isPrimary
            partNumber
            serialNumber
            monthsSinceNew
            monthsSinceOverhaul
            monthsSinceRepair
            hoursSinceNew
            hoursSinceOverhaul
            hoursSinceRepair
            cyclesSinceNew
            cyclesSinceOverhaul
            cyclesSinceRepair
          }
        }
      }
    }
  }
`

function useCreatePartsTransaction(options = {}) {
  return useMutation(CREATE_PARTS_TRANSACTION_MUTATION, {
    onError: (error) => {
      toast.error('Something went wrong.')
      Sentry.captureException(error)
    },
    ...options,
  })
}

export default useCreatePartsTransaction
