import * as React from 'react'

import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete, Switch, Typography } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { styled, alpha } from '@mui/material/styles'
import { useDebounce } from 'usehooks-ts'

type IconPosition = 'left' | 'right'

interface StyledInputBaseProps {
  iconPosition: IconPosition
}

interface SearchIconWrapperProps {
  iconPosition: IconPosition
}

const SearchIconWrapper = styled('div')<SearchIconWrapperProps>(
  ({ theme, iconPosition }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: iconPosition === 'right' ? 0 : undefined,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  })
)

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #E4E4E4',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}))

const StyledInputBase = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'iconPosition',
})<StyledInputBaseProps>(({ theme, iconPosition }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(
      iconPosition === 'left' ? 4 : 0
    )})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

interface SearchbarProps {
  placeholder: string
  iconPosition?: IconPosition
  showPowerSearchToggle?: boolean
  value: string | any
  className?: string
  powerSearch?: boolean
  powerSearchOptions?: any
  onChange: (any) => void
  setHasUserChangedLocalSearchValue?: (any) => void
}

const Searchbar: React.FC<SearchbarProps> = ({
  placeholder,
  iconPosition = 'left',
  powerSearch = false,
  powerSearchOptions,
  showPowerSearchToggle = true,
  value,
  className,
  onChange,
  setHasUserChangedLocalSearchValue,
}) => {
  const powerSearchSwitchEnabled =
    (powerSearch && localStorage.getItem('powerSearchSwitchEnabled')) ===
      'true' || false
  const [powerSearchAutoCompleteOptions, setPowerSearchAutoCompleteOptions] =
    React.useState([])
  const [powerSearchInputVal, setPowerSearchInputVal] = React.useState('')
  const debouncedPowerSearchInputVal = useDebounce(powerSearchInputVal, 500)

  React.useEffect(() => {
    if (powerSearch && !powerSearchSwitchEnabled)
      // When power search is enabled by parent and power search is disabled by the local switch,
      // we want to pass the debounced value to the parent component
      onChange(debouncedPowerSearchInputVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPowerSearchInputVal])

  React.useEffect(() => {
    if (powerSearch) {
      powerSearchSwitchEnabled ? onChange({}) : onChange('')
      setPowerSearchInputVal('')
    }
  }, [powerSearchSwitchEnabled])

  const powerSearchKeys = powerSearchOptions
    ? Object.keys(powerSearchOptions)
    : []
  const powerSearchLabels = powerSearchOptions
    ? Object.values(powerSearchOptions)
    : []

  React.useEffect(() => {
    if (
      !powerSearch ||
      !powerSearchSwitchEnabled ||
      !powerSearchOptions ||
      !powerSearchLabels.length
    )
      return
    let optionLabels = []
    if (!powerSearchInputVal) {
      optionLabels.push({
        label: 'Start typing to search...',
        value: 'none',
      })
      setPowerSearchAutoCompleteOptions(optionLabels)
      return
    }
    optionLabels = powerSearchLabels.map((label) => {
      return {
        label: `Search '${powerSearchInputVal}' in ${label}`,
        value: powerSearchKeys[powerSearchLabels.indexOf(label)],
      }
    })
    setPowerSearchAutoCompleteOptions(optionLabels)
  }, [powerSearchOptions, powerSearchInputVal, powerSearchSwitchEnabled])

  return (
    <div className={`flex-1 ${className ? className : ''}`}>
      <Search>
        <SearchIconWrapper iconPosition={iconPosition}>
          <div className="flex items-center gap-1">
            <SearchIcon />
            {powerSearch && showPowerSearchToggle && (
              <>
                <Typography variant="caption" className="select-none">
                  Power Search
                </Typography>
                <Switch
                  size="small"
                  checked={powerSearchSwitchEnabled}
                  onChange={() => {
                    localStorage.setItem(
                      'powerSearchSwitchEnabled',
                      !powerSearchSwitchEnabled ? 'true' : 'false'
                    )
                    setPowerSearchAutoCompleteOptions([])
                  }}
                />
              </>
            )}
          </div>
        </SearchIconWrapper>
        {powerSearchSwitchEnabled ? (
          <Autocomplete
            freeSolo
            size="small"
            id="free-solo-2-demo"
            disableClearable
            getOptionDisabled={(_) =>
              powerSearchAutoCompleteOptions.length === 1
            }
            options={powerSearchAutoCompleteOptions}
            onChange={(_: any, newValue: any) => {
              if (typeof newValue === 'string') {
                onChange(newValue)
              } else {
                onChange({
                  ...(typeof value === 'object' ? value : {}),
                  [newValue.value]: powerSearchInputVal,
                })
              }
              setHasUserChangedLocalSearchValue(true)
              setPowerSearchInputVal('')
            }}
            inputValue={powerSearchInputVal}
            onInputChange={(event, newInputValue) => {
              event && setPowerSearchInputVal(newInputValue)
            }}
            renderInput={(params) => {
              const { InputLabelProps: _, ...rest } = params

              return (
                <StyledInputBase
                  {...params.InputProps}
                  {...rest}
                  placeholder={placeholder}
                  iconPosition={iconPosition}
                  fullWidth
                />
              )
            }}
          />
        ) : (
          <StyledInputBase
            placeholder={placeholder}
            iconPosition={iconPosition}
            inputProps={{ 'aria-label': 'search', style: { width: '100%' } }}
            onChange={(event) => {
              if (powerSearch && !powerSearchSwitchEnabled) {
                // when power search is enabled by parent and power search is disabled by the local switch,
                // we want to set the value in the local state and then pass debounced value to the parent component
                setPowerSearchInputVal(event.target.value)
              } else {
                // when power search is disabled from parent component, we want to pass the event to the parent component
                onChange(event)
              }
              setHasUserChangedLocalSearchValue(true)
            }}
            value={
              powerSearch && !powerSearchSwitchEnabled
                ? powerSearchInputVal
                : value
            }
            fullWidth
          />
        )}
      </Search>
    </div>
  )
}

export default Searchbar
