//import { MaintenanceCadenceType } from 'types/graphql'
import { object, string, number, date, InferType } from 'yup'

export const importedDataComplianceSchema = object({
  equipmentName: string()
    .optional()
    .test(
      'checkIfPrimaryComponent',
      'Equipment name should be one of primary component',
      //TODO: Implement this validation
      (_value) => true
    ),
  logDate: date().optional(),
  logHours: number()
    .min(0)
    .test(
      'checkNonNullableForCadenceType',
      'Log hours should not be null',
      function (value) {
        const { path, createError } = this
        const cadenceType = this.options.context?.cadenceType
        if (cadenceType === 'FLYING_HOURS') {
          if (value === null || value === undefined) {
            return createError({
              path,
              message: `logHours should not be null when cadence type is ${cadenceType}`,
            })
          }
        }
        return true
      }
    ),
  logLandings: number()
    .min(0)
    .integer()
    .test(
      'checkNonNullableForCadenceType',
      'Log hours should not be null',
      function (value) {
        const { path, createError } = this
        const cadenceType = this.options.context?.cadenceType
        if (cadenceType === 'LANDINGS') {
          if (value === null || value === undefined) {
            return createError({
              path,
              message: `logHours should not be null when cadence type is ${cadenceType}`,
            })
          }
        }
        return true
      }
    ),
  logCycles: number()
    .min(0)
    .integer()
    .test(
      'checkNonNullableForCadenceType',
      'Log hours should not be null',
      function (value) {
        const { path, createError } = this
        const cadenceType = this.options.context?.cadenceType
        if (cadenceType === 'CYCLES') {
          if (value === null || value === undefined) {
            return createError({
              path,
              message: `logHours should not be null when cadence type is ${cadenceType}`,
            })
          }
        }
        return true
      }
    ),
})

export const getLastComplianceAsFlatDict = (
  lastCompliance: ImportedDataCompliance
) => {
  const fieldMap = {
    logDate: 'lastComplianceDate',
    logHours: 'lastComplianceHours',
    logLandings: 'lastComplianceLandings',
    logCycles: 'lastComplianceCycles',
  }
  return Object.keys(fieldMap).reduce((acc, key) => {
    if (fieldMap[key] === undefined) return acc
    acc[fieldMap[key]] = lastCompliance[key]
    return acc
  }, {} as { [key: string]: any })
}

export type ImportedDataCompliance = InferType<
  typeof importedDataComplianceSchema
>
