import { useCallback, useState } from 'react'

const useCheckboxSelection = (data) => {
  const [selected, setSelected] = useState<string[]>([])

  const handleSelect = useCallback(
    (id: string) => {
      const isSelect = !selected.includes(id)
      if (isSelect) {
        setSelected((prevSelected) => [...prevSelected, id])
      } else {
        setSelected((prevSelected) =>
          prevSelected.filter((itemId) => itemId !== id)
        )
      }
    },
    [selected]
  )

  const handleSelectAll = useCallback(() => {
    if (selected.length === data.length) {
      setSelected([])
    } else {
      setSelected(data.map((item) => item.id))
    }
  }, [data, selected.length])

  const handleUnselectAll = useCallback(() => {
    setSelected([])
  }, [])

  return { selected, handleSelect, handleSelectAll, handleUnselectAll }
}

export default useCheckboxSelection
