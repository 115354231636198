import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { TableCell, TableRow } from '@mui/material'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { AircraftUsageLog } from 'types/graphql'

import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import { useDispatch } from 'src/hooks/useDispatch'
import useQuery from 'src/hooks/useQuery'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, setModalData } from 'src/slices/modal'

import Loading from '../Loading'
import Button from '../MUI/Button'
import Table, { DataProp } from '../Table'

const GET_USAGE_LOGS = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query SelectAircraftUsageLog($aircraftId: String!) {
    aircraftUsageLogs(aircraftId: $aircraftId) {
      ...CoreAircraftUsageLogFields
    }
  }
`

type RowData = DataProp & AircraftUsageLog

type SelectTimeProps = {
  show: boolean
  onSuccess?: (aircraftUsageLogId: string) => void
  trackedByComponentName?: string
}

// const Row = ({ data: { name, age, location } }: { data: RowData }) => {
const Row: React.FC<{
  data: RowData
  onSelect: (sting) => string
  trackedByComponentName: string
}> = ({ data, onSelect, trackedByComponentName }) => {
  const componentLog = data.ComponentUsageLog.find(
    (log) => log.component.name === (trackedByComponentName ?? 'Airframe')
  )
  return (
    <TableRow>
      <TableCell>{dayjs(data.usageAsOf).format('MMM DD, YYYY')}</TableCell>
      <TableCell>{componentLog.totalTimeSinceNew}</TableCell>
      <TableCell>{componentLog.cycleSinceNew}</TableCell>
      <TableCell className="flex gap-1">
        <Button
          disabledTooltip="Upcoming feature: Edit this time"
          tooltip="Edit this time"
          disabled
          variant="contained"
          startIcon={<EditRoundedIcon />}
        >
          Edit
        </Button>

        <Button
          tooltip="Select this time"
          variant="contained"
          onClick={() => onSelect(data.id)}
          startIcon={<CheckRoundedIcon />}
        >
          Apply this time
        </Button>
      </TableCell>
    </TableRow>
  )
}

const SelectTime: React.FC<SelectTimeProps> = ({
  onSuccess,
  show,
  trackedByComponentName,
}) => {
  const dispatch = useDispatch()
  const { aircraftId, loading = false } = useSelector(
    (state) => state.modal.modals?.aircraftTimeModal?.data
  )
  const { data, loading: aircraftUsageLogsLoading } = useQuery(GET_USAGE_LOGS, {
    variables: {
      aircraftId,
    },
    onCompleted: () => {
      dispatch(
        setModalData({
          name: 'aircraftTimeModal',
          data: { loading: false },
        })
      )
    },
  })

  const handleSelect = (aircraftUsageLogId: string) => {
    dispatch(closeModal({ name: 'aircraftTimeModal' }))
    onSuccess(aircraftUsageLogId)
  }

  const componentName = trackedByComponentName ?? 'Airframe'

  const cycleLabel = componentName === 'Airframe' ? 'Landings' : 'Cycles'

  return (
    <>
      {show && (
        <div
          className={clsx({
            'flex h-52 items-center justify-center': true,
            hidden: !loading && !aircraftUsageLogsLoading,
          })}
        >
          <Loading />
        </div>
      )}
      <div
        className={clsx({
          'mb-2 mt-2': true,
          hidden: !show || loading || aircraftUsageLogsLoading,
        })}
      >
        <Table
          title="Historical Usage Log"
          classes={{ tableContainer: 'max-h-70' }}
          data={data?.aircraftUsageLogs ?? []}
          container="fragment"
          slots={{
            Row,
          }}
          RowProps={{ onSelect: handleSelect, trackedByComponentName }}
          columnConfig={[
            { id: 'date', label: 'Date' },
            { id: 'hours', label: `${componentName} Hours` },
            { id: 'cycles', label: `${componentName} ${cycleLabel}` },
            { id: 'actions', label: 'Actions' },
          ]}
        />
      </div>
    </>
  )
}

export default SelectTime
