import { OperationVariables } from '@apollo/client/core'
import {
  ComplianceActivity,
  UpdateComplianceActivityInput,
} from 'types/graphql'

import useMutationUpdate from './useMutationUpdate'

const UPDATE_COMPLIANCE_ACTIVITY_MUTATION = gql`
  mutation UpdateComplianceActivity(
    $id: String!
    $input: UpdateComplianceActivityInput!
  ) {
    updateComplianceActivity(id: $id, input: $input) {
      id
      complianceLedgerId
      maintenanceItemId
      description
      notes
      workHours
      workedById
      correctiveAction
      partsRemovedId
      partsInstalledId
      nextDueId
      workedById
      signedById
      status
      childActivities {
        id
        status
      }
    }
  }
`

const useUpdateComplianceActivity = (options?: OperationVariables) => {
  return useMutationUpdate<ComplianceActivity, UpdateComplianceActivityInput>(
    UPDATE_COMPLIANCE_ACTIVITY_MUTATION,
    options
  )
}

export default useUpdateComplianceActivity
