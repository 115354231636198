import {
  Checkbox as MUICheckbox,
  FormControlLabel,
  Tooltip,
  TooltipProps,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material'

import { Controller, useFormContext } from '@redwoodjs/forms'

interface CheckboxProps extends MuiCheckboxProps {
  className?: string
  name: string
  label: string
  required?: boolean
  checkboxProps?: React.ComponentProps<typeof MUICheckbox>
  tooltip?: string
  disabledTooltip?: string
  tooltipProps?: TooltipProps
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  tooltip,
  disabledTooltip,
  required = false,
  checkboxProps,
  tooltipProps,
  ...otherProps
}) => {
  // We use the Redwood form context to get form methods
  const { register, watch, control } = useFormContext()

  let tooltipText: string
  if (checkboxProps?.disabled) {
    tooltipText = disabledTooltip
  } else {
    tooltipText = tooltip
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value: isChecked, ...rest } }) => {
        return (
          // <FormControl error={Boolean(fieldError)}>
          //   <MuiRadioGroup
          //     {...props}
          //     id={name}
          //     name={name}
          //     className={className}
          //     value={value}
          //     {...rest}
          //   >
          //     {options.map((option, idx) => (
          //       <FormControlLabel
          //         key={idx}
          //         value={option.value}
          //         control={<Radio />}
          //         label={option.label}
          //         disabled={option?.disabled || disabled}
          //       />
          //     ))}
          //   </MuiRadioGroup>
          //   <FormHelperText>{fieldError || helperText}</FormHelperText>
          // </FormControl>
          <Tooltip title={tooltipText} placement="top" {...tooltipProps}>
            <span>
              <FormControlLabel
                control={
                  <MUICheckbox
                    {...register(name, { required })}
                    {...rest}
                    {...checkboxProps}
                    checked={isChecked ?? false}
                  />
                }
                label={label}
                {...otherProps}
              />
            </span>
          </Tooltip>
        )
      }}
    />
  )
}

export default Checkbox
