import { DuelistFilterTimeframeLandings } from 'src/types'

interface Option extends DuelistFilterTimeframeLandings {
  label: string
}

const LANDINGS_FILTER_OPTIONS: Option[] = [
  {
    option: '25L',
    landings: 25,
    label: '25 Landings',
  },
  {
    option: '50L',
    landings: 50,
    label: '50 Landings',
  },
  {
    option: '75L',
    landings: 75,
    label: '75 Landings',
  },
  {
    option: '100L',
    landings: 100,
    label: '100 Landings',
  },
  {
    option: 'ALL',
    landings: -1,
    label: 'Clear',
  },
]

export default LANDINGS_FILTER_OPTIONS
