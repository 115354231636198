import { OperationVariables } from '@apollo/client/core'
import { PartsTransaction, UpdatePartsTransactionInput } from 'types/graphql'

import useMutationUpdate from './useMutationUpdate'

const UPDATE_PARTS_TRANSACTION_MUTATION = gql`
  mutation updatePartsTransaction(
    $id: String!
    $input: UpdatePartsTransactionInput!
  ) {
    updatePartsTransaction(id: $id, input: $input) {
      id
      removalReason
      complianceActivity {
        id
        partsTransaction {
          id
          removalReason
          installedStatus
          added {
            id
            aircraftId
            isPrimary
            partNumber
            serialNumber
            monthsSinceNew
            monthsSinceOverhaul
            monthsSinceRepair
            hoursSinceNew
            hoursSinceOverhaul
            hoursSinceRepair
            cyclesSinceNew
            cyclesSinceOverhaul
            cyclesSinceRepair
          }
          removed {
            id
            aircraftId
            isPrimary
            partNumber
            serialNumber
            monthsSinceNew
            monthsSinceOverhaul
            monthsSinceRepair
            hoursSinceNew
            hoursSinceOverhaul
            hoursSinceRepair
            cyclesSinceNew
            cyclesSinceOverhaul
            cyclesSinceRepair
          }
        }
      }
    }
  }
`

const useUpdatePartsTransaction = (options?: OperationVariables) => {
  return useMutationUpdate<PartsTransaction, UpdatePartsTransactionInput>(
    UPDATE_PARTS_TRANSACTION_MUTATION,
    options
  )
}

export default useUpdatePartsTransaction
