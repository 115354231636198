import { Checkbox, CheckboxProps } from '@mui/material'

const CheckboxHeader: React.FC<{
  handleSelectAll: () => void
  selectedCount: number
  rowCount: number
  CheckboxProps?: CheckboxProps
}> = ({ handleSelectAll, selectedCount, rowCount, CheckboxProps = {} }) => (
  <Checkbox
    color="primary"
    indeterminate={selectedCount > 0 && selectedCount < rowCount}
    checked={selectedCount > 0 && selectedCount === rowCount}
    onChange={handleSelectAll}
    {...CheckboxProps}
  />
)

export default CheckboxHeader
