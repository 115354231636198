import React, { createContext, useEffect, useState, ReactNode } from 'react'
import { createMongoAbility, MongoAbility } from '@casl/ability'
import { useAuth, useUser } from '@clerk/clerk-react'
import { Permit, permitState } from 'permit-fe-sdk'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import Loading from 'src/components/Loading'
import useHasFeature from 'src/hooks/useHasFeature'
import { useDispatch } from 'src/hooks/useDispatch'
import { setOrgHasPermissions } from 'src/slices/customRequestHeaders'

// Create Context
export const AbilityContext = createContext<MongoAbility | undefined>(undefined)

interface AbilityLoaderProps {
  children: ReactNode
  orgSlug: string
}

// Move this to a separate utility function
const getAllPermissionsToCheck = (aircraftIds: string[]) => {
  const basePermissions = [
    ...Object.values(Permissions.compliance),
    ...Object.values(Permissions.maintenanceItem),
    ...Object.values(Permissions.user),
    ...Object.values(Permissions.workOrder),
    ...Object.values(Permissions.purchaseOrder),
  ]

  const aircraftSpecificPermissions = aircraftIds.flatMap((aircraftId) =>
    [
      ...Object.values(Permissions.compliance),
      ...Object.values(Permissions.maintenanceItem),
      ...Object.values(Permissions.workOrder),
    ].map((permission) => ({
      action: permission.action,
      resource: `${permission.resource}:${aircraftId}`,
    }))
  )

  return [...basePermissions, ...aircraftSpecificPermissions]
}

export const AbilityLoader: React.FC<AbilityLoaderProps> = ({
  children,
  orgSlug,
}) => {
  const dispatch = useDispatch()
  const { hasFeature: orgHasPermissions, loading: orgHasPermissionsLoading } =
    useHasFeature('Permissions', orgSlug)
  const isRbacEnabled = process.env.RBAC_ENABLED === 'true'
  const { isSignedIn, user } = useUser()
  const userId = user?.id
  const { getToken } = useAuth()
  const [ability, setAbility] = useState<MongoAbility | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (orgHasPermissionsLoading) return
    // set orgHasPermissions in redux store so that BE auth checks can use it
    dispatch(setOrgHasPermissions(orgHasPermissions))
    if (!isRbacEnabled || !orgHasPermissions) {
      setIsLoading(false)
      return
    }
    const getAbility = async () => {
      if (!isSignedIn || !userId) {
        setIsLoading(false)
        return
      }

      setIsLoading(true)

      try {
        const token = await getToken()
        const permit = Permit({
          loggedInUser: userId,
          backendUrl: '/.netlify/functions/checkPermissions',
          customRequestHeaders: {
            orgSlug,
            Authorization: `Bearer ${token}`,
            'auth-provider': 'clerk',
          },
        })
        permit.reset()

        const response = await fetch(
          '/.netlify/functions/getAccessibleAircraftIds',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'auth-provider': 'clerk',
            },
          }
        )
        const { data: aircraftIds } = await response.json()

        const permissions = getAllPermissionsToCheck(aircraftIds)
        await permit.loadLocalStateBulk(permissions)

        const caslConfig = permitState?.getCaslJson()

        const newAbility =
          caslConfig && caslConfig.length
            ? createMongoAbility(caslConfig)
            : undefined

        setAbility(newAbility)
      } catch (error) {
        console.error('Error loading permissions:', error)
      } finally {
        setIsLoading(false)
      }
    }

    getAbility()
  }, [isSignedIn, userId, orgSlug, orgHasPermissions, orgHasPermissionsLoading])

  if (isLoading || orgHasPermissionsLoading) {
    return <Loading />
  }

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}
