import * as React from 'react'

import { FormControl, FormHelperText, FormLabel } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import MuiRadioGroup from '@mui/material/RadioGroup'
import { get } from 'lodash'

import { useFormContext } from '@redwoodjs/forms'
import { Controller } from '@redwoodjs/forms'

export interface RadioGroupOption {
  label: string
  value: string | number
  disabled?: boolean
}

interface RadioGroupProps extends React.ComponentProps<typeof MuiRadioGroup> {
  name: string
  label?: string
  options: RadioGroupOption[]
  helperText?: string
  className?: string
  disabled?: boolean
  required?: boolean
}

// This is a RadioGroup component that uses MUI's RadioGroup component under the hood
// and adds a label and provides a way to pass in an array of options.
const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  label,
  options,
  helperText,
  className,
  disabled,
  required,
  ...props
}) => {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext()
  const value = watch(name)

  const fieldError = get(errors, name)?.message as string | undefined

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value: _, ...rest } }) => {
        return (
          <FormControl error={Boolean(fieldError)}>
            <FormLabel id={`${name}-radio-button-group-label`}>
              {label}
            </FormLabel>
            <MuiRadioGroup
              {...props}
              id={name}
              name={name}
              className={className}
              value={value}
              aria-labelledby={`${name}-radio-button-group-label`}
              {...rest}
            >
              {options.map((option, idx) => (
                <FormControlLabel
                  key={idx}
                  value={option.value}
                  required={required}
                  control={<Radio />}
                  label={option.label}
                  disabled={option?.disabled || disabled}
                />
              ))}
            </MuiRadioGroup>
            <FormHelperText>{fieldError || helperText}</FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}

export default RadioGroup
