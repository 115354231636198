interface AuditLogPayload {
  context: {
    headers: {
      'audit-log-notes': string
    }
  }
}

const _sanitizeHeaderValue = (value: string) => {
  // Ensure the value is a string
  if (typeof value !== 'string') {
    throw new TypeError('Header value must be a string')
  }

  // Remove leading and trailing whitespace
  value = value.trim()

  // Define the disallowed characters: any character not in the visible ASCII range, except for space
  const notSupportedChars = /[^\x20-\x7E]/g

  // Replace any disallowed characters with an empty string
  return value.replace(notSupportedChars, ' ')
}

const generateAuditLogPayload = (note: string): AuditLogPayload => {
  return {
    context: {
      headers: {
        'audit-log-notes': _sanitizeHeaderValue(note),
      },
    },
  }
}

export default generateAuditLogPayload
