const CORE_COMPONENT_USAGE_LOG_FRAGMENT = gql`
  fragment CoreComponentUsageLogFields on ComponentUsageLog {
    id
    componentId
    usageAsOf
    totalTimeSinceNew
    timeSinceOverhaul
    cycleSinceNew
    cycleSinceOverhaul
    component {
      id
      name
      isPrimary
      isIntegral
    }
  }
`

export default CORE_COMPONENT_USAGE_LOG_FRAGMENT
