import React from 'react'

import { ComplianceActivity } from 'types/graphql'

import DiscrepanciesTable from './Table'

interface DiscrepanciesTabProps {
  complianceActivity: ComplianceActivity
  aircraftUsageLogId: string
  refreshLedger: () => void
}

const Discrepancies: React.FC<DiscrepanciesTabProps> = ({
  complianceActivity,
  aircraftUsageLogId,
  refreshLedger,
}) => {
  return (
    <div>
      <DiscrepanciesTable
        complianceActivity={complianceActivity}
        aircraftUsageLogId={aircraftUsageLogId}
        refreshLedger={refreshLedger}
      />
    </div>
  )
}

export default Discrepancies
