import { MutationHookOptions } from '@apollo/client'
import { ComplianceActivity } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

const CREATE_COMPLIANCE_ACTIVITY_MUTATION = gql`
  mutation CreateComplianceActivities(
    $maintenanceItemIds: [String!]!
    $id: String!
  ) {
    addMaintenanceItemsToComplianceLedger(
      id: $id
      input: { maintenanceItemIds: $maintenanceItemIds }
    ) {
      id
      complianceActivity(filters: { omitChildren: false }) {
        id
        status
        maintenanceItem {
          id
          title
          childItems {
            id
            title
          }
        }
      }
    }
  }
`

interface MutationResult {
  data?: { addMaintenanceItemsToComplianceLedger: ComplianceActivity }
  loading: boolean
  error?: Error
}

function useAddMaintenanceItemsToLedger(): [
  (
    ledgerId: string,
    maintenanceItems: string[],
    options?: MutationHookOptions
  ) => Promise<void>,
  MutationResult
] {
  const [createComplianceActivity, results] = useMutation(
    CREATE_COMPLIANCE_ACTIVITY_MUTATION
  )

  const mutate = async (
    ledgerId: string,
    maintenanceItems: string[],
    options?: MutationHookOptions
  ) => {
    const result = await createComplianceActivity({
      variables: { maintenanceItemIds: maintenanceItems, id: ledgerId },
      ...options,
    })

    return result
  }

  return [mutate, results]
}

export default useAddMaintenanceItemsToLedger
