import React from 'react'
import { ListItem, Typography, Avatar } from '@mui/material'
import { AiChatAction, Message } from './types'
import { AiSparklesIcon } from 'src/components/AiSparklesIcon'

interface ChatActionProps {
  action: AiChatAction
  onClick?: (action: AiChatAction) => void
  isActive?: boolean
  disabled?: boolean
  comingSoon?: boolean
}

const ChatAction: React.FC<ChatActionProps> = ({ action, onClick, isActive, disabled, comingSoon }) => {
  return (
    <div
      role="button"
      onClick={() => {
        if (!disabled && !isActive) {
          onClick(action)
        }
      }}
      className={`p-2 w-full rounded-xl text-gray-800 justify-start shadow-sm
        bg-gradient-to-br from-white to-blue-50 border border-solid border-blue-100
        ${isActive ? '' : 'h-[120px]'}
        ${disabled || isActive ? 'opacity-80 cursor-not-allowed' : 'cursor-pointer hover:shadow-md transition-shadow'}`}
    >
      <div className="flex flex-col gap-1">
        <div className="flex gap-1 items-center">
          <Typography variant="body1" fontWeight={500}>
            {action.name}
          </Typography>

        </div>
        <Typography variant="body2">
          {action.description}
        </Typography>
        {comingSoon && <Typography variant="caption">Coming Soon</Typography>}
      </div>
    </div>
  )
}

export default ChatAction
