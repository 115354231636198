import {
  Select as MUISelect,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  CircularProgress,
  SelectProps as MUISelectProps,
} from '@mui/material'
import { get } from 'lodash'

import { Controller } from '@redwoodjs/forms'
import { useFormContext } from '@redwoodjs/forms'
import LockPersonIcon from '@mui/icons-material/LockPerson'

interface ObjectSelector {
  id: string
  name: string
}
export interface SelectOption {
  label: string
  value: string | number | ObjectSelector
  locked?: boolean
}

interface SelectFieldProps extends MUISelectProps {
  name: string
  label: string
  helperText?: string
  size?: 'small' | 'medium'
  options: SelectOption[]
  className?: string
  growHeight?: boolean
  loading?: boolean
  onChange?: (event: any) => void
  required?: boolean
  disabled?: boolean
}

const SelectDropdown: React.FC<SelectFieldProps> = ({
  name,
  helperText,
  options,
  size = 'small',
  growHeight = false,
  className = '',
  loading = false,
  required = false,
  disabled = false,
  onChange,
  ...props
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext()

  const fieldError = get(errors, name)?.message as string | undefined
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      render={({ field: { ref, value, onChange: fieldOnChange, ...rest } }) => {
        const handleChange = (event) => {
          fieldOnChange(event)
          if (onChange) {
            onChange(event)
          }
        }

        return (
          <FormControl
            fullWidth={props.fullWidth || false}
            error={Boolean(fieldError)}
            size={size}
            className={className}
            disabled={disabled}
            required={required}
          >
            <InputLabel id={`${name}-label`}>{props.label}</InputLabel>
            <MUISelect
              {...props}
              id={name}
              labelId={`${name}-label`}
              error={Boolean(fieldError)}
              {...rest}
              inputRef={ref}
              renderValue={props.renderValue}
              value={value}
              onChange={handleChange}
              MenuProps={{ className: 'w-1' }}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              ) : (
                options.map(({ label, value, locked }, idx) => (
                  <MenuItem
                    className="whitespace-normal"
                    key={idx}
                    value={value as any}
                    disabled={locked}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {label}
                    {locked && <LockPersonIcon fontSize="small" />}
                  </MenuItem>
                ))
              )}
            </MUISelect>
            <FormHelperText>{fieldError || helperText}</FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}

export default SelectDropdown
