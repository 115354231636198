import { FC, useMemo } from 'react'

import { Typography } from '@mui/material'

type NextDueValueProps = {
  regularValue: string | number
  overrideValue?: string
  cadenceLabel?: string
  isRecurringTask: boolean
}

const NextDueValue = ({
  regularValue,
  overrideValue,
  cadenceLabel,
  isRecurringTask,
}: NextDueValueProps) => {
  const text = useMemo(() => {
    if (!isRecurringTask || !regularValue) {
      return '--'
    } else if (overrideValue && overrideValue !== regularValue) {
      return (
        <div className="flex gap-1">
          <span className="text-gray-400 line-through">
            {regularValue} {cadenceLabel}
          </span>
          {overrideValue} {cadenceLabel}
        </div>
      )
    } else {
      return `${regularValue} ${cadenceLabel ?? ''}`
    }
  }, [isRecurringTask, overrideValue, regularValue, cadenceLabel])

  return <Typography variant="body2">{text}</Typography>
}

export default NextDueValue
