import { IconButton, IconButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { SparklesIcon } from 'lucide-react'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'

const GradientIconButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: 'transparent',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(45deg, rgba(255,107,107,0.2), rgba(255,217,61,0.2), rgba(107,203,119,0.2), rgba(77,150,255,0.2), rgba(155,89,182,0.2))',
    clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
    transform: 'translate(-50%, -50%) scale(0)',
    transition: 'transform 0.3s ease',
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '&::before': {
      transform: 'translate(-50%, -50%) scale(1)',
    },
    '& svg': {
      filter: 'drop-shadow(0 0 3px rgba(255, 255, 255, 0.5))',
    },
  },
}))

export const AiSparklesIcon = ({
  width = 24,
  height = 24,
}: {
  width?: number
  height?: number
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <defs>
        <linearGradient id="aiIconGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#FF6B6B" />
          <stop offset="25%" stopColor="#FFD93D" />
          <stop offset="50%" stopColor="#6BCB77" />
          <stop offset="75%" stopColor="#4D96FF" />
          <stop offset="100%" stopColor="#9B59B6" />
        </linearGradient>
      </defs>

      <AutoAwesomeIcon
        fontSize="small"
        style={{ fill: 'url(#aiIconGradient)', stroke: 'gray', strokeWidth: 0.5 }}
      />
    </svg>
  )
}


const AiSparklesIconButton: React.FC<IconButtonProps> = ({ ...props }) => {
  return (
    <GradientIconButton
      size="large"
      aria-label="AI assistant"
      color="inherit"
      {...props}
    >
      <AiSparklesIcon />
    </GradientIconButton>
  )
}

export default AiSparklesIconButton
