import React, { useEffect } from 'react'

import {
  InputBaseComponentProps,
  Autocomplete as MUIAutocomplete,
  TextField,
  createFilterOptions,
} from '@mui/material'
import { get } from 'lodash'
import { useDebounce } from 'usehooks-ts'

import { Controller, useFormContext } from '@redwoodjs/forms'

export interface CreatableAutocompleteOption {
  label: string
  value: string
  inputValue?: string
}

interface AutocompleteProps
  extends Omit<React.ComponentProps<typeof MUIAutocomplete>, 'renderInput'> {
  /** The form name of the input field.
   * If `isFormField` is true, this prop is required.
   */
  name?: string
  label?: string
  helperText?: string
  error?: boolean
  size?: 'small' | 'medium'
  options: CreatableAutocompleteOption[]
  className?: string
  growHeight?: boolean
  required?: boolean
  onDebounce?: (inputValue: string) => void
  /** Used to determine the string value for a given option.
   * It's used to fill the input (and the list box options if renderOption is not provided).
   * This is useful in cases where your option label is different & you want to show a different label in the input.
    @param option
    @default
    (option) => option.inputValue ?? option.label ?? option */
  getOptionLabelOverride?: (option: CreatableAutocompleteOption) => string
  /** If true, the component will be rendered as a Form Controller component.
   * If false, the component will be rendered as a standalone component.
   * If true, the `name` prop is required.
   * If false, the `value` and `onChange` props are required.
   * @default false
   */
  isFormField?: boolean
  /** The value of the input field.
   * If `isFormField` is false, this prop is required.
   */
  value?: any
  /** The function that will be called when the input value changes.
   * If `isFormField` is false, this prop is required
   * @param value
   * The selected option or the input value.
   * If the input value is not an existing option, it will be an object with the following shape:
   * { label: string, value: string, isNew: boolean }
   * If the input value is an existing option, it will be the option object.
   * If `isFormField` is true, this function will be called with the updated value.
   * */
  onChange?: (value: any) => void
  textFieldInputProps?: InputBaseComponentProps
}

const filter = createFilterOptions<CreatableAutocompleteOption>()

const CreatableAutocomplete: React.FC<AutocompleteProps> = ({
  name,
  options,
  growHeight,
  onDebounce,
  getOptionLabelOverride,
  value,
  onChange,
  error,
  label = '',
  helperText,
  isFormField = false,
  textFieldInputProps = {},
  ...props
}) => {
  const [localInputValue, setLocalInputValue] = React.useState('')
  const [shouldTriggerOnDebounce, setShouldTriggerOnDebounce] =
    React.useState(false)
  const debouncedLocalInputValue = useDebounce(localInputValue, 500)

  const { formState, control, watch } = useFormContext()
  const formValue = isFormField ? watch(name) : value

  const fieldError = isFormField
    ? get(formState.errors, `${name}.message`, '')
    : error
  const fieldHelperText = isFormField
    ? get(formState.errors, `${name}.message`, '')
    : helperText

  const getOptionLabel = (option) => {
    if (getOptionLabelOverride) {
      return getOptionLabelOverride(option)
    }
    if (typeof option === 'string') {
      return option
    }
    if (option.inputValue) {
      return option.inputValue
    }
    if (option?.isNew && option?.value) {
      return option.value
    }
    return option.label
  }

  useEffect(() => {
    if (!formValue) return
    setLocalInputValue(getOptionLabel(formValue))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue])

  useEffect(() => {
    if (shouldTriggerOnDebounce) {
      onDebounce?.(debouncedLocalInputValue)
      setShouldTriggerOnDebounce(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocalInputValue])

  const handleChange = (event, newValue) => {
    const updatedValue =
      typeof newValue === 'string'
        ? newValue
        : newValue && newValue.inputValue
        ? { value: newValue.value, newValue: newValue.inputValue, isNew: true }
        : newValue && newValue.value
        ? { label: newValue.label, value: newValue.value, isNew: false }
        : newValue

    if (isFormField) {
      return updatedValue
    } else {
      onChange?.(updatedValue)
    }
  }

  const AutocompleteComponent = (
    <MUIAutocomplete
      value={formValue}
      className="w-full"
      onChange={handleChange}
      filterOptions={(options: CreatableAutocompleteOption[], params) => {
        const filtered = filter(options, params)
        const { inputValue } = params
        const isExisting = options.some((option) => inputValue === option.value)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
            value: inputValue,
          })
        }
        return filtered
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options}
      inputValue={localInputValue}
      onInputChange={(event, newInputValue) => {
        if (!event) return
        if (event.type === 'change') {
          setShouldTriggerOnDebounce(true)
        }
        setLocalInputValue(newInputValue)
      }}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <li {...props} style={{ whiteSpace: 'pre-line' }}>
          {option.label}
        </li>
      )}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          helperText={helperText as string}
          error={!!fieldError}
          className="w-full"
          maxRows={growHeight ? 5 : 1}
          multiline={growHeight}
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              ...textFieldInputProps,
            }
          }}
        />
      )}
      {...props}
    />
  )

  if (isFormField) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) =>
          React.cloneElement(AutocompleteComponent, {
            value,
            onChange: (event, newValue) => {
              onChange(handleChange(event, newValue))
            },
          })
        }
      />
    )
  }

  return AutocompleteComponent
}

export default CreatableAutocomplete
