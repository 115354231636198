import TextField from './MUI/TextField'

type PartsTimeInputTextFieldProps = {
  name: string
  label: string
  disabled?: boolean
}

export const PartsTimeInputTextField: React.FC<
  PartsTimeInputTextFieldProps
> = ({ name, label, disabled }) => {
  const renderPartsTimeValue = (value: string | number) => {
    if (value === '-1' || value === -1) {
      return ''
    }
    return value
  }
  return (
    <TextField
      name={name}
      size="small"
      label={label}
      variant="outlined"
      renderValue={renderPartsTimeValue}
      disabled={disabled}
    />
  )
}

export default PartsTimeInputTextField
