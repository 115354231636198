import { ReactElement } from 'react'

import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined'
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined'
import { Tooltip } from '@mui/material'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import {
  RemainingValue,
  cadenceValueSchema,
  getRemainingValueFromNextDueValue,
} from '@wingwork/common/src/jsonObjects'
import get from 'lodash/get'
import { MaintenanceItem, Aircraft } from 'types/graphql'

type DueStatusChipProp = {
  maintenanceItem: MaintenanceItem
  aircraft: Pick<Aircraft, 'AircraftUsageLog'>
}

interface DueStatusChipVariant {
  color: 'error' | 'warning' | 'success'
  icon: ReactElement
  variant: 'filled' | 'outlined'
  title: string
}

export const DUE_STATUS_VARIANT: { [key: string]: DueStatusChipVariant } = {
  OVERDUE: {
    color: 'error',
    icon: <DoDisturbOutlinedIcon fontSize="small" />,
    variant: 'filled',
    title: 'The maintenance is overdue',
  },
  IN_TOLERANCE: {
    color: 'error',
    icon: <TitleOutlinedIcon fontSize="small" />,
    variant: 'outlined',
    title: 'The maintenance is in tolerance',
  },
  APPROACHING_DUE: {
    color: 'warning',
    icon: <PriorityHighIcon fontSize="small" />,
    variant: 'outlined',
    title: 'The maintenance is approaching due',
  },
  GOOD: {
    color: 'success',
    icon: <CheckOutlinedIcon fontSize="small" />,
    variant: 'outlined',
    title: 'The maintenance is good',
  },
  NOT_DUE: {
    color: 'success',
    icon: <CheckBoxOutlinedIcon fontSize="small" />,
    variant: 'outlined',
    title: 'The maintenance is not due',
  },
  OPEN: {
    color: 'warning',
    icon: <NewReleasesOutlinedIcon fontSize="small" />,
    variant: 'outlined',
    title: 'Compliance is open',
  },
}

const DueStatusChip = ({ maintenanceItem, aircraft }: DueStatusChipProp) => {
  const latestAircraftUsageLog = get(aircraft, ['AircraftUsageLog', 0])
  const latestComponentUsageLog =
    latestAircraftUsageLog?.ComponentUsageLog?.find(
      (log) => log.component.id === maintenanceItem?.trackedByComponentId
    )
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem?.cadenceValue)
  if (!maintenanceItem || !latestComponentUsageLog) {
    return <></>
  }
  if (maintenanceItem.nextDueStatus === 'NOT_DUE') {
    // We need to handle when AD SB Status is OPEN but nextDueStatus is NOT_DUE
    let key = 'NOT_DUE'
    let label = 'Not due'
    if (maintenanceItem.adSbStatus === 'OPEN') {
      key = 'OPEN'
      label = 'Open'
    }
    if (maintenanceItem.adSbStatus === 'COMPLIED_WITH') {
      key = 'GOOD'
      label = 'Complied with'
    }
    if (maintenanceItem.adSbStatus === 'NOT_APPLICABLE') {
      key = 'NOT_DUE'
      label = 'Not Applicable'
    }
    return (
      <Tooltip title={DUE_STATUS_VARIANT[key].title}>
        <Chip
          icon={DUE_STATUS_VARIANT[key]?.icon}
          label={label}
          color={DUE_STATUS_VARIANT[key]?.color}
          variant={DUE_STATUS_VARIANT[key]?.variant}
          size="small"
        />
      </Tooltip>
    )
  }
  const remainingValue: RemainingValue = getRemainingValueFromNextDueValue(
    maintenanceItem.maintenanceNextDue[0],
    cadenceValue,
    latestComponentUsageLog
  )
  let nextDueStatus: string = maintenanceItem?.nextDueStatus
  if (nextDueStatus !== remainingValue?.status) {
    // TODO: Trigger backend refresh of nextDueStatus
    // Move this to useDueStatus hook and not here
    console.log('Mismatch between server status and FE calculated status')
    nextDueStatus = remainingValue?.status
  }

  //const title = DUE_STATUS_VARIANT[nextDueStatus]?.title

  const unitArray = ['date', 'flying_hours', 'landings', 'cycles'].filter(
    (_) => remainingValue[_] !== undefined
  )

  return (
    <Tooltip title={DUE_STATUS_VARIANT[remainingValue.status].title}>
      <div>
        {remainingValue.status === 'OVERDUE' ? (
          <Chip
            icon={DUE_STATUS_VARIANT['OVERDUE']?.icon}
            label="Overdue"
            color={DUE_STATUS_VARIANT['OVERDUE']?.color}
            variant={DUE_STATUS_VARIANT['OVERDUE']?.variant}
            size="small"
          />
        ) : (
          <>
            {unitArray.map((key, index) => {
              const unitStatus = remainingValue[key]
              const icon = DUE_STATUS_VARIANT[unitStatus.status]?.icon
              const color: 'error' | 'warning' | 'success' =
                DUE_STATUS_VARIANT[unitStatus.status]?.color
              const variant: 'filled' | 'outlined' =
                DUE_STATUS_VARIANT[unitStatus.status]?.variant
              return (
                <div key={index} className="noWrap mb-1 flex items-center">
                  <Chip
                    icon={icon}
                    label={unitStatus.valString}
                    color={color}
                    variant={variant}
                    size="small"
                  />

                  {unitStatus.toleranceString && (
                    <Typography variant="caption" className="ml-1">
                      + {unitStatus.toleranceString}
                    </Typography>
                  )}
                </div>
              )
            })}
          </>
        )}
      </div>
    </Tooltip>
  )
}

export default DueStatusChip
