import React, { useState } from 'react'
import { List, Grid, Paper, Typography } from '@mui/material'
import { AiChatAction } from './types'
import ChatAction from './ChatAction'
import { motion, AnimatePresence } from 'framer-motion'
import ComplianceByLogbook from './ActionHandlers/ComplianceByLogbook'
import { useFormContext } from '@redwoodjs/forms'


const chatActions: AiChatAction[] = [
  {
    type: 'COMPLIANCE_BY_LOGBOOK',
    name: 'Logbook Entry',
    description: 'Add compliance to the aircraft by uploading a logbook entry',
    ActionHandler: ComplianceByLogbook,
    disabled: false
  },
  {
    type: 'LEDGER_BY_WORK_CARDS',
    name: 'Work Card',
    description: 'Add compliance to the aircraft by uploading a work card',
    ActionHandler: () => {
      return <div>Not implemented</div>
    },
    disabled: true
  },
]

interface AiChatInterfaceProps {
  aircraftId: string;
}

const AiChatInterface: React.FC<AiChatInterfaceProps> = ({ aircraftId }) => {
  const [selectedAction, setSelectedAction] = useState<AiChatAction | null>(null)
  const handleActionClick = (action: AiChatAction) => {
    setSelectedAction(action)
  }


  return (
    <div
      className="flex flex-col h-[92%] pl-2 pb-2"
      style={{ background: 'linear-gradient(to bottom, #ffffff, #e6f0ff)' }}
    >
      <div>
        {!selectedAction && (
          <Grid container spacing={2} className="flex-1 overflow-auto mb-4 pr-2 pt-2">
            <AnimatePresence>
              {chatActions.map((action, index) => (
                <Grid item xs={12} sm={6} key={action.name}>
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <ChatAction action={action} onClick={handleActionClick} disabled={action.disabled} comingSoon={action.disabled} />
                  </motion.div>
                </Grid>
              ))}
            </AnimatePresence>
          </Grid>
        )}
      </div>
      {selectedAction && (
        <div className="flex flex-col gap-2 py-2 pr-2">
          <ChatAction action={selectedAction} isActive />
          <selectedAction.ActionHandler aircraftId={aircraftId} />
        </div>
      )}
    </div>
  )
}

export default AiChatInterface
