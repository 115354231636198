import { Button, FormHelperText, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import { get } from 'lodash'
import Searchbar from '../Searchbar/Searchbar'
import { useState, useMemo } from 'react'

import { Aircraft } from 'types/graphql'

import DropdownFilter from '../DropdownFilter/DropdownFilter'

interface PlainFilterContentProps {
  gridView?: boolean
  gridCols?: number
  aircrafts: Aircraft[]
  aircraftsFilterData: any
  enableSearch?: boolean
  disableClearAll?: boolean
  error?: boolean
  helperText?: string
  setAircraftFilter: (aircraftId: string, checked: boolean) => void
  selectAllAircraftFilter?: () => void
  resetetAircraftFilter: () => void
}
interface AircraftFilterProps extends PlainFilterContentProps {
  varient?: 'popover' | 'plain'
  dropdownStyles?: React.CSSProperties
  anchorRight?: boolean
}

const PlainFilterContent: React.FC<PlainFilterContentProps> = ({
  gridView = false,
  disableClearAll = false,
  gridCols = 4,
  error,
  helperText,
  aircrafts,
  aircraftsFilterData,
  setAircraftFilter,
  resetetAircraftFilter,
  selectAllAircraftFilter,
  enableSearch = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredAircrafts = useMemo(() => {
    if (!searchTerm) return aircrafts
    return aircrafts.filter((aircraft) =>
      aircraft.tailNumber.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [aircrafts, searchTerm])

  return (
    <>
      {enableSearch && (
        <Searchbar
          placeholder="Search aircrafts..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-2 w-full"
        />
      )}
      <div className="flex w-full items-center justify-between">
        <Typography variant="caption" color={error ? 'error' : ''}>
          <Typography variant="body1">
            {error ? 'Aircrafts *' : 'Aircrafts'}
          </Typography>
        </Typography>
        <div className="flex">
          {selectAllAircraftFilter && (
            <Button
              variant="text"
              onClick={selectAllAircraftFilter}
              sx={{ textTransform: 'none', marginRight: 1 }}
            >
              Select all
            </Button>
          )}
          {!disableClearAll && (
            <Button
              variant="text"
              onClick={() => resetetAircraftFilter()}
              sx={{ textTransform: 'none' }}
            >
              Clear all
            </Button>
          )}
        </div>
      </div>
      <FormGroup>
        <div
          className={gridView ? `grid grid-cols-${gridCols} ` : 'flex flex-col'}
        >
          {filteredAircrafts.map((aircraft) => {
            return (
              <FormControlLabel
                key={aircraft.tailNumber}
                control={
                  <Checkbox
                    key={aircraftsFilterData[aircraft.id] ?? aircraft.id}
                    checked={get(aircraftsFilterData, aircraft.id)}
                    onChange={(e) =>
                      setAircraftFilter(aircraft.id, e.target.checked)
                    }
                  />
                }
                label={aircraft.tailNumber}
              />
            )
          })}
        </div>
        <FormHelperText error={error} className="pb-2">
          {helperText}
        </FormHelperText>
      </FormGroup>
    </>
  )
}

const AircraftFilter: React.FC<AircraftFilterProps> = ({
  dropdownStyles,
  anchorRight = false,
  varient = 'popover',
  error,
  helperText,
  ...rest
}) => {
  if (varient === 'plain') {
    return (
      <PlainFilterContent error={error} helperText={helperText} {...rest} />
    )
  }

  return (
    <div>
      <DropdownFilter
        label="Aircraft"
        error={error}
        dropdownStyles={dropdownStyles}
        anchorRight={anchorRight}
      >
        <PlainFilterContent {...rest} />
      </DropdownFilter>
      {helperText && (
        <FormHelperText error={error} className="py-1">
          {helperText}
        </FormHelperText>
      )}
    </div>
  )
}

export default AircraftFilter
