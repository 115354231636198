import React from 'react'

import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { IconButton, Paper, Typography, Skeleton } from '@mui/material'
import { useOnClickOutside } from 'usehooks-ts'
interface PrimSecTextComboProps {
  primaryText: string | number
  secondaryText: string | number
  variant?: 'default' | 'inverted'
  primaryTextClassName?: string
  /** Set `collapsible` to `true` if you need an
   * expandable view for the primary text.
   * For it to work, the parent container
   * MUST have it's `position` set to `relative`*/
  collapsible?: boolean
}

const CollapsibleWrapper: React.FC<PrimSecTextComboProps> = ({
  primaryText,
  secondaryText,
  variant = 'default',
  primaryTextClassName = '',
  collapsible = false,
}) => {
  const primaryTextStr = primaryText ? primaryText.toString() : ''
  const primaryTextLength = primaryTextStr.length
  const [expanded, setExpanded] = React.useState(false)
  const divRef = React.useRef<HTMLDivElement>(null)

  const outsideAlertToggle = () => {
    setExpanded(false)
  }
  useOnClickOutside(divRef, outsideAlertToggle)

  const truncatedPrimaryText = primaryTextStr.slice(0, 60)

  if (!collapsible)
    return (
      <PrimSecTextCombo
        primaryText={primaryText}
        secondaryText={secondaryText}
        variant={variant}
        primaryTextClassName={primaryTextClassName}
      />
    )

  return expanded ? (
    <Paper
      className="absolute top-0 z-[1] -ml-2 flex w-full items-center justify-between p-2 "
      ref={divRef}
    >
      <PrimSecTextCombo
        primaryText={primaryText}
        secondaryText={secondaryText}
        variant={variant}
        primaryTextClassName={primaryTextClassName}
      />
      <IconButton
        onClick={(event) => {
          event.stopPropagation()
          setExpanded(!expanded)
        }}
      >
        <OpenInFullIcon />
      </IconButton>
    </Paper>
  ) : (
    <div className="w-full items-center justify-between">
      <div className="flex flex-col gap-1">
        <div className="flex items-center">
          <PrimSecTextCombo
            primaryText={
              primaryTextLength > 60
                ? `${truncatedPrimaryText}...`
                : primaryTextStr
            }
            secondaryText={secondaryText}
            variant={variant}
            primaryTextClassName={primaryTextClassName}
          />
          {primaryTextLength > 60 && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation()
                setExpanded(!expanded)
              }}
            >
              <OpenInFullIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  )
}

const PrimSecTextCombo: React.FC<
  Omit<PrimSecTextComboProps, 'collapsible'>
> = ({
  primaryText,
  secondaryText,
  variant = 'default',
  primaryTextClassName = '',
}) => {
  return (
    <div>
      {variant === 'default' ? (
        <>
          <Typography variant="body2" className={primaryTextClassName}>
            {primaryText}
          </Typography>
          <Typography variant="caption">
            {secondaryText !== undefined ? (
              secondaryText
            ) : (
              <Skeleton width={75} />
            )}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="caption">{secondaryText}</Typography>
          <Typography variant="body2" className={primaryTextClassName}>
            {primaryText !== undefined ? primaryText : <Skeleton width={75} />}
          </Typography>
        </>
      )}
    </div>
  )
}

export default CollapsibleWrapper
