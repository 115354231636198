import { Tag } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

const CREATE_MANY_TAGS = gql`
  mutation CreateManyTags($input: [CreateTagInput!]!) {
    createManyTags(input: $input) {
      id
      name
    }
  }
`

const SET_MAINTENANCE_ITEM_TAGS = gql`
  mutation SetMaintenanceItemTags($id: String!, $tags: [String!]!) {
    setMaintenanceItemTags(id: $id, tags: $tags) {
      id
      tags {
        id
        name
      }
    }
  }
`

const SET_WORK_ORDER_MAINTENANCE_ITEM_TAGS = gql`
  mutation SetWorkOrderMaintenanceItemTags($id: String!, $tags: [String!]!) {
    setWorkOrderMaintenanceItemTags(id: $id, tags: $tags) {
      id
      tags {
        id
        name
      }
    }
  }
`

const useAddTagsToMtxItem = () => {
  const [createManyTags] = useMutation(CREATE_MANY_TAGS)
  const [setTags] = useMutation(SET_MAINTENANCE_ITEM_TAGS)
  const [setWorkOrderTags] = useMutation(SET_WORK_ORDER_MAINTENANCE_ITEM_TAGS)

  const addTagsToMtxItem = async (
    allTags: Tag[],
    mtxItemId: string,
    isInternalWorkOrder = false
  ) => {
    const newTags = allTags.filter((tag) => !tag.id)
    const oldTags = allTags.filter((tag) => tag.id)

    let tags = oldTags.map((tag) => tag.id)
    try {
      if (newTags.length > 0) {
        const { data: newTagData } = await createManyTags({
          variables: { input: newTags },
        })
        const newTagIds = newTagData?.createManyTags?.map((tag) => tag.id)
        tags = [...tags, ...newTagIds]
      }

      if (isInternalWorkOrder) {
        const { data: tagAssociationData } = await setTags({
          variables: { id: mtxItemId, tags },
        })

        return tagAssociationData.setMaintenanceItemTags
      }
      const { data: tagAssociationData } = await setWorkOrderTags({
        variables: { id: mtxItemId, tags },
      })
      return tagAssociationData.setWorkOrderMaintenanceItemTags
    } catch (error) {
      console.error('Error adding tags to maintenance item', error)
      return Promise.reject()
    }
  }

  return { addTagsToMtxItem }
}

export default useAddTagsToMtxItem
