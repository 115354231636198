import React, { ReactElement, ReactNode } from 'react'

import { Typography } from '@mui/material'

const Field = ({
  label,
  children,
  className,
}: {
  label: string
  children: ReactNode
  className?: string
}): ReactElement => {
  return (
    <div className={`flex flex-col ${className}`}>
      <Typography variant="caption">{label}</Typography>
      {children}
    </div>
  )
}

export default Field
