import FilterList from '@mui/icons-material/FilterList'
import { IconButton, SxProps, Theme, styled } from '@mui/material'
import Button from '@mui/material/Button'
import { useOnClickOutside } from 'usehooks-ts'

interface DropdownFilterProps {
  label?: string
  buttonSx?: SxProps<Theme>
  dropdownStyles?: React.CSSProperties
  children: JSX.Element | JSX.Element[]
  disabled?: boolean
  anchorRight?: boolean
  startIcon?: JSX.Element
  error?: boolean
}

const FilterWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  width: '326px',
  marginTop: '8px',
  background: '#FFFFFF',
  boxShadow:
    '0px 2px 4px rgba(0, 0, 0, 0.02), 0px 4px 12px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  flex: 'none',
  order: 1,
  position: 'absolute',
  flexGrow: 0,
  zIndex: 1,
}))

const DropdownFilter: React.FC<DropdownFilterProps> = ({
  label,
  buttonSx,
  dropdownStyles,
  children,
  disabled = false,
  anchorRight = false,
  error = false,
  startIcon = <FilterList />,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const divRef = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const outsideAlertToggle = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }
  useOnClickOutside(divRef, outsideAlertToggle)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      {label ? (
        <Button
          ref={buttonRef}
          variant="outlined"
          color={error ? 'error' : 'base'}
          startIcon={startIcon}
          className="relative"
          onClick={toggle}
          disabled={disabled}
          sx={{ textTransform: 'none', ...buttonSx }}
        >
          {label}
        </Button>
      ) : (
        <IconButton onClick={toggle}>{startIcon}</IconButton>
      )}
      {isOpen && (
        <FilterWrapper
          style={{
            ...dropdownStyles,
            ...(anchorRight && {
              right:
                window.innerWidth -
                buttonRef.current?.getBoundingClientRect().right,
            }),
          }}
          ref={divRef}
        >
          {children}
        </FilterWrapper>
      )}
    </div>
  )
}

export default DropdownFilter
