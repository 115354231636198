import { useState } from 'react'

function usePreventClickOnTextSelect() {
  const [mouseDownPoint, setMouseDownPoint] = useState({ x: 0, y: 0 })
  const [preventClick, setPreventClick] = useState(false)

  const handleMouseDown = (e) => {
    setMouseDownPoint({ x: e.clientX, y: e.clientY })
  }

  const handleMouseUp = (e) => {
    const mouseUpX = e.clientX
    const mouseUpY = e.clientY

    const hasMoved =
      Math.abs(mouseDownPoint.x - mouseUpX) > 5 ||
      Math.abs(mouseDownPoint.y - mouseUpY) > 5

    if (hasMoved) {
      setPreventClick(true)
    } else {
      setPreventClick(false)
    }
  }

  const handleClick = (e) => {
    if (preventClick) {
      e.stopPropagation()
      setPreventClick(false)
      return false
    }
    return true
  }

  return {
    handleMouseDown,
    handleMouseUp,
    handleClick,
  }
}

export default usePreventClickOnTextSelect

// Usage

// const { handleMouseDown, handleMouseUp, handleClick } = usePreventClickOnTextSelect();
// const handleParentClick = (e) => {
//     if (handleClick(e)) {
//         console.log("Parent div clicked without selecting text");
//     }
// };
