import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { object, number, date, InferType, string } from 'yup'

import {
  ComponentLimitType,
  ComponentUsageLog,
  MaintenanceCadenceType,
  PartsTransaction,
} from '../graphql'

import { CadenceValue, cadenceValueSchema } from './cadenceValue'
import { UNIT_MAP } from './remainingValue'
import { formatDateForDisplayInUtc } from '../maintenanceItem/utils'

dayjs.extend(timezone)
dayjs.extend(utc)

const unitValueSchema = number()
  .positive()
  .optional()
  .default(undefined)
  .transform((value, originalValue) => {
    if (originalValue === undefined) return undefined
    if (originalValue === null) return undefined
    if (originalValue === '') return undefined
    if (typeof originalValue === 'number') {
      return originalValue
    }
    if (typeof originalValue === 'string') {
      return parseFloat(value)
    }
    return value
  })

export const nextDueValueSchema = object({
  date: date()
    .nullable()
    .default(undefined)
    .transform((value, originalValue) => {
      if (originalValue === undefined) return undefined
      if (originalValue === null) return undefined
      if (originalValue === '') return undefined
      return value
    }),
  flying_hours: unitValueSchema,
  cycles: unitValueSchema,
  landings: unitValueSchema,
  note: string().optional().default(undefined),
  category: string().optional().default(undefined),
})

interface PreviousComplianceInformation {
  previousNextDueValue: NextDueValue
}

interface PartInstallationAdjustment {
  transactionInfo: Partial<PartsTransaction>
  ComponentLimitType: ComponentLimitType
}

export const getNextDueAsFlatDict = (nextDueValue: NextDueValue) => {
  const result = {}
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  const fieldMap = {
    date: 'Date',
    flying_hours: 'Hours',
    cycles: 'Cycles',
    landings: 'Landings',
  }
  fields.forEach((field) => {
    if (nextDueValue?.[field]) {
      result['nextDue' + fieldMap[field]] = nextDueValue[field]
    } else {
      result['nextDue' + fieldMap[field]] = null
    }
  })
  return result
}

export const getNextDueValueAsStringArray = (nextDueValue: NextDueValue) => {
  const result: string[] = []
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  fields.forEach((field) => {
    if (nextDueValue[field]) {
      if (field === 'date') {
        result.push(formatDateForDisplayInUtc(nextDueValue[field]))
      } else {
        result.push(`${nextDueValue[field]} ${UNIT_MAP[field]}`)
      }
    }
  })
  return result
}

export const calculateNextDueValue = (
  cadenceType: MaintenanceCadenceType,
  cadenceValue: CadenceValue,
  componentUsageLog: Partial<ComponentUsageLog>,
  currentComplianceDate: Date,
  isEndOfMonthAdjustment: boolean,
  previousComplianceInformation?: PreviousComplianceInformation | null,
  partsInformation?: PartInstallationAdjustment | null
): NextDueValue => {
  cadenceValue = cadenceValueSchema.cast(cadenceValue)

  const calculateNextDueValue = (
    previousValue: Partial<ComponentUsageLog>,
    cadenceValue: CadenceValue,
    cadenceType: MaintenanceCadenceType,
    lastComplianceDate: Date,
    isEndOfMonthAdjustment: boolean
  ): NextDueValue => {
    const nextDueValue: NextDueValue = {}
    const monthCalculation = () => {
      if (cadenceValue['months'] === undefined) return undefined
      if (isEndOfMonthAdjustment) {
        return dayjs
          .tz(lastComplianceDate)
          .add(cadenceValue['months'], 'months')
          .endOf('month')
          .toDate()
      } else {
        return dayjs
          .tz(lastComplianceDate)
          .add(cadenceValue['months'], 'months')
          .toDate()
      }
    }
    Object.keys(cadenceValue).forEach((key) => {
      if (key === 'days' && cadenceValue['days'] !== undefined) {
        nextDueValue['date'] = dayjs
          .tz(lastComplianceDate)
          .add(cadenceValue['days'], 'days')
          .toDate()
      }
      if (key === 'months' && cadenceValue['months'] !== undefined) {
        nextDueValue['date'] = monthCalculation()
      }
      if (key === 'cycles' && cadenceValue['cycles'] !== undefined) {
        nextDueValue['cycles'] =
          cadenceValue['cycles'] + previousValue.cycleSinceNew
      }
      if (
        key === 'flying_hours' &&
        cadenceValue['flying_hours'] !== undefined
      ) {
        nextDueValue['flying_hours'] =
          cadenceValue['flying_hours'] + previousValue.totalTimeSinceNew
      }
      if (key === 'landings' && cadenceValue['landings'] !== undefined) {
        nextDueValue['landings'] =
          cadenceValue['landings'] + previousValue.cycleSinceNew
      }
    })
    return nextDueValue
  }
  const getAdjustedCadenceValue = (): CadenceValue => {
    const adjustedCadenceValue: CadenceValue = {}
    const handleDefaultValue = (value: number | undefined) => {
      if (value === undefined) return 0
      if (value === null) return 0
      if (value < 0) return 0
      return value
    }
    const LIMIT_TYPE_TO_FIELD_NAME = {
      LIFE_LIMITED: {
        flying_hours: 'hoursSinceNew',
        cycles: 'cyclesSinceNew',
        landings: 'cyclesSinceNew',
        months: 'monthsSinceNew',
      },
      OVERHAUL: {
        flying_hours: 'hoursSinceOverhaul',
        cycles: 'cyclesSinceOverhaul',
        landings: 'cyclesSinceOverhaul',
        months: 'monthsSinceOverhaul',
      },
    }
    const mappedFields =
      LIMIT_TYPE_TO_FIELD_NAME[partsInformation.ComponentLimitType]
    if (mappedFields) {
      Object.keys(mappedFields).forEach((key) => {
        if (cadenceValue[key] !== undefined) {
          adjustedCadenceValue[key] =
            cadenceValue[key] -
            handleDefaultValue(
              partsInformation.transactionInfo[mappedFields[key]]
            )
        }
      })
      return adjustedCadenceValue
    }
    return cadenceValue
  }
  const getAdjustedPreviousCompliance = () => {
    //TODO: Identify if the previous Compliance took place in the tolerance window
    // If so, adjust the current compliance timestamp by the tolerance window
    const adjustedComponentUsageLog: Partial<ComponentUsageLog> = {
      ...componentUsageLog,
    }
    let adjustedComplianceDate = currentComplianceDate

    const mappedFields = {
      flying_hours: 'totalTimeSinceNew',
      cycles: 'cycleSinceNew',
      landings: 'cycleSinceNew',
    }

    Object.keys(mappedFields).forEach((key) => {
      if (cadenceValue[key] !== undefined) {
        const marginOfError = Math.abs(
          componentUsageLog[mappedFields[key]] -
          previousComplianceInformation.previousNextDueValue[key]
        )
        if (
          cadenceValue.tolerance?.[key] &&
          marginOfError <= cadenceValue.tolerance[key]
        ) {
          adjustedComponentUsageLog[mappedFields[key]] =
            previousComplianceInformation.previousNextDueValue[key]
        }
      }
    })

    if (cadenceValue['months'] !== undefined) {
      const marginOfError = Math.abs(
        dayjs(currentComplianceDate).diff(
          previousComplianceInformation.previousNextDueValue.date,
          'month'
        )
      )
      if (
        cadenceValue.tolerance?.months &&
        marginOfError < cadenceValue.tolerance?.months
      ) {
        adjustedComplianceDate =
          previousComplianceInformation.previousNextDueValue.date
      }
    }

    return [adjustedComponentUsageLog, adjustedComplianceDate]
  }

  // Case 1: No Previous Compliance Information or Parts Information
  if (!previousComplianceInformation && !partsInformation) {
    return {
      ...calculateNextDueValue(
        componentUsageLog,
        cadenceValue,
        cadenceType,
        currentComplianceDate,
        isEndOfMonthAdjustment
      ),
      note: 'Next due calculated based on the current compliance information',
    }
  }

  // Case 2: No Previous Compliance Information but Parts Information
  if (!previousComplianceInformation && partsInformation) {
    // Adjust the cadence value based on the part installation
    const adjustedCadenceValue = getAdjustedCadenceValue()
    return {
      ...calculateNextDueValue(
        componentUsageLog,
        adjustedCadenceValue,
        cadenceType,
        currentComplianceDate,
        isEndOfMonthAdjustment
      ),
      //TODO: only if adjustment took place
      note: 'Next due adjusted based on part installation',
    }
  }

  // Case 3: Previous Compliance Information but no Parts Information
  if (previousComplianceInformation && !partsInformation) {
    // Adjust the current timestamp if the compliance took place in tolerance window
    const [adjustedPreviousCompliance, adjustComplianceDate] =
      getAdjustedPreviousCompliance()
    return {
      ...calculateNextDueValue(
        adjustedPreviousCompliance as ComponentUsageLog,
        cadenceValue,
        cadenceType,
        adjustComplianceDate as Date,
        isEndOfMonthAdjustment
      ),
      //TODO: only if adjustment took place
      note: 'Next due calculated based on previous compliance information',
    }
  }

  // Case 4: Previous Compliance Information and Parts Information
  if (previousComplianceInformation && partsInformation) {
    // Adjust the current timestamp if the compliance took place in tolerance window
    // Adjust the cadence value based on the part installation
    const adjustedCadenceValue = getAdjustedCadenceValue()
    const [adjustedPreviousCompliance, adjustComplianceDate] =
      getAdjustedPreviousCompliance()
    return {
      ...calculateNextDueValue(
        adjustedPreviousCompliance as ComponentUsageLog,
        adjustedCadenceValue,
        cadenceType,
        adjustComplianceDate as Date,
        isEndOfMonthAdjustment
      ),
      //TODO: only if adjustment took place
      note: 'Next due calculated based on previous compliance and part installation',
    }
  }
}

export type NextDueValue = InferType<typeof nextDueValueSchema>
