import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CustomRequestHeadersState {
  aircraftId: string
  orgHasPermissions: boolean
}

const initialState: CustomRequestHeadersState = {
  aircraftId: '',
  orgHasPermissions: false,
}

const customRequestHeadersSlice = createSlice({
  name: 'customRequestHeaders',
  initialState,
  reducers: {
    //TODO refactor to use this slice to setAircraftId instead of the auditLog because aircraftId header is needed for both audit log and permit checks
    setAircraftId: (
      state: CustomRequestHeadersState,
      action: PayloadAction<string>
    ) => {
      state.aircraftId = action.payload
    },
    setOrgHasPermissions: (
      state: CustomRequestHeadersState,
      action: PayloadAction<boolean>
    ) => {
      state.orgHasPermissions = action.payload
    },
  },
})

export const { setAircraftId, setOrgHasPermissions } =
  customRequestHeadersSlice.actions

export default customRequestHeadersSlice.reducer
