import { OperationVariables } from '@apollo/client/core'
import { AircraftComponent, UpdateAircraftComponentInput } from 'types/graphql'

import useMutationUpdate from './useMutationUpdate'

const UPDATE_AIRCRAFT_COMPONENT_MUTATION = gql`
  mutation updateAircraftComponent(
    $id: String!
    $input: UpdateAircraftComponentInput!
  ) {
    updateAircraftComponent(id: $id, input: $input) {
      id
      isPrimary
      partNumber
      serialNumber
      monthsSinceNew
      monthsSinceOverhaul
      monthsSinceRepair
      hoursSinceNew
      hoursSinceOverhaul
      hoursSinceRepair
      cyclesSinceNew
      cyclesSinceOverhaul
      cyclesSinceRepair
      name
      ataCode
      description
      installationDate
      warrantyDate
      location
      installationAircraftUsageLog {
        id
        airframeTotalTime
        airframeCycle
      }
      metadata
    }
  }
`

const useUpdateAircraftComponent = (options?: OperationVariables) => {
  return useMutationUpdate<AircraftComponent, UpdateAircraftComponentInput>(
    UPDATE_AIRCRAFT_COMPONENT_MUTATION,
    options
  )
}

export default useUpdateAircraftComponent
