import { useMemo, useState } from 'react'

import { useLazyQuery, ApolloError } from '@apollo/client'
import { Aircraft } from 'types/graphql'

import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'

const GET_AIRCRAFT_LIST = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query getAircraftList($orgSlug: String!) {
    aircrafts(orgSlug: $orgSlug) {
      id
      model
      name
      serialNumber
      onboardStatus
      orgSlug
      tailNumber
      profilePicture {
        id
        url
      }
      AircraftUsageLog {
        ...CoreAircraftUsageLogFields
      }
      AircraftUsageParameter {
        dailyFlyingTime
        dailyLandings
      }
    }
  }
`

const GET_AIRCRAFT_BY_ID = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query getAircraftById($id: String!) {
    aircraft(id: $id) {
      id
      model
      name
      serialNumber
      onboardStatus
      orgSlug
      tailNumber
      profilePicture {
        id
        url
      }
      AircraftUsageLog {
        ...CoreAircraftUsageLogFields
      }
      AircraftUsageParameter {
        dailyFlyingTime
        dailyLandings
      }
    }
  }
`

export interface AircraftFetchQueryParams {
  orgSlug: string
}

interface AircraftRecordsHook {
  aircrafts: Record<string, Aircraft>
  aircraftsArr: Aircraft[]
  loading: boolean
  hasLoaded: boolean
  error: ApolloError
  loadAircraftList: (params: AircraftFetchQueryParams) => void
  loadAircraftById: (id: string) => void
}

const useAircraftList = (): AircraftRecordsHook => {
  const [aircraftsArr, setAircraftsArr] = useState([])
  const [error, setError] = useState<ApolloError>(undefined)
  const [hasLoaded, setHasLoaded] = useState<boolean>(false)

  const aircrafts: Record<string, Aircraft> = useMemo(() => {
    const result: Record<string, Aircraft> = {};
    for (const aircraft of aircraftsArr) {
      result[aircraft.id] = aircraft;
    }
    return result;
  }, [aircraftsArr]);

  const [fetchAircraftList, { loading: isAircraftListLoading }] = useLazyQuery(
    GET_AIRCRAFT_LIST,
    {
      onCompleted: (data) => {
        setHasLoaded(true)
        setAircraftsArr(data.aircrafts)
      },
      onError: (error) => {
        setHasLoaded(true)
        setError(error)
      },
      fetchPolicy: 'cache-first',
    }
  )

  const [fetchAircraftById, { loading: isAircraftLoading }] = useLazyQuery(
    GET_AIRCRAFT_BY_ID,
    {
      onCompleted: (data) => {
        setHasLoaded(true)
        const aircrafts = {}
        aircrafts[data.aircraft.id] = data.aircraft
        setAircraftsArr([data.aircraft])
      },
      onError: (error) => {
        setHasLoaded(true)
        setError(error)
      },
      fetchPolicy: 'cache-first',
    }
  )

  const loading = isAircraftListLoading || isAircraftLoading

  const loadAircraftList = (params: AircraftFetchQueryParams) => {
    fetchAircraftList({ variables: params })
  }

  const loadAircraftById = (id: string) => {
    fetchAircraftById({
      variables: {
        id,
        skip: !id,
      },
    })
  }

  return {
    aircrafts,
    aircraftsArr,
    loading,
    hasLoaded,
    error,
    loadAircraftList,
    loadAircraftById,
  }
}

export default useAircraftList
