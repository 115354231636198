import { object, string, number, InferType } from 'yup'
import { UNIT_MAP } from './remainingValue'

const toleranceTypeValidation = string()
  .required()
  .oneOf(['absolute', 'percentage'])

const toleranceSignValidation = string()
  .required()
  .oneOf(['+', '-', '+-'])
  .default('+')

// TODO: support %age tolerance
const toleranceUnitValidation = object()
  .shape({
    value: number().positive().required(),
    type: toleranceTypeValidation,
    sign: toleranceSignValidation,
  })
  .optional()
  .default(undefined)

export const unitValueSchema = number()
  .positive()
  .integer()
  .optional()
  .default(undefined)
  .transform((value, _originalValue) => {
    if (value === '' || value === null || value === undefined || isNaN(value)) {
      return undefined
    }
    if (typeof value === 'number') {
      return value
    }
    if (typeof value === 'string') {
      return parseInt(value)
    }
  })

export const toleranceSchemaV1 = object({
  months: unitValueSchema,
  days: unitValueSchema,
  flying_hours: unitValueSchema,
  cycles: unitValueSchema,
  landings: unitValueSchema,
})

export const toleranceSchemaV2 = object({
  months: toleranceUnitValidation,
  flying_hours: toleranceUnitValidation,
  cycles: toleranceUnitValidation,
  landings: toleranceUnitValidation,
})

export const getToleranceValueAsStringArray = (toleranceValue: Tolerance) => {
  const result: string[] = []
  if (!toleranceValue) {
    return result
  }
  const fields = ['months', 'days', 'flying_hours', 'cycles', 'landings']
  fields.forEach((field) => {
    if (toleranceValue[field]) {
      result.push(`${toleranceValue[field]} ${UNIT_MAP[field]}`)
    }
  })
  return result
}

export type Tolerance = InferType<typeof toleranceSchemaV1>
