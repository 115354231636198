// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'auditLog.operationName.COMPLETED_COMPLIANCE': 'Compliance Completed',
      'auditLog.operationName.PERFORM_SINGLE_COMPLIANCE':
        'Perform Single Compliance',
      'auditLog.operationName.SIGN_COMPLIANCE': 'Sign Compliance',
      'auditLog.operationName.CREATE_COMPLIANCE_LEDGER_REVISION':
        'Create Compliance Ledger Revision',
      'auditLog.operationName.CANCEL_COMPLIANCE_LEDGER_REVISION':
        'Abort Compliance Ledger Revision',
      'auditLog.operationName.RESET_COMPLIANCE_LEDGER_REVISION':
        'Reset Compliance Ledger Revision',
      'auditLog.actionType.CREATE': 'Create',
      'auditLog.actionType.UPDATE': 'Update',
      'auditLog.actionType.DELETE': 'Delete',
      'auditLog.entityType.UserTenantMembership': 'User Tenant Membership',
      'auditLog.entityType.AircraftUsageParameter': 'Aircraft Usage Parameter',
      'auditLog.entityType.AircraftUsageLog': 'Aircraft Usage Log',
      'auditLog.entityType.ComponentUsageLog': 'Component Usage Log',
      'auditLog.entityType.InternalWorkOrder': 'Internal Work Order',
      'auditLog.entityType.InternalWorkItem': 'Internal Work Item',
      'auditLog.entityType.ComplianceLedger': 'Compliance Ledger',
      'auditLog.entityType.ComplianceActivity': 'Compliance Activity',
      'auditLog.entityType.ComplianceSignature': 'Compliance Signature',
      'auditLog.entityType.AircraftComponent': 'Aircraft Component',
      'auditLog.entityType.PartsTransaction': 'Parts Transaction',
      'auditLog.entityType.MaintenanceItem': 'Maintenance Item',
      'auditLog.entityType.ReturnToServiceStatement':
        'Return to Service Statement',
      'auditLog.entityType.MaintenanceItemTag': 'Maintenance Item Tag',
      'auditLog.entityType.MaintenanceNextDue': 'Maintenance Next Due',
      'auditLog.entityType.AttachedFile': 'Attached File',
      'auditLog.entityType.MaintenanceLogbook': 'Maintenance Logbook',
      'auditLog.entityType.MaintenanceLogbookActivity':
        'Maintenance Logbook Activity',
      'auditLog.entityType.IntegrationRegistration': 'Integration Registration',
      'auditLog.entityType.IntegrationOperation': 'Integration Operation',
      'auditLog.entityType.IntegrationLogs': 'Integration Logs',
      'auditLog.entityType.FeTableConfiguration': 'Table Column Configuration',
      'notification.actionTitle.finalizeLogbook': 'Finalize Logbook',

      'complianceLedger.status.COMPLETED': 'Completed',
      'complianceLedger.status.SIGN': 'Sign',
      'complianceLedger.status.REVISION_IN_PROGRESS': 'Revision',

      'workOrder.status.COMPLETED': 'Completed',
      'workOrder.status.IN_PROGRESS': 'In Progress',
      'workOrder.status.DRAFT': 'Draft',

      'purchaseOrder.status.DRAFT': 'Draft',
      'purchaseOrder.status.OPEN': 'Open',
      'purchaseOrder.status.COMPLETED': 'Completed',
      'purchaseOrder.status.CANCELLED': 'Cancelled',

      'purchaseOrder.paymentTerms.null': '',
      'purchaseOrder.paymentTerms.NET_30': 'Net 30',
      'purchaseOrder.paymentTerms.WIRE_TRANSFER': 'Wire Transfer',
      'purchaseOrder.paymentTerms.CREDIT_CARD': 'Credit Card',

      'auditLogNotes.label': 'Change Reason',
      'auditLogNotes.placeholder':
        'Describe why this change is being made (e.g., correcting data, process update).',

      'toolTip.signatureProcessing':
        'Move the slider to adjust for image processing to make your signature look better.',

      'purchaseOrderItem.type.PART': 'Part',
      'purchaseOrderItem.type.SERVICE': 'Service',

      'purchaseOrderItem.condition.NEW': 'New',
      'purchaseOrderItem.condition.OVERHAULED': 'Overhauled',
      'purchaseOrderItem.condition.REPAIRED': 'Repaired',
      'purchaseOrderItem.condition.SERVICEABLE': 'Serviceable',
      'purchaseOrderItem.condition.INSPECTED': 'Inspected',

      'itemLifecycleEventType.CUSTOM': 'Custom',
      'itemLifecycleEventType.RECEIVED': 'Received',
      'itemLifecycleEventType.INSTALLED': 'Installed',
      'itemLifecycleEventType.REMOVED': 'Removed',
      'itemLifecycleEventType.REPAIRED': 'Repaired',
      'itemLifecycleEventType.RETIRED': 'Retired',
      'itemLifecycleEventType.MISCELLANEOUS': 'Miscellaneous',
    },
  },
  fr: {
    translation: {
      'Welcome to React': 'Bienvenue à React et react-i18next',
    },
  },
}

export default resources
