import React, { useMemo } from 'react'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CheckIcon from '@mui/icons-material/Check'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert'
import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import _ from 'lodash'

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal'
import FileUploadButton from 'src/components/FileUploadButton'
import Loading from 'src/components/Loading'
import ReusableTabs, { TabComponent } from 'src/components/MUI/Tabs'
import ReusableTimeline, {
  ReusableTimelineItem,
} from 'src/components/MUI/Timeline'
import UploadedFileDetails from 'src/components/UploadedFileDetails/UploadedFileDetails'
import useQuery from 'src/hooks/useQuery'
import Button from 'src/components/MUI/Button'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useSelector } from 'src/hooks/useSelector'

dayjs.extend(relativeTime)

const FILE_FRAGMENT = gql`
  fragment FileFragmentForBulkComplianceAttachments on FileEntry {
    id
    title
    description
    originalFilename
    uploadedBy {
      id
      firstName
      lastName
    }
  }
`

const FETCH_ATTACHMENTS = gql`
  ${FILE_FRAGMENT}
  query FetchComplianceAttachments($id: String!) {
    complianceActivity(id: $id) {
      id
      attachedFiles {
        id
        createdAt
        file {
          ...FileFragmentForBulkComplianceAttachments
        }
      }
      maintenanceItem {
        id
        attachedFiles {
          id
          createdAt
          file {
            ...FileFragmentForBulkComplianceAttachments
          }
        }
      }
    }
  }
`

const Attachments = ({ task }) => {
  const { data, refetch, loading, hasLoaded } = useQuery(FETCH_ATTACHMENTS, {
    variables: { id: task.id },
  })

  const complianceActivityFiles = useMemo(
    () =>
      _.chain(
        data?.complianceActivity?.attachedFiles.map((attachedFile) => ({
          attachedFileId: attachedFile.id,
          ...attachedFile.file,
          createdAt: attachedFile.createdAt,
        })) ?? []
      )
        .sortBy('createdAt')
        .reverse()
        .value(),
    [data]
  )

  const maintenanceFiles = useMemo(
    () =>
      _.chain(
        data?.complianceActivity?.maintenanceItem?.attachedFiles.map(
          (attachedFile) => ({
            attachedFileId: attachedFile.id,
            ...attachedFile.file,
            createdAt: attachedFile.createdAt,
          })
        ) ?? []
      )
        .sortBy('createdAt')
        .reverse()
        .value(),
    [data]
  )

  const aircraftId = useSelector((state) => state.compliance.aircraftId)
  const canUpdateCompliance = useHasPermission(
    Permissions.compliance.update,
    aircraftId
  )

  return (
    <div className="p-3 pt-0">
      {loading && !hasLoaded ? (
        <Loading />
      ) : (
        <ReusableTabs>
          <TabComponent
            label="Work Completed"
            tooltip="Attachments related to the work performed for this maintenance item."
            icon={<InfoOutlinedIcon fontSize="small" />}
            iconPosition="end"
          >
            <div className="flex flex-col p-2">
              <div className="flex items-center justify-between">
                <Typography variant="subtitle2" fontWeight={'bold'}>
                  Work Completed
                </Typography>
                <FileUploadButton
                  key={task.id}
                  attachables={[{ id: task.id, type: 'ComplianceActivity' }]}
                  refetchAttachments={() => refetch()}
                  button={
                    <Button
                      size="small"
                      variant="outlined"
                      color="base"
                      startIcon={<AddOutlinedIcon fontSize="small" />}
                      locked={!canUpdateCompliance}
                      lockedTooltip="You do not have permission"
                    >
                      Upload File
                    </Button>
                  }
                />
              </div>
              <div className="mt-2 h-50 overflow-y-auto">
                {complianceActivityFiles?.length ? (
                  <ReusableTimeline>
                    {complianceActivityFiles?.map((file) => (
                      <ReusableTimelineItem
                        key={file.id}
                        icon={<CheckIcon fontSize="small" />}
                        dotColor="#7FABFF"
                        title={dayjs(file.createdAt).fromNow()}
                        description={dayjs(file.createdAt).format(
                          'MMMM DD, YYYY'
                        )}
                      >
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-col">
                            <Typography variant="subtitle1" fontWeight={500}>
                              {file.title}
                            </Typography>
                            <Typography variant="caption">
                              {file.description}
                            </Typography>
                          </div>
                          <UploadedFileDetails
                            file={{
                              fileId: file.id,
                              uploadedBy: `${file.uploadedBy?.firstName} ${file.uploadedBy?.lastName}`,
                              fileName: file.originalFilename,
                              // fileSize: '3 MB',
                            }}
                            attachedFileId={file.attachedFileId}
                          />
                        </div>
                      </ReusableTimelineItem>
                    ))}
                  </ReusableTimeline>
                ) : (
                  <div className="flex h-full items-center justify-center gap-1">
                    <SdCardAlertIcon fontSize="large" color="action" />
                    <Typography variant="body1" color="textSecondary">
                      No files found
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </TabComponent>
          <TabComponent
            label="Task Attachments"
            tooltip="Attachments that are directly associated with this maintenance item."
            icon={<InfoOutlinedIcon fontSize="small" />}
            iconPosition="end"
          >
            <div className="flex flex-col p-2">
              <div className="flex items-center justify-between">
                <Typography variant="subtitle2" fontWeight={'bold'}>
                  Task Attachments
                </Typography>
                <FileUploadButton
                  attachables={[
                    { id: task.maintenanceItem?.id, type: 'MaintenanceItem' },
                  ]}
                  refetchAttachments={() => refetch()}
                  button={
                    <Button
                      size="small"
                      variant="outlined"
                      color="base"
                      startIcon={<AddOutlinedIcon fontSize="small" />}
                      locked={!canUpdateCompliance}
                      lockedTooltip="You do not have permission"
                    >
                      Upload File
                    </Button>
                  }
                />
              </div>
              <div className="mt-2 h-50 overflow-y-auto">
                {maintenanceFiles?.length ? (
                  <ReusableTimeline>
                    {maintenanceFiles?.map((file) => (
                      <ReusableTimelineItem
                        key={file.id}
                        icon={<CheckIcon fontSize="small" />}
                        dotColor="#7FABFF"
                        title={dayjs(file.createdAt).fromNow()}
                        description={dayjs(file.createdAt).format(
                          'MMMM DD, YYYY'
                        )}
                      >
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-col">
                            <Typography variant="subtitle1" fontWeight={500}>
                              {file.title}
                            </Typography>
                            <Typography variant="caption">
                              {file.description}
                            </Typography>
                          </div>
                          <UploadedFileDetails
                            file={{
                              fileId: file.id,
                              uploadedBy: `${file.uploadedBy?.firstName} ${file.uploadedBy?.lastName}`,
                              fileName: file.originalFilename,
                              // fileSize: '3 MB',
                            }}
                            attachedFileId={file.attachedFileId}
                          />
                        </div>
                      </ReusableTimelineItem>
                    ))}
                  </ReusableTimeline>
                ) : (
                  <div className="flex h-full items-center justify-center gap-1">
                    <SdCardAlertIcon fontSize="large" color="action" />
                    <Typography variant="body1" color="textSecondary">
                      No files found
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </TabComponent>
        </ReusableTabs>
      )}
      <ConfirmationModal
        discriminator="removeFile"
        title="Remove File"
        confirmText="Remove"
        denyText="Cancel"
        onConfirm={() => refetch()}
      />
    </div>
  )
}

export default Attachments
