import { OperationVariables } from '@apollo/client/core'
import { ComplianceLedger, UpdateComplianceLedgerInput } from 'types/graphql'

import useMutationUpdate from './useMutationUpdate'

const COMPLIANCE_LEDGER_REFRESH_MUTATION = gql`
  mutation ComplianceLedgerRefresh($id: String!) {
    refreshMaintenanceItemsPostCompletion(id: $id) {
      id
    }
  }
`

const useComplianceLedgerRefresh = (options?: OperationVariables) => {
  return useMutationUpdate<ComplianceLedger, UpdateComplianceLedgerInput>(
    COMPLIANCE_LEDGER_REFRESH_MUTATION,
    options
  )
}

export default useComplianceLedgerRefresh
