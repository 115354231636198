import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Typography } from '@mui/material';
import AiSparklesIcon from 'src/components/AiSparklesIcon';

interface AiLoadingIndicatorProps {
  steps: string[];
}

const AiLoadingIndicator: React.FC<AiLoadingIndicatorProps> = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prev) => (prev + 1) % steps.length);
    }, 3000); // Change step every 3 seconds

    return () => clearInterval(interval);
  }, [steps.length]);

  return (
    <div className="flex flex-col items-center">
      <motion.div
        className="mb-2"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [1, 0.7, 1],
        }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <AiSparklesIcon className="text-purple-600" />
      </motion.div>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentStep}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            animate={{
              scale: [1, 1.03, 1],
              color: ["#4B5563", "#6B7280", "#4B5563"],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <Typography variant="body2" fontWeight={500} className="text-center">
              {steps[currentStep]}
            </Typography>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default AiLoadingIndicator;
