import React, { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Button, Switch } from '@mui/material'
import { FeTableName } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useOrgName } from 'src/hooks/useOrgName'

import HorizontalDivider from './HorizontalDivider'
import Loading from './Loading'
import ReusableModal from './MUI/Modal'

interface ManageTableColumnsModalProps {
  open: boolean
  onClose: () => void
  onSaveDone?: (columns: string[]) => void
  columnOptions: any
  tableName: FeTableName
}

export const GET_SAVED_COLUMNS_CONFIG = gql`
  query GetSavedColumnsConfig($tableName: FeTableName!, $orgSlug: String!) {
    feTableConfiguration(tableName: $tableName, orgSlug: $orgSlug) {
      id
      columns
    }
  }
`

const UPSERT_FE_TABLE_CONFIG = gql`
  mutation UpsertFeTableConfig(
    $id: String!
    $input: UpsertFeTableConfigurationInput!
  ) {
    upsertFeTableConfiguration(id: $id, input: $input) {
      id
      columns
    }
  }
`

const ManageTableColumnsModal: React.FC<ManageTableColumnsModalProps> = ({
  open,
  columnOptions,
  tableName,
  onClose,
  onSaveDone,
}) => {
  const [enabledColumns, setEnabledColumns] = React.useState([])
  const [disabledColumns, setDisabledColumns] = React.useState([])

  const orgSlug = useOrgName()

  const [getSavedColumnsConfig, { data: savedTableConfig, loading }] =
    useLazyQuery(GET_SAVED_COLUMNS_CONFIG)
  const [upsertFeTableConfig] = useMutation(UPSERT_FE_TABLE_CONFIG)

  const savedColOrder = savedTableConfig?.feTableConfiguration?.columns || []

  useEffect(() => {
    if (open && tableName && !loading) {
      getSavedColumnsConfig({
        variables: {
          tableName,
          orgSlug,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName, open])

  useEffect(() => {
    setEnabledColumns(savedColOrder)
    setDisabledColumns(
      Object.keys(columnOptions)
        .filter((key) => !savedColOrder.some((col) => col === key))
        .map((key) => key)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnOptions, savedTableConfig])

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const items = Array.from(enabledColumns)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setEnabledColumns(items)
  }

  const onSaveClick = async () => {
    await upsertFeTableConfig({
      variables: {
        id: savedTableConfig?.feTableConfiguration?.id || '',
        input: {
          tableName,
          columns: enabledColumns,
          orgSlug,
        },
      },
    })
    onSaveDone && onSaveDone(enabledColumns)
    onClose()
  }

  return (
    <ReusableModal
      open={open}
      onClose={() => {
        onClose()
      }}
      title="Manage Columns"
      description="You can add or remove columns from the table or drag and drop items to change their order."
    >
      <div className="p-2">
        {loading ? (
          <Loading />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, _) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                >
                  {enabledColumns.map((enabledCol, idx) => {
                    return (
                      <Draggable
                        key={enabledCol}
                        draggableId={enabledCol}
                        index={idx}
                      >
                        {(provided, snapshot) => {
                          if (snapshot.isDragging) {
                            provided.draggableProps.style = {
                              ...provided.draggableProps.style,
                              left: 'auto',
                              top: 'auto',
                            }
                          }
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <SettingRow
                                columnName={columnOptions[enabledCol]}
                                isVisible={true}
                                onChange={() => {
                                  // Disable the column and move it to the disabled columns
                                  setDisabledColumns([
                                    ...disabledColumns,
                                    enabledCol,
                                  ])
                                  setEnabledColumns(
                                    enabledColumns.filter(
                                      (col) => col !== enabledCol
                                    )
                                  )
                                }}
                              />
                            </div>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {enabledColumns.length > 0 && <HorizontalDivider />}
            {
              // disabled columns
              disabledColumns.map((disabledCol) => {
                return (
                  <SettingRow
                    key={disabledCol}
                    columnName={columnOptions[disabledCol]}
                    isVisible={false}
                    onChange={() => {
                      // Enable the column and move it to the enabled columns
                      setEnabledColumns([...enabledColumns, disabledCol])
                      setDisabledColumns(
                        disabledColumns.filter((col) => col !== disabledCol)
                      )
                    }}
                  />
                )
              })
            }
          </DragDropContext>
        )}
        <div className="mt-2 flex flex-col gap-1">
          <Button disabled={loading} onClick={onSaveClick}>
            Save
          </Button>
          <Button variant="outlined" onClick={onClose} color="base">
            Cancel
          </Button>
        </div>
      </div>
    </ReusableModal>
  )
}

const SettingRow: React.FC<{
  columnName: string
  isVisible: boolean
  onChange: () => void
}> = ({ columnName, isVisible, onChange }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-2">
        <DragHandleIcon />
        <div>{columnName}</div>
      </div>
      <Switch
        checked={isVisible}
        onChange={(_) => {
          // handle switch change
          onChange()
        }}
      />
    </div>
  )
}

export default ManageTableColumnsModal
