import { DuelistFilterTimeframeHours } from 'src/types'

interface Option extends DuelistFilterTimeframeHours {
  label: string
}

const HOURS_FILTER_OPTIONS: Option[] = [
  {
    option: '25H',
    hours: 25,
    label: '25 Hours',
  },
  {
    option: '50H',
    hours: 50,
    label: '50 Hours',
  },
  {
    option: '75H',
    hours: 75,
    label: '75 Hours',
  },
  {
    option: '100H',
    hours: 100,
    label: '100 Hours',
  },
  {
    option: 'ALL',
    hours: -1,
    label: 'Clear',
  },
]

export default HOURS_FILTER_OPTIONS
