import React from 'react'

import { Drawer, Typography } from '@mui/material'
import { Aircraft, MaintenanceItem } from 'types/graphql'

import AdvancedDrawerContent from './AdvancedDrawerContent'
import SimpleDrawerContent from './SimpleDrawerContent'

interface LineItemDetailsDrawerProps {
  open: boolean
  onClose: () => void
  parentTask: MaintenanceItem
  aircraft: Aircraft
  taskViewMode?: 'simple' | 'advanced'
}

const LineItemDetailsDrawer: React.FC<LineItemDetailsDrawerProps> = ({
  open,
  onClose,
  parentTask,
  aircraft,
  taskViewMode = 'advanced',
}) => {
  return (
    <Drawer
      disablePortal
      anchor={'right'}
      open={open}
      sx={{ zIndex: 1202 }}
      onClose={onClose}
    >
      {parentTask ? (
        <>
          {taskViewMode === 'advanced' ? (
            <AdvancedDrawerContent
              onClose={onClose}
              parentTask={parentTask}
              aircraft={aircraft}
            />
          ) : (
            <SimpleDrawerContent
              onClose={onClose}
              parentTask={parentTask}
              aircraft={aircraft}
            />
          )}
        </>
      ) : (
        <Typography variant="h6">Something went wrong</Typography>
      )}
    </Drawer>
  )
}

export default LineItemDetailsDrawer
