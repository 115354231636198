import * as React from 'react'

import { useApolloClient } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import DifferenceRoundedIcon from '@mui/icons-material/DifferenceRounded'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import TopicRoundedIcon from '@mui/icons-material/TopicRounded'
import { Button, FormHelperText, Paper } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers'
import { calculateNextDueValue } from '@wingwork/common/src/jsonObjects'
import { getIntervalCadenceValues } from '@wingwork/common/src/maintenanceItem'
import dayjs from 'dayjs'
import { t } from 'i18next'
import * as Yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import AircraftTimeModal from 'src/components/AircraftTimeModal/AircraftTimeModal'
import NextDueValue from 'src/components/Compliance/NextDueValue'
import RemainingValue from 'src/components/Compliance/RemainingValue'
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal'
import FileUploadField from 'src/components/FileUploadField'
import HorizontalDivider from 'src/components/HorizontalDivider'
import Loading from 'src/components/Loading'
import Checkbox from 'src/components/MUI/Checkbox'
import CreatableAutocomplete, {
  CreatableAutocompleteOption,
} from 'src/components/MUI/CreatableAutoComplete'
import DatePicker from 'src/components/MUI/DatePicker'
import IconButton from 'src/components/MUI/IconButton'
import SelectDropdown from 'src/components/MUI/SelectDropdown'
import TextField from 'src/components/MUI/TextField'
import { UsersDropDownSelector } from 'src/components/MUI/UsersDropDownSelector'
import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import CORE_COMPONENT_USAGE_LOG_FRAGMENT from 'src/fragments/ComponentUsageLog'
import useCreateSingleCompliance from 'src/hooks/requests/useCreateSingleCompliance'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import { useDispatch } from 'src/hooks/useDispatch'
import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'
import { complianceActivitySchema } from 'src/pages/BulkCompliancePage/ComplianceEditor/EditorRoot'
import { openModal } from 'src/slices/modal'
import generateAuditLogPayload from 'src/utils/generateAuditLogPayload'
import { capitalizeFirstLetter, flattenErrors } from 'src/utils/helpers'
import { inProgressValues } from 'src/utils/maintenanceItem'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useMemo } from 'react'

const GET_MAINTENANCE_ITEM = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  ${CORE_COMPONENT_USAGE_LOG_FRAGMENT}
  query GetMaintenanceItemForSidebarCompliance($id: String!) {
    maintenanceItem(id: $id) {
      id
      ataCode
      manufactureCode
      cadenceType
      cadenceValue
      lastComplianceStamp {
        usageAsOf
      }
      complianceLedger {
        id
        status
        isRevision
        MaintenanceLogbook {
          id
          status
        }
      }
      workOrderIdInProgress
      maintenanceNextDue {
        nextDueValue
        nextDueOverride
        nextDueOverrideType
      }
      trackedByComponentId
      trackedByComponent {
        id
        name
        ComponentUsageLog {
          ...CoreComponentUsageLogFields
        }
      }
      aircraft {
        id
        AircraftUsageLog {
          ...CoreAircraftUsageLogFields
        }
      }
      aircraftComponent {
        id
        partNumber
        serialNumber
      }
    }
  }
`

export const RTS_STATEMENTS = gql`
  query GetRtsStatementsForSidebarCompliance($orgSlug: String!) {
    returnToServiceStatements(orgSlug: $orgSlug) {
      id
      statement
    }
  }
`

const CREATE_RTS_STATEMENT = gql`
  mutation CreateRtsStatement($input: CreateReturnToServiceStatementInput!) {
    createReturnToServiceStatement(input: $input) {
      id
    }
  }
`

export const GET_AIRCRAFT_USAGE_LOG = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query GetAircraftUsageLog($id: String!) {
    aircraftUsageLog(id: $id) {
      ...CoreAircraftUsageLogFields
    }
  }
`

const FileValue = (value, clear) => (
  <div className="flex items-center justify-between">
    <Typography className="overflow-hidden text-ellipsis whitespace-nowrap">
      {value?.[0]?.name}
    </Typography>
    <IconButton onClick={clear} size="small">
      <ClearRoundedIcon fontSize="small" />
    </IconButton>
  </div>
)

type ComplianceTabProps = {
  maintenanceItemId: string
}

const cadenceTypeToKey = {
  FLYING_HOURS: 'flying_hours',
  CYCLES: 'cycles',
  LANDINGS: 'landings',
  MONTHS: 'months',
  CALENDAR_MONTHS: 'months',
}

const cadenceTypeToUsageLogFieldMapping = {
  FLYING_HOURS: 'totalTimeSinceNew',
  CYCLES: 'cycleSinceNew',
  LANDINGS: 'cycleSinceNew',
  CALENDAR_DAYS: 'totalTimeSinceNew',
  CALENDAR_MONTHS: 'totalTimeSinceNew',
  EVENT_TRIGGERED: 'totalTimeSinceNew',
  UPSTREAM_TASK: 'totalTimeSinceNew',
}

const ComplianceTab: React.FC<ComplianceTabProps> = ({
  maintenanceItemId: id,
}) => {
  const client = useApolloClient()
  const dispatch = useDispatch()
  const { data: users } = useGetUsers()
  const orgSlug = useOrgName()
  const [overridingNextDue, setOverridingNextDue] = React.useState(false)

  const [isComplianceAccordionOpen, setIsComplianceAccordionOpen] =
    React.useState(false)

  const [isReturnToServiceAccordionOpen, setIsReturnToServiceAccordionOpen] =
    React.useState(false)

  const { data, hasLoaded, loading } = useQuery(GET_MAINTENANCE_ITEM, {
    variables: { id: id },
    skip: !id,
  })

  const canReadCompliance = useMemo(() => {
    if (!data?.maintenanceItem?.aircraft?.id) return false
    return useHasPermission(
      Permissions.compliance.read,
      data?.maintenanceItem?.aircraft?.id
    )
  }, [data?.maintenanceItem?.aircraft?.id])

  const [createCompliance] = useCreateSingleCompliance()
  const { maintenanceItem } = data ?? {}
  const isRecurringTask = React.useMemo(() => {
    return maintenanceItem?.maintenanceNextDue !== null
  }, [maintenanceItem?.maintenanceNextDue])

  const {
    data: rtsData,
    hasLoaded: hasRtsStatementsLoaded,
    loading: areRtsStatementsloading,
  } = useQuery(RTS_STATEMENTS, {
    variables: { orgSlug: orgSlug },
  })
  const [createRtsStatement] = useMutation(CREATE_RTS_STATEMENT)

  const formMethods = useForm({
    resolver: yupResolver(
      complianceActivitySchema.shape({
        usageLog: Yup.object().shape({
          id: Yup.string().required('Please create or select a usage log'),
        }),
      })
    ),
  })

  React.useEffect(() => {
    if (hasLoaded && data) {
      formMethods.reset({
        saveType: '',
        auditLogNotes: '',
        parts: {
          removed: {
            id: data?.maintenanceItem?.aircraftComponent?.id,
            partNumber: data?.maintenanceItem?.aircraftComponent?.partNumber,
            serialNumber:
              data?.maintenanceItem?.aircraftComponent?.serialNumber,
          },
          added: {
            partNumber: '',
            serialNumber: '',
          },
          removalReason: '',
          installedStatus: '',
        },
        task: {},
        ledger: {
          statementId: '',
          description: `${data?.maintenanceItem?.ataCode} ${data?.maintenanceItem?.manufactureCode}`,
        },
        usageLog: {
          id: null,
        },
        nextDue: {
          nextDueOverride: {},
        },
        doWorkCards: false,
      })
    }
  }, [hasLoaded])

  React.useEffect(() => {
    // set the default override value on button click, or undo it on reset/clicking the icon a second time
    if (overridingNextDue) {
      formMethods.setValue('nextDue.nextDueOverride', nextDueValue)
    } else {
      formMethods.resetField('nextDue.nextDueOverride')
    }
  }, [formMethods, overridingNextDue])

  const nextDueOverride = formMethods.watch('nextDue.nextDueOverride')

  const usageLogId = formMethods.watch('usageLog.id')

  const [getAircraftUsageLog, { data: aircraftUsageLogData }] = useLazyQuery(
    GET_AIRCRAFT_USAGE_LOG
  )

  React.useEffect(() => {
    if (usageLogId)
      getAircraftUsageLog({
        variables: { id: usageLogId },
        fetchPolicy: 'cache-first',
      })
  }, [usageLogId])

  const usageLog = aircraftUsageLogData?.aircraftUsageLog

  const componentUsageLog = React.useMemo(
    () =>
      usageLog?.ComponentUsageLog?.find(
        (l) => l.componentId === data.maintenanceItem.trackedByComponentId
      ),
    [data?.maintenanceItem, usageLog]
  )

  const nextDueValue = React.useMemo(() => {
    if (!componentUsageLog) {
      return null
    }

    return calculateNextDueValue(
      data.maintenanceItem.cadenceType,
      data.maintenanceItem.cadenceValue,
      componentUsageLog,
      usageLog.usageAsOf,
      false
    )
  }, [data?.maintenanceItem, componentUsageLog, usageLog])

  React.useEffect(() => {
    const hasComplianceError = !!formMethods.formState.errors.usageLog?.id
    if (hasComplianceError) setIsComplianceAccordionOpen(true)
    const hasReturnToServiceError =
      !!formMethods.formState.errors.ledger?.statementId
    if (hasReturnToServiceError) setIsReturnToServiceAccordionOpen(true)
    if (Object.keys(flattenErrors(formMethods.formState.errors)).length) {
      toast.error('Please resolve the errors before submitting the form')
    }
  }, [formMethods.formState.errors])

  const handleSubmit = async ({ saveType, ...values }) => {
    let statementId = values?.ledger?.statementId
      ? values.ledger.statementId.value
      : null
    if (values?.ledger?.statementId && values?.ledger?.statementId.isNew) {
      const { data: rtsStatementData } = await createRtsStatement({
        variables: {
          input: {
            orgSlug,
            statement: values.ledger.statementId.newValue,
          },
        },
      })
      statementId = rtsStatementData.createReturnToServiceStatement.id
    }
    const ledger = await createCompliance(
      {
        doSign: saveType === 'createLogbook',
        ...values,
        ledger: {
          ...values.ledger,
          statementId,
          aircraftId: data.maintenanceItem.aircraft.id,
          aircraftUsageLogId: values.usageLog.id,
        },
        task: {
          ...values.task,
          maintenanceItemId: data.maintenanceItem.id,
        },
        nextDueOverride: values.nextDue.nextDueOverride,
      },
      {
        ...generateAuditLogPayload(values.auditLogNotes),
      }
    )

    if (ledger !== undefined) {
      //TODO ww-443: Get error from createCompliance and handle it accordingly if the error is
      // unhandled in the hook.
      if (saveType === 'completeCompliance') {
        navigate(routes.workCompleted({ orgName: orgSlug }))
      } else if (saveType === 'createLogbook') {
        navigate(
          routes.ledgerLogbookEntry({ orgName: orgSlug, ledgerId: ledger.id })
        )
      }
    }
  }

  const statementOptions: CreatableAutocompleteOption[] =
    rtsData?.returnToServiceStatements.map((statement) => ({
      label: statement.statement,
      value: statement.id,
    }))

  if (
    (loading && !hasLoaded) ||
    (areRtsStatementsloading && !hasRtsStatementsLoaded)
  )
    return <Loading />

  const intervalCadenceValues = getIntervalCadenceValues(maintenanceItem)
  const dateCadenceLabel = intervalCadenceValues.includes('months') ? 'M' : 'D'
  const landingsCyclesString = intervalCadenceValues.includes('landings')
    ? 'landings'
    : 'cycles'

  const {
    isLedgerInProgress,
    isRevisionInProgress,
    isLogbookInProgress,
    isLogbookAwaitingSignature,
    isWorkOrderInProgress,
    isMaintenanceItemInProgress,
  } = inProgressValues(maintenanceItem)

  if (isMaintenanceItemInProgress) {
    return (
      <div className="flex flex-col gap-2 px-3 py-2">
        <Paper className="rounded-xl">
          <div className="flex w-full items-center justify-center p-5">
            {isLedgerInProgress && (
              <>
                <Typography variant="body2">
                  This task is currently in progress on an existing compliance
                </Typography>
                <IconButton
                  tooltip="Open compliance in progress"
                  disabled={!canReadCompliance}
                  disabledTooltip="You do not have permission to view compliance"
                  onClick={() =>
                    navigate(
                      routes.bulkCompliance({
                        orgName: orgSlug,
                        ledgerId: maintenanceItem.complianceLedger?.id,
                      })
                    )
                  }
                >
                  <TopicRoundedIcon />
                </IconButton>
              </>
            )}
            {isRevisionInProgress && (
              <>
                <Typography variant="body2">
                  This task is currently in progress on a compliance revision
                </Typography>
                <IconButton
                  tooltip="Continue revision"
                  disabled={!canReadCompliance}
                  disabledTooltip="You do not have permission to continue revision"
                  onClick={() =>
                    navigate(
                      routes.bulkCompliance({
                        orgName: orgSlug,
                        ledgerId: maintenanceItem.complianceLedger?.id,
                      })
                    )
                  }
                >
                  <DifferenceRoundedIcon />
                </IconButton>
              </>
            )}
            {isLogbookInProgress && (
              <>
                <Typography variant="body2">
                  This task is waiting for the logbook to be published
                </Typography>
              </>
            )}
            {isLogbookAwaitingSignature && (
              <>
                <Typography variant="body2">
                  This task is currently waiting for logbook signatures
                </Typography>
              </>
            )}
            {isWorkOrderInProgress && (
              <>
                <Typography variant="body2">
                  This task is currently in progress on an existing work order
                </Typography>
                <IconButton
                  tooltip="Open work order in progress"
                  onClick={() =>
                    navigate(
                      routes.workOrder({
                        orgName: orgSlug,
                        id: maintenanceItem.workOrderIdInProgress,
                      })
                    )
                  }
                >
                  <HomeRepairServiceIcon />
                </IconButton>
              </>
            )}
          </div>
        </Paper>
      </div>
    )
  }

  return (
    <>
      <Form
        id="sidebarCompliance"
        formMethods={formMethods}
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-2 px-3 py-2">
          <div>
            <Accordion
              className="rounded-xl"
              expanded={isComplianceAccordionOpen}
              onChange={() =>
                setIsComplianceAccordionOpen(!isComplianceAccordionOpen)
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Compliance</Typography>
              </AccordionSummary>
              <AccordionDetails className="flex flex-col gap-2">
                <Typography variant="subtitle2">Times</Typography>
                <MUIDatePicker
                  label="Date"
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                      error: !!formMethods.formState.errors.usageLog?.id,
                    },
                  }}
                  disabled
                  value={
                    usageLog?.usageAsOf ? dayjs(usageLog?.usageAsOf) : null
                  }
                />
                <TextField
                  disabled
                  error={!!formMethods.formState.errors.usageLog?.id}
                  label="Hours"
                  name="notes"
                  fullWidth
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={componentUsageLog?.totalTimeSinceNew}
                />
                <TextField
                  disabled
                  error={!!formMethods.formState.errors.usageLog?.id}
                  label={
                    maintenanceItem.trackedByComponent.name === 'Airframe'
                      ? 'Landings'
                      : 'Cycles'
                  }
                  name="notes"
                  fullWidth
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={componentUsageLog?.cycleSinceNew}
                />
                <div className="flex flex-col">
                  <Button
                    onClick={() => {
                      dispatch(
                        openModal({
                          name: 'aircraftTimeModal',
                          data: {
                            aircraftId: data?.maintenanceItem?.aircraft?.id,
                            discriminator: 'sidebarCompliance',
                            initialTab: 'pick',
                            trackedByComponentName:
                              data?.maintenanceItem?.trackedByComponent?.name,
                          },
                        })
                      )
                    }}
                  >
                    Apply Time
                  </Button>
                  {formMethods.formState.errors.usageLog?.id?.message && (
                    <FormHelperText error>
                      {formMethods.formState.errors.usageLog?.id?.message}
                    </FormHelperText>
                  )}
                </div>
                {nextDueValue && (
                  <div className="grid grid-cols-2 gap-1">
                    <div className="flex items-center gap-x-1">
                      <Typography variant="subtitle2">Next Due</Typography>
                      <IconButton
                        size="small"
                        onClick={() => setOverridingNextDue(!overridingNextDue)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </div>

                    <Typography variant="subtitle2" className="self-center">
                      Remaining
                    </Typography>

                    {/* <nextDue> */}
                    {overridingNextDue ? (
                      <div className="flex flex-col gap-2">
                        {(intervalCadenceValues.includes('months') ||
                          intervalCadenceValues.includes('days')) && (
                          <DatePicker
                            label="Date"
                            name="nextDue.nextDueOverride.date"
                            slotProps={{ textField: { size: 'small' } }}
                          />
                        )}
                        {intervalCadenceValues.includes('flying_hours') && (
                          <TextField
                            name="nextDue.nextDueOverride.flying_hours"
                            type="number"
                            valueAsNumber
                            size="small"
                            label="Hours"
                            variant="outlined"
                          />
                        )}
                        {(intervalCadenceValues.includes('landings') ||
                          intervalCadenceValues.includes('cycles')) && (
                          <TextField
                            name={`nextDue.nextDueOverride.${landingsCyclesString}`}
                            type="number"
                            valueAsNumber
                            size="small"
                            label={capitalizeFirstLetter(landingsCyclesString)}
                            variant="outlined"
                          />
                        )}

                        {overridingNextDue && (
                          <Button
                            size="small"
                            variant="outlined"
                            color="pastelRed"
                            onClick={() => setOverridingNextDue(false)}
                          >
                            Reset
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2">
                        {(intervalCadenceValues.includes('months') ||
                          intervalCadenceValues.includes('days')) && (
                          <NextDueValue
                            regularValue={
                              nextDueValue?.date &&
                              dayjs(nextDueValue?.date).format('MMM DD, YYYY')
                            }
                            overrideValue={
                              nextDueOverride?.date &&
                              dayjs(nextDueOverride?.date).format(
                                'MMM DD, YYYY'
                              )
                            }
                            isRecurringTask={isRecurringTask}
                          />
                        )}
                        {intervalCadenceValues.includes('flying_hours') && (
                          <NextDueValue
                            regularValue={nextDueValue?.flying_hours}
                            overrideValue={nextDueOverride?.flying_hours}
                            cadenceLabel={'H'}
                            isRecurringTask={isRecurringTask}
                          />
                        )}
                        {intervalCadenceValues.includes('landings') && (
                          <NextDueValue
                            regularValue={nextDueValue?.landings}
                            overrideValue={nextDueOverride?.landings}
                            cadenceLabel={'L'}
                            isRecurringTask={isRecurringTask}
                          />
                        )}
                        {intervalCadenceValues.includes('cycles') && (
                          <NextDueValue
                            regularValue={nextDueValue?.cycles}
                            overrideValue={nextDueOverride?.cycles}
                            cadenceLabel={'C'}
                            isRecurringTask={isRecurringTask}
                          />
                        )}
                      </div>
                    )}
                    {/* </nextDue> */}

                    {/* <remaining> */}
                    <div className="flex flex-col gap-2">
                      {(intervalCadenceValues.includes('months') ||
                        intervalCadenceValues.includes('days')) && (
                        <RemainingValue
                          regularValue={nextDueValue?.date}
                          overrideValue={nextDueOverride?.date}
                          sinceValue={usageLog?.usageAsOf}
                          cadenceLabel={dateCadenceLabel}
                          isRecurringTask={isRecurringTask}
                          isDate
                          dateDiffUnit={
                            dateCadenceLabel === 'M' ? 'month' : 'day'
                          }
                        />
                      )}
                      {intervalCadenceValues.includes('flying_hours') && (
                        <RemainingValue
                          regularValue={nextDueValue?.flying_hours}
                          overrideValue={nextDueOverride?.flying_hours}
                          sinceValue={componentUsageLog?.totalTimeSinceNew}
                          cadenceLabel={'H'}
                          isRecurringTask={isRecurringTask}
                        />
                      )}
                      {intervalCadenceValues.includes('landings') && (
                        <RemainingValue
                          regularValue={nextDueValue?.landings}
                          overrideValue={nextDueOverride?.landings}
                          sinceValue={componentUsageLog?.cycleSinceNew}
                          cadenceLabel={'L'}
                          isRecurringTask={isRecurringTask}
                        />
                      )}
                      {intervalCadenceValues.includes('cycles') && (
                        <RemainingValue
                          regularValue={nextDueValue?.cycles}
                          overrideValue={nextDueOverride?.cycles}
                          sinceValue={componentUsageLog?.cycleSinceNew}
                          cadenceLabel={'C'}
                          isRecurringTask={isRecurringTask}
                        />
                      )}
                    </div>
                    {/* </remaining> */}
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion className="rounded-xl">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Work Information</Typography>
              </AccordionSummary>
              <AccordionDetails className="flex flex-col gap-2">
                <TextField
                  label="Description"
                  name="task.description"
                  fullWidth
                  multiline
                  rows={4}
                />
                <TextField
                  label="Notes"
                  name="task.notes"
                  fullWidth
                  multiline
                  rows={4}
                />
                <div className="grid grid-cols-2 gap-1">
                  <UsersDropDownSelector
                    name="task.workedById"
                    label="Mechanic"
                    users={users?.users || []}
                    size="small"
                  />
                  <TextField
                    label="Work Hours"
                    name="task.workHours"
                    fullWidth
                    size="small"
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion className="rounded-xl">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Parts Used</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-cols-2 gap-1">
                  <div className="flex flex-col gap-2">
                    <Typography variant="subtitle2">Removed</Typography>
                    <TextField
                      label="Part Number"
                      name="parts.removed.partNumber"
                      fullWidth
                      size="small"
                      disabled
                    />
                    <TextField
                      label="Serial Number"
                      name="parts.removed.serialNumber"
                      fullWidth
                      size="small"
                      disabled
                    />
                    <TextField
                      fullWidth
                      name="parts.removalReason"
                      label="Removal Reason"
                      size="small"
                      disabled={
                        !data?.maintenanceItem?.aircraftComponent?.partNumber
                      }
                    />
                    <FileUploadField
                      name="parts.removedPartFile"
                      renderValue={FileValue}
                      button={
                        <Button
                          className="w-fit"
                          variant="outlined"
                          color="base"
                          startIcon={<AttachFileIcon />}
                          disabled={
                            !data?.maintenanceItem?.aircraftComponent
                              ?.partNumber
                          }
                        >
                          Attach File
                        </Button>
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Typography variant="subtitle2">Added</Typography>
                    <TextField
                      label="Part Number"
                      name="parts.added.partNumber"
                      fullWidth
                      size="small"
                    />
                    <TextField
                      label="Serial Number"
                      name="parts.added.serialNumber"
                      fullWidth
                      size="small"
                    />
                    <SelectDropdown
                      name="parts.installedStatus"
                      size="small"
                      fullWidth
                      options={[
                        'Altered',
                        'Inspected',
                        'Modified',
                        'New',
                        'Not Specified',
                        'Overhauled',
                        'Other (See Notes)',
                        'Repaired',
                      ].map((status) => ({ label: status, value: status }))}
                      label="Installed Status"
                    />
                    <FileUploadField
                      name="parts.addedPartFile"
                      renderValue={FileValue}
                      button={
                        <Button
                          className="w-fit"
                          variant="outlined"
                          color="base"
                          startIcon={<AttachFileIcon />}
                        >
                          Attach File
                        </Button>
                      }
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              className="rounded-xl"
              expanded={isReturnToServiceAccordionOpen}
              onChange={() =>
                setIsReturnToServiceAccordionOpen(
                  !isReturnToServiceAccordionOpen
                )
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Return to Service</Typography>
              </AccordionSummary>
              <AccordionDetails className="flex flex-col gap-2">
                <CreatableAutocomplete
                  isFormField
                  name="ledger.statementId"
                  label="Statement"
                  options={statementOptions}
                />
                <div>
                  <Typography variant="subtitle2">
                    Signatures (optional)
                  </Typography>
                  <Typography variant="caption">
                    Select users to sign off on the logbook entry
                  </Typography>
                </div>
                <UsersDropDownSelector
                  name="ledger.requestedWorkerId"
                  label="Technician"
                  users={users?.users || []}
                  size="small"
                />
                <UsersDropDownSelector
                  name="ledger.requestedInspectorId"
                  label="Inspector"
                  users={users?.users || []}
                  size="small"
                />
                <HorizontalDivider />
                <div className="flex basis-1/5 flex-col gap-2">
                  <Typography variant="subtitle2" className="mb-2">
                    Documentation Generation
                  </Typography>
                  <Checkbox
                    name="doWorkCards"
                    label="Work Cards"
                    disabledTooltip="Upcoming feature: Create work cards"
                    checkboxProps={{ disabled: true }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <ConfirmationModal
          title="Complete Compliance"
          discriminator="sidebarCompleteCompliance"
          message="Are you sure you want to complete this compliance?"
          confirmText="Complete Compliance"
          collectNotes
          notesFieldLabel={t('auditLogNotes.label')}
          placeholder={t('auditLogNotes.placeholder')}
          denyText="Cancel"
          confirmNote="Due list will be updated upon compliance completion."
          beforeRender={() => {
            //TODO: The shouldFocus does not work as expected since
            // it requires the field to be registered using the form function
            return formMethods.trigger(undefined, { shouldFocus: true })
          }}
          onConfirm={(note) => {
            formMethods.setValue('auditLogNotes', note)
            formMethods.setValue('saveType', 'completeCompliance')
            formMethods.handleSubmit(handleSubmit)()
          }}
        />
        <ConfirmationModal
          title="Create Logbook Entry"
          discriminator="sidebarCreateLogbook"
          message="Are you sure you want to create a Logbook Entry?"
          confirmText="Complete Compliance"
          collectNotes
          notesFieldLabel={t('auditLogNotes.label')}
          placeholder={t('auditLogNotes.placeholder')}
          denyText="Cancel"
          confirmNote="Due list will be updated upon Logbook entry completion."
          beforeRender={() => {
            return formMethods.trigger(undefined, { shouldFocus: true })
          }}
          onConfirm={(note) => {
            formMethods.setValue('auditLogNotes', note)
            formMethods.setValue('saveType', 'createLogbook')
            formMethods.handleSubmit(handleSubmit)()
          }}
        />
      </Form>
      <AircraftTimeModal
        discriminator="sidebarCompliance"
        onSuccess={(id) => {
          formMethods.setValue('usageLog.id', id)
          formMethods.trigger('usageLog.id')
        }}
      />
    </>
  )
}

export default ComplianceTab
