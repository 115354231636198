import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Paper, Typography, Button, CircularProgress } from '@mui/material';

// Important: Set the worker source for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`

type CitationViewerProps = {
  fileUrl: string;
  pageNumbers?: string;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const CitationViewer = ({ fileUrl, pageNumbers, onClose }: CitationViewerProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState<number[]>([]);
  console.log('fileUrl', fileUrl)

  useEffect(() => {
    if (pageNumbers) {
      const range = parsePageRange(pageNumbers);
      setPageRange(range);
      setCurrentPage(range[0]);
    }
  }, [pageNumbers]);

  function parsePageRange(input: string): number[] {
    const parts = input.split(',').flatMap(part => {
      if (part.includes('-')) {
        const [start, end] = part.split('-').map(Number);
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
      }
      return [Number(part)];
    });
    return [...new Set(parts)].sort((a, b) => a - b);
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    if (!pageNumbers) {
      setPageRange(Array.from({ length: numPages }, (_, i) => i + 1));
    }
  }

  const goToPrevPage = (event) => {
    event.stopPropagation()
    setCurrentPage(prevPage => {
      const index = pageRange.indexOf(prevPage);
      return index > 0 ? pageRange[index - 1] : prevPage;
    });
  }

  const goToNextPage = (event) => {
    event.stopPropagation()
    setCurrentPage(prevPage => {
      const index = pageRange.indexOf(prevPage);
      return index < pageRange.length - 1 ? pageRange[index + 1] : prevPage;
    });
  }

  return (
    <Paper className="flex flex-col items-center p-4" onClick={(event) => event.stopPropagation()}>
      <Paper className="mt-4 flex justify-between w-full">
        <Button onClick={goToPrevPage} disabled={currentPage === pageRange[0]}>
          Previous
        </Button>
        <Typography>
          {numPages === null ? (
            "Loading..."
          ) : pageNumbers ? (
            `Page ${currentPage} of ${numPages} (Showing ${pageRange[0]}-${pageRange[pageRange.length - 1]})`
          ) : (
            `Page ${currentPage} of ${numPages}`
          )}
        </Typography>
        <Button onClick={goToNextPage} disabled={currentPage === pageRange[pageRange.length - 1]}>
          Next
        </Button>
      </Paper>
      <Button onClick={onClose} className="mt-4">
        Close
      </Button>
      <Document
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<CircularProgress />}
      >
        <Page
          pageNumber={currentPage}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </Paper>
  );
};

export default CitationViewer;
